import React from 'react';
import { useParams } from 'react-router-dom';
 
export const WithParams = ({children}) => {
    const params = useParams();
    let elements;
    if (Array.isArray(children)) {
        elements = children.map(child => {
            return {...child, props: {...child.props, params: params}}
        })
    } else {
        elements = {...children, props: {...children.props, params: params}}
    }
    return elements;
};