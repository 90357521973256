import React from "react";


export default function CardWithButton({title = "", content = "", submitBtnName = "", submitHandler = null}) {
    return (
        <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">{title}</h6>
                {submitHandler !== null &&
                <button
                className="bg-atec-neon-green-400 text-white active:bg-atec-neon-green-200 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={submitHandler}
                >
                    {submitBtnName}
                </button>
                }
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            {content}
          </div>
        </div>
        </>
  );
}
