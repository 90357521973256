import React from "react";
import { useState } from "react";
import { useAuth } from "contexts/AuthContext";

// components
import Alert from "components/Alerts/Alert.js";
import CardWithButton from "components/Cards/CardWithButton";
import ConfirmPopup from "components/Popups/ConfirmPopup";

export default function IngestionTools() {
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    backgroundColor: "",
    textColor: "",
    text: "",
    icon: ""
  })
  const { getAuthHeader } = useAuth();

  const [showPopup, setShowPopup] = useState(false);

  const [deleteIngestionId, setDeleteIngestionId] = useState("");

  const deleteAllQueuedIngestionRecords = async () => {
    setShowAlert(false);
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/delete-all-queued-ingestion-records', 
    {
      method: "DELETE",
      headers: await getAuthHeader(),
    });

    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
      setShowAlert(true);
    } else {
      response.json().then(body => {
        setAlert({
          backgroundColor: "bg-atec-neon-green-400",
          textColor: "text-white",
          text: "Successfully removed all ingestion records from queue, items removed: " + body.items_deleted,
          icon: "fas fa-check-circle"
        });
        setShowAlert(true);
      })
    }
  }

  const deleteAllIncompleteIngestionRecords = async () => {
    setShowAlert(false);
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/delete-all-incomplete-ingestion-records', 
    {
      method: "DELETE",
      headers: await getAuthHeader(),
    });

    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
      setShowAlert(true);
    } else {
      response.json().then(body => {
        setAlert({
          backgroundColor: "bg-atec-neon-green-400",
          textColor: "text-white",
          text: "Successfully removed all incomplete ingestion records, items removed: " + body.items_deleted,
          icon: "fas fa-check-circle"
        });
        setShowAlert(true);
      })
    }
  }

  const deleteIngestionRecord = async () => {
    setShowAlert(false);
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/cancel-pending-ingestion?ingestion_id=' + deleteIngestionId, 
    {
      method: "DELETE",
      headers: await getAuthHeader(),
    });

    if (response.status != 200) {
      const { error } = await response.json();
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: error,
        icon: "fas fa-exclamation-triangle"
      });
      setShowAlert(true);
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Successfully Deleted Ingestion Record: " + deleteIngestionId,
        icon: "fas fa-check-circle"
      });
      setShowAlert(true);
    }
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-full px-4">
        <Alert showAlert={showAlert} closeCallback={() => setShowAlert(false)} alertOptions={alert}/>
        <CardWithButton title="Ingestion Tools" content={
            <div>
                <div className="mt-5">
                    <h6 className="text-red-600 text-sm mt-3 mb-5 font-bold uppercase">
                        Delete Records
                    </h6>
                </div>
                <div>
                  <div className="flex flex-wrap items-center mb-5">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Ingestion ID
                        </label>
                        <input
                          type="text"
                          value={deleteIngestionId} 
                          onChange={(e) => {setDeleteIngestionId(e.target.value)}}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={deleteIngestionRecord}
                      >
                        Delete Ingestion Record
                      </button>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="flex flex-wrap py-4 justify-between">
                    <button
                        className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {setShowPopup(true);}}
                    >
                        Delete Queued Records <i className="fas fa-radiation"/>
                    </button>
                </div>

                <div className="flex flex-wrap py-4 justify-between">
                    <button
                        className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={deleteAllIncompleteIngestionRecords}
                    >
                        Delete Incomplete Records
                    </button>
                </div>
            </div>
        }/>
      </div>
      <ConfirmPopup
          show = {showPopup}
          confirmCallback = {() => {deleteAllQueuedIngestionRecords(); setShowPopup(false);}}
          cancelCallback = {() => {setShowPopup(false);}} 
          confirmText = "Confirm"
          cancelText = "Cancel"
          title = "Are your sure?"
          content = {
            <div className="font-semibold text-lg">
                You are about to delete all records pending ingestion<br/>
            </div>}
      />
    </div>
  );
}
