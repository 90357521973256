import colors from "tailwindcss/colors";
import React from "react";
import ClassificationChart from "components/Charts/ClassificationChart";
import { AuthContext } from "contexts/AuthContext";
import FinishRecordPopup from "components/Popups/FinishRecordPopup";
import InfoPopup from "components/Popups/InfoPopup";
import ConfirmPopup from "components/Popups/ConfirmPopup";



class SSEPClassificationView extends React.Component {

    static contextType = AuthContext;

    LINE_CYAN = {default: colors.cyan[400], hover: colors.cyan[300]}
    LINE_GREEN = {default: colors.green[400], hover: colors.green[300]}
    LINE_ORANGE = {default: colors.orange[400], hover: colors.orange[300]}
    LINE_RED = {default: colors.red[400], hover: colors.red[300]}
    STATUS_TEXT = ['', 'Monitorable', 'Identifiable but not monitorable', 'Not identifiable'];
    PEAK_MARKER_TEXT = ['', 'Accurate', 'Sub-Optimal', 'Wrong'];


    constructor(props) {
        super(props);

        this.adjudicateMode = (this.props.adjudicateMode) ? true : false;
        this.adjudicateRecordId = (this.props.adjudicateMode) ? this.props.params.id || "" : undefined;

        this.state = {
            leftChart: null,
            leftChartAnnotations: {},
            leftAnnotations: {},
            leftLoading: true,
            rightChart: null,
            rightChartAnnotations: {},
            rightAnnotations: {},
            rightLoading: true,
            stimSites: {},
            recordSites: {},
            selectedStimSite: null,
            selectedRecordSite: null,
            loadedCharts: {},
            dataAnnotations: {},
            showFinishDialog: false,
            showIncompleteDialog: false,

            // Adjudication
            allAnnotations: (this.adjudicateMode) ? {} : undefined,
            annotatorDetails: (this.adjudicateMode) ? {} : undefined,
            leftDiscrepancies: (this.adjudicateMode) ? [] : undefined,
            rightDiscrepancies: (this.adjudicateMode) ? [] : undefined,
            selectedAnnotation: (this.adjudicateMode) ? "N/A" : undefined,
            selectedAnnotationMemory: (this.adjudicateMode) ? {} : undefined,
        }

        this.buildClassificationRow = this.buildClassificationRow.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.checkComplete = this.checkComplete.bind(this);
        this.changeAll = this.changeAll.bind(this);
        this.completeClicked = this.completeClicked.bind(this);
        this.classificationComplete = this.classificationComplete.bind(this);
        this.reset = this.reset.bind(this);

        // Adjudication
        this.selectUserAnnotation = this.selectUserAnnotation.bind(this);

    }

    componentDidMount() {
        this.init();
    }

    showFinishDialog(choice) {
        this.checkComplete();
        this.setState({showFinishDialog: choice});
    }

    updateAnnotations(side, baseline, key, value, changeLineColor = false) {
        const targetIndex = (baseline == 1) ? 2 : 4;
        const set = this.state.selectedStimSite + '/' + this.state.selectedRecordSite;
        const newAnnotations = {
            ...this.state.dataAnnotations,
            [set]: {
                ...this.state.dataAnnotations[set],
                [side]: {
                    ...this.state.dataAnnotations[set]?.[side],
                    [baseline]: {
                        ...this.state.dataAnnotations[set]?.[side]?.[baseline],
                        [key]: value,
                    }
                    
                }
            }
        }

        if (key === 'status' && value === 3) { // Status set to bad, therefore peak classification is disabled
            delete newAnnotations[set][side][baseline]['peak'];
        }

        this.setState({
            dataAnnotations: newAnnotations,
            [(side == 'left') ? 'leftAnnotations' : 'rightAnnotations']: {
                ...(side == 'left' ? newAnnotations[set].left : newAnnotations[set].right)
            }
        });

        if (changeLineColor) {
            const datasets = [...this.state.loadedCharts[set][side].datasets];
            const {color, hoverColor} = this.getLineColor(value);
            datasets[targetIndex]['borderColor'] = color;
            datasets[targetIndex]['hoverBorderColor'] = hoverColor;

            const chart = {
                ...this.state.loadedCharts[set],
                [side]: {
                    ...this.state.loadedCharts[set][side],
                    datasets: datasets,
                }
            }
            this.setState({
                loadedCharts: {
                    ...this.state.loadedCharts,
                    [set]: chart,
                },
                leftChart: chart.left,
                rightChart: chart.right
            })
        }
    }

    // UTIL FUNCTIONS //
    async init() {
        console.log("SSEP Classification View Initializing...");
        const result = await this.loadSets();
        if (result?.error) {
            if (result.error === 404) {
                window.alert('Could not load any SSEP sets, please try again later..');
                window.location.replace('/user/myaccount');
                return;
            }
        }

        if (result && result.records) {
            const first = Object.entries(result.records)[0];
            const split = first[0].split('/');
            let firstKey = first[0];
            let chart = first[1];
            if (!chart.left || !chart.right) {
                window.alert('Something went wrong while setting up..');
                return;
            }
            if (this.adjudicateMode) {
                const [userId, annotation] = Object.entries(result.allAnnotations[firstKey] || {})[0] || [];
                if (userId && annotation) {
                    chart = this.getUpdatedChartAfterAnnotations(annotation, firstKey, chart);
                    this.setState({
                        selectedAnnotation: userId,
                        dataAnnotations: {
                            [firstKey]: annotation,
                        },
                        leftAnnotations: annotation.left,
                        rightAnnotations: annotation.right
                    });
                }
                this.setState({
                    annotatorDetails: result.annotation_logs,
                    leftDiscrepancies: chart.left.discrepancies,
                    rightDiscrepancies: chart.right.discrepancies,
                    allAnnotations: result.allAnnotations
                })
            }

            this.setState({
                recordSites: result.record_sites,
                stimSites: result.stim_sites,
                selectedStimSite: split[0],
                selectedRecordSite: split[1],
                leftChart: {
                    name: chart.left.name || "N/A",
                    totalBaselines: chart.left.totalBaselines,
                    datasets: chart.left.datasets
                },
                leftChartAnnotations: chart.left.chartAnnotations,
                leftLoading: false,
                rightChart: {
                    name: chart.right.name || "N/A",
                    totalBaselines: chart.right.totalBaselines,
                    datasets: chart.right.datasets
                },
                rightChartAnnotations: chart.right.chartAnnotations,
                rightLoading: false,
                loadedCharts: result.records
            })

            console.log('Lazy loading other sets...');
            let needSets = [];
            for (const stim of Object.keys(result.stim_sites)) {
                for (const recording of Object.keys(result.record_sites)) {
                    const key = stim + '/' + recording;
                    if (!result.records[key]) {
                        needSets.push(key);
                    }
                }
            }
            const others = await this.loadSets(needSets);
            if (this.adjudicateMode) {
                this.setState({allAnnotations: {...result.allAnnotations, ...others.allAnnotations}});
            }
            this.setState({
                loadedCharts: {...result.records, ...others.records}
            })
        } else {
            window.alert('Something went wrong when loading an SSEP set, try again later');
        }
        return;
    }

    async loadSets(sets = []) {
        const params = ((sets.length > 0) 
        ? '?sets=' + JSON.stringify(sets) + (this.adjudicateMode ? '&adjudicate=true&record_id=' + this.adjudicateRecordId : '')
        : (this.adjudicateMode ? '?adjudicate=true&record_id=' + this.adjudicateRecordId : ''));

        const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + '/api/ssepset/load' + params,
            {
                method: 'GET', 
                headers: await this.context.getAuthHeader(),
            }
        );

        if(response.status !== 200) {
            return {error: response.status, ...response};
        }

        const body = await response.json();
        console.debug("SSEP Fetch Body", body);

        const loadedSets = {};
        const allAnnotations = (this.adjudicateMode) ? {} : undefined;

        for (const [name, charts] of Object.entries(body.records)) {
            const leftName = (charts.left.channel || "").replaceAll("_", " ").toLowerCase();
            const rightName = (charts.right.channel || "").replaceAll("_", " ").toLowerCase();
            const leftChart = this.formatForChart(charts.left.waveforms);
            const rightChart = this.formatForChart(charts.right.waveforms);
            loadedSets[name] = {
                left: {
                    datasets: leftChart.datasets, 
                    chartAnnotations: leftChart.chartAnnotations,
                    name: leftName,
                    totalBaselines: leftChart.totalBaselines,
                    discrepancies: (this.adjudicateMode) ? charts.discrepancies?.left : undefined
                },
                right: {
                    datasets: rightChart.datasets, 
                    chartAnnotations: rightChart.chartAnnotations,
                    name: rightName,
                    totalBaselines: rightChart.totalBaselines,
                    discrepancies: (this.adjudicateMode) ? charts.discrepancies?.right : undefined
                }
            }
            if (this.adjudicateMode) {
                allAnnotations[name] = this.mapAnnotationToLocalVersion(charts.annotations);
            }
        }
        return {...body, records: loadedSets, allAnnotations: allAnnotations};
    }

    reset() {
        this.setState({
            leftChart: null,
            leftChartAnnotations: {},
            rightChart: null,
            rightChartAnnotations: {},
            leftAnnotations: {},
            rightAnnotations: {},
            stimSites: {},
            recordSites: {},
            selectedStimSite: null,
            selectedRecordSite: null,
            loadedCharts: {},
            dataAnnotations: {},
            showFinishDialog: false,
            showIncompleteDialog: false,
        })
        this.init();
    }

    getLineColor(selectPosition) {
        let color;
        let hoverColor;
        switch(selectPosition) {
            case 0:
                color = this.LINE_CYAN.default;
                hoverColor = this.LINE_CYAN.hover;
            break;
            case 1:
                color = this.LINE_GREEN.default;
                hoverColor = this.LINE_GREEN.hover;               
            break;
            case 2:
                color = this.LINE_ORANGE.default;
                hoverColor = this.LINE_ORANGE.hover;         
            break;
            case 3:
                color = this.LINE_RED.default;
                hoverColor = this.LINE_RED.hover;
            break;
        }
        return {color: color, hoverColor: hoverColor};
    }

    changeDisplay(stimSite, recordSite) {
        const key = stimSite + '/' + recordSite;
        let loadedChart = this.state.loadedCharts[key];
        let dataAnnotations = this.state.dataAnnotations[key];

        if(loadedChart === undefined) {
            console.error('Attempted to change display when charts dont exist in memory', stimSite, recordSite);
            return;
        }

        if (this.adjudicateMode) {
            const previouslySelected = this.state.selectedAnnotationMemory[key];
            if (previouslySelected !== undefined) { // User has already been to display
                this.setState({selectedAnnotation: previouslySelected});
            } else { // User has never been to display, set first annotation to first one in list
                const [userId, annotation] = Object.entries(this.state.allAnnotations[key] || {})[0];
                if (userId && annotation) {
                    dataAnnotations = annotation;
                    loadedChart = this.getUpdatedChartAfterAnnotations(annotation, key, loadedChart);
                    this.setState({selectedAnnotation: userId});
                }
            }
            this.setState({
                leftDiscrepancies: loadedChart.left.discrepancies,
                rightDiscrepancies: loadedChart.right.discrepancies    
            })
        }

        this.setState({
            loadedCharts: {
                ...this.state.loadedCharts,
                [key]: loadedChart
            },
            leftChart: loadedChart.left,
            leftChartAnnotations: loadedChart.left.chartAnnotations,
            leftAnnotations: dataAnnotations?.left || {},
            rightChart: loadedChart.right,
            rightChartAnnotations: loadedChart.right.chartAnnotations,
            rightAnnotations: dataAnnotations?.right || {},
            dataAnnotations: {
                ...this.state.dataAnnotations,
                [key]: dataAnnotations
            }
        })
    }

    formatForChart(waveforms) {
        let datasets = [];
        const chartAnnotations = {}

        let totalBaselines = 0;
        for (const [key, item] of Object.entries(waveforms)) {

            datasets.push({
                label: item.name,
                data: item.points,
                offset: item.offset,
                isBaseline: item.is_baseline
            })

            if (item.is_baseline) {
                datasets[key].borderColor = this.LINE_CYAN.default;
                datasets[key].borderWidth = 4;
                datasets[key].hoverBorderWidth = 5;
                datasets[key].hoverBorderColor = this.LINE_CYAN.default;
                totalBaselines++;
            }

            if (item.ref_index != undefined) { 
                const point = item.points[item.ref_index];
                if (point === undefined) {
                    console.error(`ref_index: ${item.ref_index} is not a valid index, size of data points array: ${item.points.length}`);
                } else {
                    chartAnnotations['ref' + key] = {
                        type: 'point',
                        pointStyle: 'line',
                        xValue: point.x,
                        yValue: point.y,
                        borderColor: colors.yellow[400],
                        borderWidth: 2,
                        rotation: 90,
                        adjustScaleRange: false
                    }
                }
            } 

            if (item.peak_index != undefined) {
                const point = item.points[item.peak_index];
                if (point === undefined) {
                    console.error(`peak_index: ${item.peak_index} is not a valid index, size of data points array: ${item.points.length}`);
                } else {
                    chartAnnotations['peak' + key] = {
                        type: 'point',
                        xValue: point.x,
                        yValue: point.y,
                        borderColor: colors.yellow[400],
                        borderWidth: 2,
                        radius: 4,
                        adjustScaleRange: false
                    }
                }
            }
        }
        return {datasets: datasets, chartAnnotations: chartAnnotations, totalBaselines: totalBaselines}
    }

    buildClassificationRow(side, baseline) {
        const status = (side === 'left') ? this.state.leftAnnotations[baseline]?.status || 0 : this.state.rightAnnotations[baseline]?.status || 0;
        const peak = (side === 'left') ? this.state.leftAnnotations[baseline]?.peak || 0 : this.state.rightAnnotations[baseline]?.peak || 0;

        return (
            <div className="w-full">
                <div className="flex flex-grow justify-around">
                    <div className="basis-1/5 text-center self-center min-w-[59px]">{baseline}</div>
                    <div className="basis-2/5 inline-flex rounded-md shadow-sm justify-center text-sm" role="group">
                        <button 
                            type="button" 
                            className={(status === 1 ? "bg-green-500 text-gray-100" : "bg-gray-300 text-atec-dark-grey hover:text-gray-100 hover:bg-green-500") + " p-4 rounded-l-lg"}
                            onClick={() => this.selectChange(1, side, baseline, 'status')}
                        >
                            <i className="fas fa-thumbs-up"/>
                        </button>
                        <button 
                            type="button" 
                            className={(status === 2 ? "bg-orange-400 text-gray-100" : "bg-gray-300 text-atec-dark-grey hover:text-gray-100 hover:bg-orange-400") + " p-4 border-l border-r border-gray-400"}
                            onClick={() => this.selectChange(2, side, baseline, 'status')}
                        >
                            <i className="fas fa-question"/> 
                        </button>
                        <button 
                            type="button" 
                            className={(status === 3 ? "bg-red-500 text-gray-100" : "bg-gray-300 text-atec-dark-grey hover:text-gray-100 hover:bg-red-500") + " p-4 rounded-r-md"}
                            onClick={() => this.selectChange(3, side, baseline, 'status')}
                        >
                            <i className="fas fa-thumbs-down"/>
                        </button>
                    </div>
                    <div className="basis-2/5 inline-flex rounded-md shadow-sm justify-center text-sm" role="group">
                        <button 
                            type="button" 
                            className={(status === 3 ? "cursor-not-allowed bg-gray-500" : (peak === 1 ? "bg-green-500 text-gray-100" : "bg-gray-300 text-atec-dark-grey hover:text-gray-100 hover:bg-green-500"))  + " p-4 rounded-l-lg"}
                            onClick={() => this.selectChange(1, side, baseline, 'peak')}
                            disabled={status === 3}
                        >
                            <i className="fas fa-thumbs-up"/>
                        </button>
                        <button 
                            type="button" 
                            className={(status === 3 ? "cursor-not-allowed bg-gray-500" : (peak === 2 ? "bg-orange-400 text-gray-100" : "bg-gray-300 text-atec-dark-grey hover:text-gray-100 hover:bg-orange-400")) + " p-4 border-l border-r border-gray-400"}
                            onClick={() => this.selectChange(2, side, baseline, 'peak')}
                            disabled={status === 3}
                        >
                            <i className="fas fa-question"/> 
                        </button>
                        <button 
                            type="button" 
                            className={(status === 3 ? "cursor-not-allowed bg-gray-500" : (peak === 3 ? "bg-red-500 text-gray-100" : "bg-gray-300 text-atec-dark-grey hover:text-gray-100 hover:bg-red-500")) + " p-4 rounded-r-md"}
                            onClick={() => this.selectChange(3, side, baseline, 'peak')}
                            disabled={status === 3}
                        >
                            <i className="fas fa-thumbs-down"/>
                        </button>
                    </div>
                </div>
                <div className="flex justify-around gap-x-2 min-h-[18px] text-center text-xs">
                    <div className="basis-1/5"></div>
                    <div className="basis-2/5 truncate select-none">{this.STATUS_TEXT[status]}</div>
                    <div className="basis-2/5 select-none">{this.PEAK_MARKER_TEXT[peak]}</div>
                </div>
            </div>
            
        );
    }

    getUpdatedDatasetWithAnnotations(datasets, annotations) {
        console.log('Generating updated dataset');
        return datasets.map((dataset, index) => {
            let selectedOption = 0; // Default will give us cyan
            let color, hoverColor;
            if (dataset.isBaseline) {
                const statusSelection = annotations?.[index/2]?.status;
                selectedOption = (statusSelection) ? statusSelection : 0;
                const result = this.getLineColor(selectedOption);
                color = result.color;
                hoverColor = result.hoverColor;
            }
            return {
                ...dataset,
                borderColor: (dataset.isBaseline) ? color : dataset.borderColor,
                hoverBorderColor: (dataset.isBaseline) ? hoverColor : dataset.hoverBorderColor,
            };
        });
    }

    getUpdatedChartAfterAnnotations(annotations, key = null, loadedChart = null) {
        if (!key) {
            key = this.state.selectedStimSite + '/' + this.state.selectedRecordSite;
        }
        if (!loadedChart) {
            loadedChart = this.state.loadedCharts[key];
        }
        if (annotations && loadedChart) {
            console.log('Getting updated chart using annotations', annotations)
            const leftDataset = this.getUpdatedDatasetWithAnnotations(loadedChart.left.datasets, annotations.left);
            const rightDataset = this.getUpdatedDatasetWithAnnotations(loadedChart.right.datasets, annotations.right);
            return {
                ...loadedChart,
                left: {
                    ...loadedChart.left,
                    datasets: leftDataset
                },
                right: {
                    ...loadedChart.right,
                    datasets: rightDataset
                }
            }
        }
    }

    checkComplete() {
        const incomplete = {};
        for (const stimSite of Object.keys(this.state.stimSites)) {
            for (const recordSite of Object.keys(this.state.recordSites)) {
                const key = stimSite + '/' + recordSite;
                if (this.state.loadedCharts[key] === undefined) {
                    continue;
                }
                const leftBaselines = this.state.loadedCharts[key].left.totalBaselines;
                const rightBaselines = this.state.loadedCharts[key].right.totalBaselines;
                const expectedComplete = (leftBaselines + rightBaselines) * 2;
                const record = this.state.dataAnnotations[key];
                if (record === undefined && expectedComplete !== 0) {
                    incomplete[key] = expectedComplete;
                    continue;
                }
                if (this.adjudicateMode) { // Adjudicate mode only requires a user to view it not necessarily all records to be complete
                    continue;
                }

                let complete = 0;
                for (const side of Object.values(record)) {
                    for (const baseline of Object.values(side)) {
                        if (baseline.status === 3) {
                            complete += 2; // peak is disabled so count it as valid
                            continue;
                        }
                        complete += Object.keys(baseline).length;
                    }
                }
                if (complete < expectedComplete) {
                    incomplete[key] = (expectedComplete - complete);
                }
            }
        }
        return incomplete;
    }

    completeClicked() {
        const incomplete = this.checkComplete();
        if (Object.keys(incomplete).length === 0) {
            this.showFinishDialog(true);
            return;
        }

        let incompleteItems = [];
        for (const [key, value] of Object.entries(incomplete)) {
            incompleteItems.push(
                <li key={key}>
                    <span className="font-semibold text-lg">{(this.adjudicateMode) ? key : (key + ": " + value)}</span>
                </li>
            )
        }
        this.setState({
            incompleteContent: (
                <div>
                    <div className="font-bold text-xl">{(this.adjudicateMode) ? "Not Viewed Yet" : "Remaining Items"}</div>
                    <ul className="list-disc ml-7 capitalize">{incompleteItems}</ul>
                </div>
            ),
            showIncompleteDialog: true
        })
    }

    // Finish Handler
    async classificationComplete(flag, notes) {
        this.setState({leftLoading: true, rightLoading: true});
        console.log('Classification complete, sending completed annotations to server');
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ssepset/finish', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...await this.context.getAuthHeader() },
            body: JSON.stringify({
                flag_record: flag,
                notes: notes,
                annotations: this.state.dataAnnotations,
            })
        }).catch(error => {
            window.alert('Something went wrong')
            console.error('Failed to save annotations', error);
        })

        if (response.status != 200) {
            window.alert('Something went wrong')
            console.error(response);
            return;
        }
        this.reset();
    }

    // EVENT LISTENERS //
    selectChange(index, side, baseline, type) {
        if (this.adjudicateMode) {
            this.setState({selectedAnnotation: 'OVERRIDE'});
            this.updateAnnotationSelectedMemory('OVERRIDE');
        }
        this.updateAnnotations(side, baseline, type, index, (type === 'status'));
    }

    radioChange(e, type) {
        switch(type) {
            case 'stim':
                this.setState({selectedStimSite: e.target.value});
                this.changeDisplay(e.target.value, this.state.selectedRecordSite);
            break;
            case 'record':
                this.setState({selectedRecordSite: e.target.value});
                console.log('changing..');
                (async () => {
                    this.changeDisplay(this.state.selectedStimSite, e.target.value);
                })()
                
            break;
        }
    }

    changeAll(newValue) {
        let newAnnotations = {'left': {}, 'right': {}}
        const set = this.state.selectedStimSite + '/' + this.state.selectedRecordSite;
        const loadedCharts = this.state.loadedCharts;
        const leftBaselines = loadedCharts[set].left.totalBaselines || 0;
        const rightBaselines = loadedCharts[set].right.totalBaselines || 0;
        for (let i = 0; i < leftBaselines; i++) {
            newAnnotations['left'][i+1] = (newValue === 3 ? {status: newValue} : {peak: newValue, status: newValue});
        }
        for (let i = 0; i < rightBaselines; i++) {
            newAnnotations['right'][i+1] = (newValue === 3 ? {status: newValue} : {peak: newValue, status: newValue});
        }
        const chart = this.getUpdatedChartAfterAnnotations(newAnnotations, set, loadedCharts[set])

        this.setState({
            dataAnnotations: {
                ...this.state.dataAnnotations,
                [set]: newAnnotations
            },
            leftAnnotations: newAnnotations.left,
            rightAnnotations: newAnnotations.right,
            leftChart: chart.left,
            rightChart: chart.right,
            loadedCharts: {
                ...loadedCharts,
                [set]: chart
            }
        });

        if (this.adjudicateMode) {
            this.setState({selectedAnnotation: "OVERRIDE"});
            this.updateAnnotationSelectedMemory("OVERRIDE", set);
        }
    }

    // Adjudication //
    selectAnnotationChange(value) {
        this.setState({selectedAnnotation: value});
        this.updateAnnotationSelectedMemory(value);
        if (value !== "N/A" || value !== "OVERRIDE") {
            this.selectUserAnnotation(value);
        }
    }

    updateAnnotationSelectedMemory(selection, currentSet = null) {
        if (!currentSet) {
            currentSet = this.state.selectedStimSite + '/' + this.state.selectedRecordSite;
        }
        this.setState({
            selectedAnnotationMemory: {
                ...this.state.selectedAnnotationMemory,
                [currentSet]: selection
            }
        });
    }

    selectUserAnnotation(userId) {
        const key = this.state.selectedStimSite + '/' + this.state.selectedRecordSite;
        const loadedCharts = this.state.loadedCharts;
        const annotations = this.state.allAnnotations?.[key]?.[userId];
        const chart = this.getUpdatedChartAfterAnnotations(annotations, key, loadedCharts[key]);
        if (chart && annotations) {
            this.setState({
                dataAnnotations: {
                    ...this.state.dataAnnotations,
                    [key]: annotations
                },
                leftAnnotations: annotations.left,
                rightAnnotations: annotations.right,
                loadedCharts: {
                    ...loadedCharts,
                    [key]: chart
                },
                leftChart: chart.left,
                rightChart: chart.right
            })
        }
    }

    mapAnnotationToLocalVersion(annotations) {
        try {
            const localVersion = {};
            for (const [userId, entry] of Object.entries(annotations)) {
                for (const [side, items] of Object.entries(entry)) {
                    for (const item of items) {
                        const name = (item.name || '').toLowerCase();
                        if (name === 'baseline1' || name === 'baseline2') {
                            const position = (name === 'baseline1') ? 1 : 2;
                            localVersion[userId] = {
                                ...localVersion[userId],
                                [side]: {
                                    ...localVersion[userId]?.[side],
                                    [position]: {
                                        ...(item.peak_classification && {peak: item.peak_classification}),
                                        ...(item.status_classification && {status: item.status_classification}),
                                    }
                                }
                            }
                        } else {
                            console.warn('Could not map annotation to local version, name was not baseline1 or baseline2, name: ' + name);
                        }
                    }
                }
            }
            return localVersion;
        } catch (err) {
            console.error('Failed to map annotation to local version', err);
        }
        return null;
    }

    // Finish Handler
    async adjudicationComplete() {
        this.setState({leftLoading: true, rightLoading: true});
        console.log('Adjudication complete, sending adjudicated annotations to server');
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ssepset/finish?adjudicate=true&record_id=' + this.adjudicateRecordId, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...await this.context.getAuthHeader() },
            body: JSON.stringify({
                annotations: this.state.dataAnnotations,
            })
        }).catch(error => {
            window.alert('Something went wrong')
            console.error('Failed to save annotations', error);
        })

        if (response.status != 200) {
            window.alert('Something went wrong')
            console.error(response);
            this.setState({leftLoading: false, rightLoading: false})
            return;
        }
        this.props.navigate(-1);
    }


    render() {
        return (
            <div className="flex flex-1 flex-col shadow-lg rounded bg-atec-dark-grey mb-6 w-full text-gray-100 p-2 min-w-fit">
                <div className="flex flex-wrap flex-col flex-auto">
                    <div className="flex flex-row flex-wrap p-3 mb-3">
                        <div className="w-1/2 h-[70vh]">
                            <div className="flex justify-center font-bold uppercase">{this.state.leftChart?.name || "Loading.."}</div>
                                <div className="relative w-full h-full">
                                {this.state.leftLoading && 
                                    <div role="status" className="absolute top-[8px] right-[8px]">
                                        <svg className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-atec-neon-green-100" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>  
                                }
                                {this.state.leftChart && 
                                    <ClassificationChart
                                        xTitle='Time'
                                        xUnit='ms'
                                        yTitle='Voltage'
                                        yUnit='uV'
                                        data={this.state.leftChart}
                                        annotations={this.state.leftChartAnnotations || {}}
                                        decimateData={true}
                                    />
                                }
                            </div>
                        </div>
                        <div className="w-1/2 h-[70vh]">
                            <div className="flex justify-center font-bold uppercase">{this.state.rightChart?.name || "Loading.."}</div>
                            <div className="relative w-full h-full">
                                {this.state.rightLoading && 
                                    <div role="status" className="absolute top-[8px] right-[8px]">
                                        <svg className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-atec-neon-green-100" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>  
                                }
                                {this.state.rightChart && 
                                    <ClassificationChart
                                        xTitle='Time'
                                        xUnit='ms'
                                        yTitle='Voltage'
                                        yUnit='uV'
                                        data={this.state.rightChart}
                                        annotations={this.state.rightChartAnnotations || {}}
                                        decimateData={true} 
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-col items-center p-4 w-full" data-testid="ssep-annotation-controls">
                    <h2 className="w-full text-center leading-border-text -mb-3 pr-2 pl-2 z-10">
                        <span className="bg-atec-dark-grey px-1 text-md font-medium">Annotations</span>
                    </h2>
                    <div className="flex flex-grow flex-wrap xl:flex-nowrap justify-around px-5 pb-1 pt-4 border border-atec-light-grey rounded-md lg h-full min-w-fit">
                        <div className="flex xl:hidden w-full flex-wrap items-start justify-center">
                            <button 
                                className="bg-green-500 active:bg-green-600 text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150"
                                onClick={() => this.changeAll(1)}
                                disabled = {this.state.leftLoading || this.state.rightLoading} 
                            >
                                <i className="fas fa-thumbs-up"/>
                            </button>
                            <button 
                                className="bg-red-500 active:bg-red-600 text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150"
                                onClick={() => this.changeAll(3)}
                                disabled = {this.state.leftLoading || this.state.rightLoading}
                            >
                                <i className="fas fa-thumbs-down"/>
                            </button>
                        </div> 
                        <div className="flex flex-col flex-grow mb-2 gap-2 max-w-lg">
                            <div className="flex justify-center font-bold">Channel:<span className=" ml-2 capitalize font-medium">{this.state.leftChart?.name || "Loading.."}</span></div>
                            <hr className="border-b-1 border-atec-light-grey border-opacity-50" />
                            <div className="flex flex-nowrap justify-around">
                                <div className="basis-1/5 text-center">Baseline</div>
                                <div className="basis-2/5 text-center">Status</div>
                                <div className="basis-2/5 text-center">Peak Marking</div>
                            </div>
                            {(() => {
                                let rows = [];
                                for (let i = 0; i < this.state.leftChart?.totalBaselines; i++) {
                                    rows.push(this.buildClassificationRow('left', i+1))
                                }
                                return rows;
                            })()}
                        </div>
                        <div className="hidden xl:flex flex-nowrap items-start justify-center">
                            <button 
                                className="bg-green-500 active:bg-green-600 text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150"
                                onClick={() => this.changeAll(1)}
                                disabled = {this.state.leftLoading || this.state.rightLoading}
                            >
                                <i className="fas fa-thumbs-up"/>
                            </button>
                            <button 
                                className="bg-red-500 active:bg-red-600 text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150"
                                onClick={() => this.changeAll(3)}
                                disabled = {this.state.leftLoading || this.state.rightLoading} 
                            >
                                <i className="fas fa-thumbs-down"/>
                            </button>
                        </div> 
                        <div className="flex flex-grow flex-col mb-2 gap-2 max-w-lg">
                            <div className="flex justify-center font-bold">Channel:<span className=" ml-2 capitalize font-medium">{this.state.rightChart?.name || "Loading.."}</span></div>
                            <hr className="border-b-1 border-atec-light-grey border-opacity-50" />
                            <div className="flex flex-nowrap justify-around">
                                <div className="basis-1/5 text-center">Baseline</div>
                                <div className="basis-2/5 text-center">Status</div>
                                <div className="basis-2/5 text-center">Peak Marking</div>
                            </div>
                            {(() => {
                                let rows = [];
                                for (let i = 0; i < this.state.rightChart?.totalBaselines; i++) {
                                    rows.push(this.buildClassificationRow('right', i+1))
                                }
                                return rows;
                            })()}
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="flex-col items-center p-4">
                            <h2 className="w-full text-center leading-border-text -mb-3 pr-2 pl-2 z-10">
                                <span className="bg-atec-dark-grey px-1 text-md font-medium">Stimulus Site</span>
                            </h2>
                            <div 
                                className="flex flex-col justify-around px-5 pb-1 pt-4 border border-atec-light-grey rounded-md lg h-full min-w-[200px]"
                                onChange={(event) => {this.radioChange(event, 'stim')}}
                                >
                                {(() => {
                                    let stimSites = [];
                                    for (let [key, value] of Object.entries(this.state.stimSites)){
                                        stimSites.push(
                                            <label key={key} className={"capitalize" + ((this.state.loadedCharts[key + '/' + this.state.selectedRecordSite] === undefined) ? " text-gray-300 cursor-not-allowed" : "")}>
                                                <input type="radio" value={key} name="stim-site" 
                                                defaultChecked={this.state.selectedStimSite === key} 
                                                disabled={(this.state.loadedCharts[key + '/' + this.state.selectedRecordSite] === undefined)}
                                                /> {value}
                                            </label>
                                        );
                                    }
                                    return stimSites;
                                })()}
                            </div>
                        </div>
                        <div className="flex-col items-center p-4">
                            <h2 className="w-full text-center leading-border-text -mb-3 pr-2 pl-2 z-10">
                                <span className="bg-atec-dark-grey px-1 text-md font-medium">Recording Site</span>
                            </h2>
                            <div 
                                className="flex flex-col justify-around px-5 pb-1 pt-4 border border-atec-light-grey rounded-md lg h-full min-w-[200px]"
                                onChange={(event) => {this.radioChange(event, 'record')}}
                                >
                                {(() => {
                                    let recordSites = [];
                                    for (let [key, value] of Object.entries(this.state.recordSites)){
                                        recordSites.push(
                                            <label key={key} className={"capitalize" + ((this.state.loadedCharts[this.state.selectedStimSite + '/' + key] === undefined) ? " text-gray-300 cursor-not-allowed" : "")}>
                                                <input type="radio" value={key} name="record-site" 
                                                defaultChecked={this.state.selectedRecordSite === key} 
                                                disabled={(this.state.loadedCharts[this.state.selectedStimSite + '/' + key] === undefined)}
                                                /> {value}
                                            </label>
                                        );
                                    }
                                    return recordSites;
                                })()}
                            </div>
                        </div>
                    </div>
                    {this.adjudicateMode && (
                        <div className="mt-4">
                            <h2 className="w-full text-center leading-border-text -mb-3 pr-2 pl-2 z-10">
                                <span className="bg-atec-dark-grey px-1 text-md font-medium">Adjudication</span>
                            </h2>
                            <div className="flex w-full flex-wrap px-5 pb-1 pt-4 border border-atec-light-grey rounded-md lg h-full">
                                <div className="flex flex-nowrap flex-grow basis-1/2 px-1 justify-center items-start">
                                    <div className="pr-4">Selected Annotation</div>
                                    <select 
                                        value={this.state.selectedAnnotation} 
                                        onChange={(e) => this.selectAnnotationChange(e.target.value)} 
                                        disabled={this.state.selectedAnnotation === "N/A"}
                                        className={"w-1/2 min-w-[160px] p-2.5 pr-6 border rounded-md shadow-sm outline-none appearance-none text-ellipsis focus:ring-white focus:border-inherit "
                                            + (this.state.selectedAnnotation === "N/A" ? "cursor-not-allowed bg-gray-600" : (this.state.selectedAnnotation === "OVERRIDE") ? "bg-orange-400" : "bg-sky-600")}
                                        >
                                        <option value="N/A" hidden>N/A</option>
                                        <option value="OVERRIDE" hidden>Override</option>

                                        {(() => {
                                            let options = [];
                                            for (const userId of Object.keys(this.state.allAnnotations[this.state.selectedStimSite + '/' + this.state.selectedRecordSite] || {})) {
                                                options.push(
                                                    <option className="bg-atec-dark-grey" key={userId} value={userId}>{this.state.annotatorDetails[userId]?.user_email || userId}</option>
                                                )
                                            }
                                            return options
                                        })()}
                                    </select>
                                </div>
                                <div className={"flex flex-col basis-1/2 flex-grow px-1" + ((this.state.selectedAnnotation !== "N/A" && this.state.selectedAnnotation !== "OVERRIDE") ? "" : " invisible")}>
                                    <div className="flex justify-start font-bold">Classifier:<span className=" ml-2 font-medium">{this.state.annotatorDetails[this.state.selectedAnnotation]?.user_email || "N/A"}</span></div>
                                    <div className="flex justify-start font-bold">Flagged Record:<span className=" ml-2 font-medium">{(this.state.annotatorDetails[this.state.selectedAnnotation]?.caused_flag) ? "True" : "False"}</span></div>
                                    <div className="flex justify-start font-bold">Notes:<span className=" ml-2 font-medium">{this.state.annotatorDetails[this.state.selectedAnnotation]?.notes || "N/A"}</span></div>
                                </div>
                                <div className={"flex flex-col w-full mt-4" + ((this.adjudicateMode) ? "" : " hidden")}>
                                    <div className="font-bold text-center">Discrepancies</div>
                                    <hr className="border-b-1 border-atec-light-grey border-opacity-50 mt-1 mb-2 w-full" />
                                    <div className="flex flex-wrap flex-grow justify-around items-start">
                                        <div className="flex flex-col flex-grow basis-1/2 p-2 min-w-[300px]">
                                            <div className="font-bold text-center">Left Chart</div>
                                            <ul className="list-disc ml-8">
                                                {(() => {
                                                    let list = [];
                                                    let key = 1;
                                                    for (const discrepancy of this.state.leftDiscrepancies || []) {
                                                        list.push(<li key={key}>{discrepancy}</li>);
                                                        key++;
                                                    }
                                                    return (list.length > 0) ? list : (<li className="list-none">No Discrepancies</li>);
                                                })()}
                                            </ul>
                                        </div>
                                        <div className="flex flex-col flex-grow basis-1/2 p-2 min-w-[300px]">
                                            <div className="font-bold text-center">Right Chart</div>
                                            <ul className="list-disc ml-8">
                                                {(() => {
                                                    let list = [];
                                                    let key = 1;
                                                    for (const discrepancy of this.state.rightDiscrepancies || []) {
                                                        list.push(<li>{discrepancy}</li>);
                                                        key++;
                                                    }
                                                    return (list.length > 0) ? list : (<li className="list-none">No Discrepancies</li>);
                                                })()}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex flex-wrap justify-center mx-3">
                    <button
                        className=" bg-sky-700 active:bg-sky-600 text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150 w-60"
                        type="button"
                        onClick={() => this.completeClicked()}
                        disabled={this.state.leftLoading || this.state.rightLoading}
                    >
                        Complete
                    </button>
                </div>
                
                {(this.adjudicateMode) ? 
                    (<ConfirmPopup
                        show = {this.state.showFinishDialog}
                        confirmText = "Finish Adjudication"
                        confirmCallback = {() => {this.showFinishDialog(false); this.adjudicationComplete()}}
                        cancelCallback = {() => {this.showFinishDialog(false);}}
                        content = {<div>Are you sure you want to finish adjudicating this record?</div>}
                        title = "Finished?"
                        disabled = {this.state.leftLoading || this.state.rightLoading}
                    ></ConfirmPopup>) :
                    (<FinishRecordPopup
                        show = {this.state.showFinishDialog}
                        confirmText = "Finish Classification"
                        confirmCallback = {(flag, notes) => {this.showFinishDialog(false); this.classificationComplete(flag, notes);}}
                        cancelCallback = {() => {this.showFinishDialog(false);}}
                        title = "Additional Details"
                        disabled = {this.state.leftLoading || this.state.rightLoading}
                    ></FinishRecordPopup>)
                }
                
                <InfoPopup
                    show={this.state.showIncompleteDialog}
                    closeCallback={() => this.setState({showIncompleteDialog: false})}
                    content={this.state.incompleteContent}
                    title={(this.adjudicateMode) ? "Adjudication Incomplete!" : "Classification Incomplete!"}
                />
            </div>
        );
    }
}

export default SSEPClassificationView;