import React from "react";
import { useState } from "react";
import { useAuth } from "contexts/AuthContext";

// components
import Alert from "components/Alerts/Alert.js";
import CardWithButton from "components/Cards/CardWithButton";
import ConfirmPopup from "components/Popups/ConfirmPopup";

export default function OtherTools() {
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    backgroundColor: "",
    textColor: "",
    text: "",
    icon: ""
  })
  const { getAuthHeader } = useAuth();
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [showDeleteAllWarningPopup, setShowDeleteAllWarningPopup] = useState(false);

  const [deleteCaseId, setDeleteCaseId] = useState("");


  const deleteAllRecords = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/delete-all-records', 
    {
      method: "DELETE",
      headers: await getAuthHeader(),
    });

    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Success",
        icon: "fas fa-check-circle"
      });
    }
      setShowAlert(true);
  }

  const lookupUserID = async () => {
    setUserId('Fetching...');
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/lookup-user-id', 
    {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
      body: JSON.stringify({user_email: userEmail})
    });
    

    if (response.status != 200) {
        setUserId('Not Found..')
        setAlert({
            backgroundColor: "bg-red-500",
            textColor: "text-white",
            text: response.statusText,
            icon: "fas fa-exclamation-triangle"
        });
    } else {
        const body = await response.json();
        setUserId(body.user_id);
        setAlert({
            backgroundColor: "bg-atec-neon-green-400",
            textColor: "text-white",
            text: "Success",
            icon: "fas fa-check-circle"
        });
    }
      setShowAlert(true);
  }

  const triggerSomething = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/trigger-something', 
    {
      method: "GET",
      headers: await getAuthHeader(),
    });

    
    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Success",
        icon: "fas fa-exclamation-triangle"
      });
    }
    setShowAlert(true);
  }

  const deleteEntireCase = async () => {
    setShowAlert(false);
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/delete-case-record?case_id=' + deleteCaseId, 
    {
      method: "DELETE",
      headers: await getAuthHeader(),
    });

    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
      setShowAlert(true);
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Successfully removed all records attached to case: " + deleteCaseId,
        icon: "fas fa-check-circle"
      });
      setShowAlert(true);
    }
  }



  return (
    <div className="flex flex-wrap">
      <div className="w-full px-4">
        <Alert showAlert={showAlert} closeCallback={() => setShowAlert(false)} alertOptions={alert}/>
        <CardWithButton title="Other Tools" content={
            <div>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Lookup User ID
                </h6>
                <div className="flex flex-wrap mb-5">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        User Email
                      </label>
                      <input
                        type="text"
                        value={userEmail} 
                        onChange={(e) => {setUserEmail(e.target.value)}}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        User ID
                      </label>
                      <input
                        type="text"
                        readOnly
                        disabled
                        value={userId}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-sky-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={lookupUserID}
                    >
                      Lookup
                    </button>
                  </div>
                </div>
                
                <div className="mt-20">
                    <hr/>
                    <h6 className="text-red-600 text-sm mt-3 mb-6 font-bold uppercase">
                        <i className="fas fa-skull-crossbones"/> DANGER ZONE <i className="fas fa-skull-crossbones"/>
                    </h6>
                </div>

                <div className="flex flex-wrap items-center mb-5">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Case Id
                        </label>
                        <input
                          type="text"
                          value={deleteCaseId} 
                          onChange={(e) => {setDeleteCaseId(e.target.value)}}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={deleteEntireCase}
                      >
                        Delete Case
                      </button>
                    </div>
                  </div>
                
                <div className="flex flex-wrap py-4 justify-between">
                    <button
                        className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => {setShowDeleteAllWarningPopup(true);}}
                    >
                        Delete All Records <i className="fas fa-radiation"/>
                    </button>
                </div>

                <div className="flex flex-wrap py-4 justify-between">
                    <button
                        className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={triggerSomething}
                    >
                        Trigger Something Endpoint
                    </button>
                </div>
            </div>
        }/>
      </div>
      <ConfirmPopup
          show = {showDeleteAllWarningPopup}
          confirmCallback = {() => {deleteAllRecords(); setShowDeleteAllWarningPopup(false);}}
          cancelCallback = {() => {setShowDeleteAllWarningPopup(false);}} 
          confirmText = "Do it anyway!"
          cancelText = "Cancel"
          title = "WARNING!!!"
          content = {
            <div className="font-semibold text-lg">
                You are about to delete all records in both SQL and NoSQL Databases!<br/><span className="text-red-500 font-bold">This action is IRREVERSIBLE!</span><br/>This action will also be logged and tied to your user account
            </div>}
      />
    </div>
  );
}
