import { createContext, useContext, useMemo, useReducer } from "react";
import { useAuth } from "./AuthContext";


export const StatsContext = createContext();

const statsReducer = (state, action) => {
    switch(action.type) {
        case "REPLACE":
            return {...action.stats};
        case "CLEAR":
            return {};
        default:
            return state;
    }
}

export const StatsProvider = ({ children }) => {

    const [stats, setStats] = useReducer(statsReducer, {});

    const { getAuthHeader } = useAuth();

    const getStats = async () => {
        if (stats && Object.keys(stats).length) {
            return stats;
        }
        console.log('Fetching Site Stats...');
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/getstats', {method: "GET", headers: await getAuthHeader()});
        if (response.status !== 200) {
            console.error('Failed to load stats...');
            return null;
        }
        const loaded = await response.json();
        setStats({type: 'REPLACE', stats: loaded})
        return loaded;
    }


    const value = useMemo(
        () => ({
            getStats,
        }),
        [stats]
    );

    return <StatsContext.Provider value={value}>{children}</StatsContext.Provider>;
};

export const useStatContext = () => {
  return useContext(StatsContext);
};