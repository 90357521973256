import React from "react";
import { Routes, Route} from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import UserSidebar from "components/Sidebar/UserSidebar";
import HeaderStats from "components/Headers/HeaderStats.js";

// views
import Users from "views/admin/Users.js";
import SiteSettings from "../views/admin/SiteSettings";
import TemgRecords from "views/admin/TemgRecords";
import EditUser from "views/admin/EditUser";
import { SecureRoute } from "components/SecureRoute";
import { AdminPermissions } from "util/Permissions";
import SSEPRecords from "views/admin/SSEPRecords";
import { StatsProvider } from "contexts/StatsContext";
import Ingestion from "views/admin/Ingestion";
import PendingIngestion from "../views/admin/PendingIngestion";
import ExtractionTokens from "../views/admin/ExtractionTokens";

export default function Admin() {
  return (
    <>
      <UserSidebar />
      <div className="relative md:ml-64">
        <div className="fixed top-0 bottom-0 right-0 left-0 -z-50 bg-white"></div>
        <AdminNavbar />
        <div className="relative bg-atec-light-grey md:pt-10 pb-10 pt-12 hidden lg:block">
          <StatsProvider>
            <HeaderStats/>
          </StatsProvider>
        </div>
        <div className="relative py-4 px-5 lg:px-10 mx-auto w-full lg:-mt-40">
            <Routes>
              <Route path="users" element={
                <SecureRoute permissionRequired={AdminPermissions.UserManagement}>
                  <Users/>
                </SecureRoute>
              }/>
              <Route path="site-settings" element={
                <SecureRoute permissionRequired={AdminPermissions.SiteSettings}>
                  <SiteSettings/>
                </SecureRoute>
              }/>
              <Route path="users/:id" element={
                <SecureRoute permissionRequired={AdminPermissions.UserManagement}>
                  <EditUser/>
                </SecureRoute>
              }/>
              <Route path="ingestion" element={
                <SecureRoute permissionRequired={AdminPermissions.Ingestion}>
                  <Ingestion/>
                </SecureRoute>
              } />
              <Route path="pending-ingestion" element={
                <SecureRoute permissionRequired={AdminPermissions.Ingestion}>
                  <PendingIngestion/>
                </SecureRoute>
              } />
              <Route path="temg-records" element={
                <SecureRoute permissionRequired={AdminPermissions.TEMG}>
                  <TemgRecords/>
                </SecureRoute>
              } />
              <Route path="ssep-records" element={
                <SecureRoute permissionRequired={AdminPermissions.SSEP}>
                  <SSEPRecords/>
                </SecureRoute>
              } />
              <Route path="extraction-api" element={
                <SecureRoute permissionRequired={AdminPermissions.Extraction}>
                  <ExtractionTokens/>
                </SecureRoute>
              } />
            </Routes>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
