import React from "react";

// components

export default function CardProfile({name, role, lastLogin, stats, extraContent}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
        <div className="px-6">
          <div className="text-center mt-5">
            <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
              {name}
            </h3>
            <div className="mb-2 text-blueGray-600 mt-5">
              <i className="fas fa-calendar mr-2 text-lg text-blueGray-400"></i>
              Last Login: {lastLogin}
            </div>
            <div className="mb-2 text-blueGray-600 mt-5">
              <i className="fas fa-user-tag mr-2 text-lg text-blueGray-400"></i>
              Role(s): {role}
            </div>
          </div>
          <div className="mt-10 py-5 border-t border-blueGray-200">
            <div className="text-center">
              <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                User Stats
              </h3>
            </div>
            <div className="grid gap-x-0.5 gap-y-1 grid-cols-2 max-w-xs text-center mx-auto text-blueGray-500">
              <div className="text-base">Items Classified</div>
              <div className="text-base">{stats.itemsClassified}</div>
              <div className="text-base">Average Time Spent</div>
              <div className="text-base">{stats.avgTimeSpent}</div>
            </div>
          </div>
          {extraContent}
        </div>
      </div>
    </>
  );
}
