import React from "react";
import PropTypes from "prop-types";

const Alert = ({ showAlert, closeCallback, alertOptions}) => {
  // const [showAlert, setShowAlert] = React.useState(true);
  return (
    <>
      {showAlert ? (
        <div className={"px-6 py-4 border-0 rounded relative mb-4 " + alertOptions.backgroundColor + " " + alertOptions.textColor}>
        <span className="text-xl inline-block mr-5 align-middle">
            <i className={alertOptions.icon} />
        </span>
        <span className="inline-block align-middle mr-8">
            {alertOptions.text}
        </span>
        <button
            className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            onClick={closeCallback}
        >
            <span>×</span>
        </button>
        </div>
      ) : null}
    </>
  );
};

Alert.defaultProps = {
  alertOptions: {
    backgroundColor: "bg-lightBlue-500",
    textColor: "text-white",
    text: "",
    icon: "fas fa-bell"
  }
}

Alert.propTypes = {
  showAlert: PropTypes.bool,
  closeCallback: PropTypes.func,
  alertOptions: PropTypes.exact({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string
  })
}

export default Alert;