import React from "react";
import { Routes, Route} from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import UserSidebar from "components/Sidebar/UserSidebar";

import Query from "views/developer/Query";
import { SecureRoute } from "components/SecureRoute";
import { DeveloperPermission } from "util/Permissions";
import TemgTools from "views/developer/TemgTools";
import SSEPTools from "views/developer/SSEPTools";
import IngestionTools from "views/developer/IngestionTools";
import OtherTools from "views/developer/OtherTools";

export default function User() {
  return (
    <>
      <UserSidebar />
      <div className="relative min-h-screen md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-atec-light-grey md:pt-32 pb-32 pt-12 hidden lg:block"></div>
        <div className="relative py-4 px-5 lg:px-10 mx-auto w-full lg:-mt-40">
          <SecureRoute requiredRole={DeveloperPermission}>
            <Routes>
              <Route path="execute-query" element={<Query/>}/>
              <Route path="temg-tools" element={<TemgTools/>}/>
              <Route path="ssep-tools" element={<SSEPTools/>}/>
              <Route path="ingestion-tools" element={<IngestionTools/>}/>
              <Route path="other-tools" element={<OtherTools/>}/>
            </Routes>
          </SecureRoute>
        </div>
      </div>
    </>
  );
}
