import React from "react";
import { useState } from "react";
import { useAuth } from "contexts/AuthContext";

// components
import Alert from "components/Alerts/Alert.js";
import CardWithButton from "components/Cards/CardWithButton";

export default function Query() {
  const [showSqlAlert, setShowSqlAlert] = useState(false);
  const [sqlAlert, setSqlAlert] = useState({
    backgroundColor: "",
    textColor: "",
    text: "",
    icon: ""
  })
  const [sqlQuery, setSqlQuery] = useState("");
  const [sqlQueryResult, setSqlQueryResult] = useState("");
  const { getAuthHeader } = useAuth();

  const submitSqlQuery = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/sql-query', 
    {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
      body: JSON.stringify({query: sqlQuery})
    });

    if (response.status != 200) {
      setSqlAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setSqlAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Success",
        icon: "fas fa-check-circle"
      });

      const body = await response.json();
      setSqlQueryResult(JSON.stringify(body, null, 4));
    }
      setShowSqlAlert(true);
  }



  return (
    <div className="flex flex-wrap">
      <div className="w-full px-4">
        <Alert showAlert={showSqlAlert} closeCallback={() => setShowSqlAlert(false)} alertOptions={sqlAlert}/>
        <CardWithButton title="SQL Query" content={
          <>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Query
              <textarea rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={sqlQuery} onChange={e => setSqlQuery(e.target.value)}></textarea>
            </label>
            {sqlQueryResult !== "" &&
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Query Result
                <textarea rows="25" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={sqlQueryResult} disabled={true}></textarea>
              </label>
            }
          </>
        } submitBtnName = "Run SQL Query" submitHandler = {() => submitSqlQuery()}/>
      </div>
    </div>
  );
}
