import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from 'contexts/AuthContext';

// components

import Alert from "components/Alerts/Alert.js";
import CardWithButton from "../../components/Cards/CardWithButton";

export default function SiteSettings() {
  const { getAuthHeader } = useAuth();

  const [showAlert, setShowAlert] = useState(false)
  const [alert, setAlert] = useState({
    backgroundColor: "",
    textColor: "",
    text: "",
    icon: ""
  })

  const [siteSettings, setSiteSettings] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/get-site-settings', {method: "GET", headers: await getAuthHeader()});
      setLoading(false);
      if (response.status != 200 && response.status != 404) {
        console.error("Something went wrong");
        window.alert('Failed to load settings, please try again later');
        return;
      }
      const body = await response.json();
      if (!body.site_settings) {
        console.error('Site settings not present');
        return
      }
      setSiteSettings(body.site_settings);
    })();
  }, []);

  const submitHandler = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/update-site-settings', 
    {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
      body: JSON.stringify({site_settings: siteSettings})
    });

    
    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Updated Site Settings Successfully",
        icon: "fas fa-check"
      });
    }
    setShowAlert(true);
  }

  const updateSiteSettings = (key, value) => {
    setSiteSettings({
      ...siteSettings,
      [key]: value
    })
  }

  
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full px-4">
          <Alert showAlert={showAlert} closeCallback={() => setShowAlert(false)} alertOptions={alert}/>
          <CardWithButton title="Site Settings" content={
            <div className="flex flex-wrap gap-4">
              {(function() {
                const settings = [];
                for (const [key, value] of Object.entries(siteSettings)) {
                  const name = key.replaceAll("_", " ");
                  const updateFunc = (val) => {
                    if (typeof value === 'number') {
                      const parsed = parseInt(val)
                      if (isNaN(parsed)) {
                        console.error('Cannot update value with non-number value');
                        return;
                      }
                      updateSiteSettings(key, parsed);
                      return;
                    }
                    updateSiteSettings(key, val);
                  }

                  settings.push(
                    <div key={key}>
                      <label
                        className="flex flex-col text-blueGray-600 text-sm font-bold capitalize m-2"
                      > {name}
                        {(() => {
                          switch (typeof value) {
                            case 'boolean':
                              return (
                                <input
                                  type="checkbox"
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 ease-linear transition-all duration-150"
                                  checked={siteSettings[key]}
                                  onChange={e => updateFunc(e.target.checked)}
                                />
                              );
                            default:
                              return (
                                <input
                                  type={typeof value === 'number' ? 'number' : 'text'}
                                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                                  value={siteSettings[key]}
                                  onChange={e => updateFunc(e.target.value)}
                                />
                              );
                          }
                        })()}

                      </label>
                    </div>
                  );
                }
                if (settings.length === 0) {
                  return (isLoading) ? <div>Loading...</div> : <div>No Settings Found</div>;
                }
                return settings;
              })()}
              
            </div>
          } submitBtnName = "Save" submitHandler = {submitHandler}/>
        </div>
      </div>
    </>
  );
}
