import React from "react";
import { useState } from "react";
import { useAuth } from "contexts/AuthContext";

// components
import Alert from "components/Alerts/Alert.js";
import CardWithButton from "components/Cards/CardWithButton";

export default function ExtractionTokens() {
  const [alertShowing, showAlert] = useState(false);
  const [alert, setAlert] = useState({
    backgroundColor: "",
    textColor: "",
    text: "",
    icon: ""
  })
  const [expiresIn, setExpiresIn] = useState(null);
  const [tokenType, setTokenType] = useState(null);
  const [scopes, setScopes] = useState(null);
  const [token, setToken] = useState(null);
  const [response, setResponse] = useState(null);

  const [firewalledMode, setFirewalledMode] = useState(false);
  const { getExtractionToken } = useAuth();

  const requestToken = async () => {
    setExpiresIn(null);
    setTokenType(null);
    setToken(null);
    setScopes(null);
    setResponse(null);
    try {
      const result = await getExtractionToken(firewalledMode);
      const expiration = new Date(result.expiration * 1000);
      setExpiresIn(expiration.toLocaleString());
      setScopes(result.scope || 'Unknown');
      setToken(result.token);
      setTokenType('Bearer');
      setResponse(JSON.stringify(result, null, 4));
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Token Successfully Retrieved",
        icon: "fas fa-check-circle"
      })
    } catch (err) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: err.message,
        icon: "fas fa-exclamation-triangle"
      })
    }
    showAlert(true);
  }

  const copyToken = () => {
    navigator.clipboard.writeText(token);
    const element = document.getElementById('copy-text');
    element.classList.remove('invisible')
  }

  const showHideResponse = (e) => {
    if (e.target.innerText === 'Show') {
      e.target.innerText = 'Hide';
    } else {
      e.target.innerText = 'Show';
    }
    const element = document.getElementById('raw-response');
    element.classList.toggle('hidden');
    element.classList.toggle('block');
  }


  return (
    <div className="flex flex-wrap">
      <div className="w-full px-4">
        <Alert showAlert={alertShowing} closeCallback={() => showAlert(false)} alertOptions={alert}/>
        <CardWithButton title="Request an extraction API token" content={
          <div className="flex flex-col">
            <label htmlFor="permadmin" className="inline-flex relative items-center my-5 cursor-pointer">
                <input type="checkbox" value="" id="permadmin" className="sr-only peer" checked={firewalledMode} onChange={e => setFirewalledMode(e.target.checked)}/>
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-500"></div>
                <span className="ml-3 text-sm font-bold text-blueGray-600 dark:text-blueGray-300 uppercase">Firewalled Mode</span>
            </label>
    
            {token !== null && <hr className="mb-2"/>}

            {token !== null && 
              <div className="flex flex-wrap justify-between text-lg font-medium mb-5">
                <div><strong className="mr-2">Type:</strong>{tokenType || 'N/A'}</div>
                <div><strong className="mr-2">Scopes:</strong>{(scopes !== null) ? scopes.replaceAll(' ', ', ') : 'N/A'}</div>
                <div><strong className="mr-2">Expires In:</strong>{expiresIn || 'N/A'}</div>
              </div>
            }
            
            {token !== null && 
              <div className="relative">
                <div className="absolute top-0 left-16 flex flex-nowrap items-center transition-all">
                  <i className="fa-regular fa-copy fa-xl cursor-pointer hover:scale-125 duration-300 ease-out active:text-green-700" onClick={copyToken}/>
                  <p id="copy-text" className="ml-2 invisible">Copied!</p>
                </div>
                <label className="block font-bold text-lg">Token
                    <textarea rows="6" className="block border border-gray-300 bg-gray-50 p-2.5 w-full text-sm font-medium rounded-lg mt-2 resize-none" value={token} disabled/>
                </label>
              </div>
            }

            {response !== null &&
              <div className={(token !== null) ? "mt-10" : ""}>
                <hr/>
                <label className="block font-bold text-lg">Raw Response <span className="font-medium text-sm ml-3 cursor-pointer" onClick={showHideResponse}>Show</span>
                  <textarea rows="13" id="raw-response" className="border border-gray-300 bg-gray-50 p-2.5 w-full text-sm font-medium rounded-lg mt-2 hidden" value={response} disabled/>
                </label>
              </div>
              
            }
          </div>
        } submitBtnName = "Request Token" submitHandler = {() => requestToken()}/>
      </div>
    </div>
  );
}
