export const AdminPermissions = {
    UserManagement: 'admin:user-management',
    SiteSettings: 'admin:site-settings',
    Ingestion: 'admin:ingestion',
    TEMG: 'admin:temg',
    SSEP: 'admin:ssep',
    Extraction: 'admin:extraction'
}
export const AdjudicationPermissions = {
    TEMG: 'adjudicate:temg',
    SSEP: 'adjudicate:ssep'
}
export const ClassificationPermissions = {
    TEMG: 'classify:temg',
    SSEP: 'classify:ssep'
}
export const DeveloperPermission = 'developer'

export const getRoleString = (permissions) => {
    if (!Array.isArray(permissions)) {
        console.error('Failed to get role string, permissions is not an array');
        return "Unknown"
    }
    let roles = [];
    if (Object.values(AdminPermissions).some(el => permissions.includes(el))) {
        roles.push('Administrator');
    }
    if (Object.values(AdjudicationPermissions).some(el => permissions.includes(el))) {
        roles.push('Adjudicator');
    }
    if (Object.values(ClassificationPermissions).some(el => permissions.includes(el))) {
        roles.push('Classifier');
    }
    if (permissions.includes(DeveloperPermission)) {
        roles.push('Developer');
    }

    return roles.join(', ');
}