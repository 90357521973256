import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    
    static getDerivedStateFromError(error) {
        return { hasError: true, error: error };
    }
    componentDidCatch(error, errorInfo) {
        console.error('ERROR BOUNDARY', error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        const stack = JSON.stringify(this.state.error.stack, null, 2).replaceAll('"', '').split('\\n');
        const stackRows = stack.map((row, index) => {
            if (index === 0) {
                return <div className="text-blueGray-700 font-bold text-lg mx-2">{row}</div>;
            } else {
                return <div className="text-blueGray-700 font-bold text-lg ml-6">- {row}</div>
            }
        })
        return (
            <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center'>
                <div className="flex flex-grow justify-center" data-testid="error-boundary">
                    <div className="relative flex flex-col shadow-lg rounded-lg bg-blueGray-100 min-h-fit w-full md:w-2/3 items-center py-5 px-10">
                        <div className="text-blueGray-700 font-bold text-2xl">Oh No! There Was An Error <i className="fas fa-exclamation"/></div>
                        <hr className="mt-4 mb-4 border-1 w-full border-blueGray-400" />
                        <div className="text-blueGray-700 font-bold text-xl mb-2 capitalize">Error: {this.state.error.message}</div>
                        <div className='flex flex-col justify-start'>
                            {stackRows}
                        </div>
                    </div>
                </div>
            </div>
            
        );
      }
  
      return this.props.children; 
    }
  }