import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import CardProfile from "components/Cards/CardProfile";
import Alert from "components/Alerts/Alert.js";
import { useUserService } from "contexts/UserContext";
import { AdminPermissions, AdjudicationPermissions, ClassificationPermissions, getRoleString } from "../../util/Permissions";
import CardWithButton from "../../components/Cards/CardWithButton";
import ConfirmPopup from "../../components/Popups/ConfirmPopup";

const permissionReducer = (state, action) => {
    if (action.reset) {
        return {...action.reset}
    }
    if (action.enable === undefined || action.permission === undefined || state[action.permission] === undefined) {
      return {...state}
    }
    return {
        ...state,
        [action.permission]: (action.enable) ? true : false
    }
}

const mapPermissions = (permissions) => {
    let mapped = {};
    Object.values(AdminPermissions).map (p => {
        mapped[p] = permissions.includes(p);
    })
    Object.values(AdjudicationPermissions).map (p => {
        mapped[p] = permissions.includes(p);
    })
    Object.values(ClassificationPermissions).map (p => {
        mapped[p] = permissions.includes(p);
    })
    return mapped;
};

export default function EditUser() {
    let params = useParams();
    const [showUserUpdateAlert, setShowUserUpdateAlert] = useState(false);
    const [user, setUser] = useState(null);
    const [roleString, setRoleString] = useState("N/A");
    const { getUser, assignUserPermissions, removeUserPermissions, changeApprovalStatus, removeGuardianEnrollment } = useUserService();
    const [permissions, updatePermissions] = useReducer(permissionReducer, {});
    const [showMFAResetPopup, setShowMFAResetPopup] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const user = await getUser(params.id);
                console.log(user);
                setUser(user);
                updatePermissions({reset: mapPermissions(user.permissions)});
                setRoleString(getRoleString(user.permissions));
            } catch (err) {
                console.error(err);
                setUserAlert({
                    backgroundColor: "bg-red-500",
                    textColor: "text-white",
                    text: "Error Retrieving User",
                    icon: "fas fa-exclamation-triangle"
                })
                setShowUserUpdateAlert(true);
            }
        })()
    }, [params.id])


    const [userAlert, setUserAlert] = useState({
        backgroundColor: "",
        textColor: "",
        text: "",
        icon: ""
    })

    // TODO: Centralize and update this once information is available
    const stats = {
        itemsClassified: "N/A",
        avgTimeSpent: "N/A"
    }

    const saveHandler = async () => {
        console.log('Updating permissions');
        let removePermissions = [];
        let addPermissions = [];
        for (const [key, value] of Object.entries(permissions)) {
            if (value && !user.permissions.includes(key)) {
                addPermissions.push(key)
            } else if (!value && user.permissions.includes(key)) {
                removePermissions.push(key);
            }
        }

        let fail = false;
        if (removePermissions.length > 0) {
            const result = await removeUserPermissions(params.id, removePermissions)
            if (!result) fail = true;
        }

        if (addPermissions.length > 0) {
            const result = await assignUserPermissions(params.id, addPermissions)
            if (!result) fail = true;
        }

        if (fail) {
            setUserAlert({
                backgroundColor: "bg-red-500",
                textColor: "text-white",
                text: "Error Updating User",
                icon: "fas fa-exclamation-triangle"
            })
            setShowUserUpdateAlert(true);
        } else {
            setUserAlert({
                backgroundColor: "bg-atec-neon-green-400",
                textColor: "text-white",
                text: "Updated User Successfully",
                icon: "fas fa-check"
              });
            setShowUserUpdateAlert(true);
        }
    }

    const handleChange = (permission, checked) => {
        updatePermissions({permission: permission, enable: checked})
    }

    const buildPermissionToggle = (permission, name, status, changeCallback) => {
        return (
          <label className="inline-flex relative items-center mb-5 cursor-pointer" key={permission}>
            <input type="checkbox" className="sr-only peer" checked={status} onChange={e => changeCallback(permission, e.target.checked)}/>
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-atec-neon-green-200"></div>
            <span className="ml-3 text-sm font-bold text-blueGray-600 dark:text-blueGray-300 uppercase">{name}<span className="italic ml-2">"{permission}"</span></span>
          </label>
        )
    }

    const userStatus = user?.dc_access || false;
    const lastLocation = user?.last_location;

    const toggleDCAccess = async () => {
        const result = await changeApprovalStatus(params.id, !userStatus);
        if (result) {
            setUser({...user, dc_access: !userStatus})
            setUserAlert({
                backgroundColor: "bg-atec-neon-green-400",
                textColor: "text-white",
                text: "Updated Status Successfully Modified",
                icon: "fas fa-check"
            });
            setShowUserUpdateAlert(true);
        } else {
            setUserAlert({
                backgroundColor: "bg-red-500",
                textColor: "text-white",
                text: "Error Modifying User Status",
                icon: "fas fa-exclamation-triangle"
            })
            setShowUserUpdateAlert(true);
        }
    }

    const mfaEnrollments = user?.multifactor?.enrolled || [];
    const mfaLastModified = user?.multifactor?.last_modified || 'N/A';

    const resetGuardian = async () => {
        const result = await removeGuardianEnrollment(params.id);
        if (result) {
            setUserAlert({
                backgroundColor: "bg-atec-neon-green-400",
                textColor: "text-white",
                text: "Successfully Reset MFA",
                icon: "fas fa-check"
            });
            setShowUserUpdateAlert(true);
        } else {
            setUserAlert({
                backgroundColor: "bg-red-500",
                textColor: "text-white",
                text: "Error Resetting MFA",
                icon: "fas fa-exclamation-triangle"
            })
            setShowUserUpdateAlert(true);
        }
    }

  return (
    <>
      <ConfirmPopup
        show={showMFAResetPopup}
        confirmCallback={() => {setShowMFAResetPopup(false); resetGuardian()}}
        cancelCallback={() => setShowMFAResetPopup(false)}
        title="Please Confirm"
        content={
            <div className="font-semibold text-lg">
                Are you sure you want to reset Guardian Enrollments for this user?<br/>
                Doing so will force the user to reconfigure Guardian on next login
            </div>
        }
      ></ConfirmPopup>
      <div className="flex flex-wrap">
        <div className={"w-full px-4" + ((user) ? " lg:w-8/12" : "")} >
            <Alert showAlert={showUserUpdateAlert} closeCallback={() => setShowUserUpdateAlert(false)} alertOptions={userAlert}/>
        </div>
        {user &&
        <>
            <div className="w-full lg:w-8/12 px-4">
                <CardWithButton 
                    title = {`Modify user '${user.email}`}
                    submitBtnName = "Save"
                    submitHandler = {saveHandler}
                    content = {
                        <div>
                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                User Information
                            </h6>
                            <div className="flex flex-wrap py-4">
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Name</label>
                                        <input
                                            type="text"
                                            value={user.name}
                                            disabled={true}
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full cursor-not-allowed"
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Email</label>
                                        <input
                                            type="text"
                                            value={user.email}
                                            disabled={true}
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full cursor-not-allowed"
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300" />
                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">User Permissions</h6>
                            <div className="ml-3 flex flex-col">
                                <h6 className="text-blueGray-500 text-sm mb-3 font-semibold uppercase">Administrative</h6>
                                {Object.entries(AdminPermissions).map(p => buildPermissionToggle(p[1], p[0], permissions[p[1]], handleChange))}
                                <h6 className="text-blueGray-500 text-sm mb-3 font-semibold uppercase">Adjudication</h6>
                                {Object.entries(AdjudicationPermissions).map(p => buildPermissionToggle(p[1], p[0], permissions[p[1]], handleChange))}
                                <h6 className="text-blueGray-500 text-sm mb-3 font-semibold uppercase">Classification</h6>
                                {Object.entries(ClassificationPermissions).map(p => buildPermissionToggle(p[1], p[0], permissions[p[1]], handleChange))}
                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300" />
                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Administrative Actions
                            </h6>
                            <div className="text-center flex justify-between">
                                <div>Data Classification Access: <span className={(userStatus) ? "text-green-500" : "text-red-500"}>{(userStatus ? "Approved" : "Not Approved")}</span></div>
                                <button
                                className={((!userStatus) ? "bg-green-500 active:bg-green-400" : "bg-red-500 active:bg-red-400") + " text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"}
                                type="button"
                                onClick={toggleDCAccess}
                                >
                                {(!userStatus) ? "Approve User" : "Revoke Approval"}
                                </button>
                            </div>
                            <hr className="mt-6 border-b-1 border-blueGray-300" />
                            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                Multifactor Authentication
                                <span className={"text-blueGray-700 float-right " + (mfaEnrollments.length > 0 ? "text-green-500" : "text-red-500")}>{(mfaEnrollments.length > 0 ? "Enrolled" : "Not Enrolled")}</span>
                            </h6>
                            {mfaEnrollments.length > 0 &&
                               <div className="flex flex-wrap justify-between mb-2 items-end">
                                    <div className="flex-col">
                                        <div>Enrollment(s): <span className="capitalize">{mfaEnrollments.join(', ')}</span></div>
                                        <div>Last Modified: {mfaLastModified}</div>
                                    </div>
                                    <button
                                        className="bg-red-500 active:bg-red-400 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 h-fit"
                                        type="button"
                                        onClick={() => setShowMFAResetPopup(true)}
                                    >Reset Guardian Enrollments</button>
                                </div>
                            }
                        </div>
                    }/>
            </div>
            <div className="w-full lg:w-4/12 px-4">
                <CardProfile 
                    name={user.name}
                    lastLogin={user.last_login}
                    role={roleString || 'None'}
                    stats={stats}
                    extraContent={
                        <div className="mt-5 py-5 border-t border-blueGray-200">
                            <div className="text-center">
                                <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                    Administrative Details
                                </h3>
                                <div className="mb-2 text-blueGray-600 mt-5">
                                    <i className="fas fa-location-dot mr-2 text-lg text-blueGray-400"></i>
                                    Last Location: {lastLocation?.city_name || lastLocation?.country_code || 'Unknown'}
                                    </div>
                                    <div className="mb-2 text-blueGray-600 mt-5">
                                    <i className="fas fa-user-secret mr-2 text-lg text-blueGray-400"></i>
                                    IP Address: {user?.last_ip}
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
        </>
        }
        
      </div>
    </>
  );
}
