import { useSearchParams } from "react-router-dom";

export default function AuthError() {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error') || 'Unknown';
  const description = searchParams.get('error_description') || '';
  return (
    
      <div className="flex justify-center">
        <div className="relative flex flex-col shadow-lg rounded-lg bg-blueGray-100 min-h-fit w-full md:w-1/2 items-center py-5 px-10">
          <div className="text-blueGray-700 font-bold text-2xl">Authentication Error <i className="fas fa-exclamation"/></div>
          <hr className="mt-4 mb-4 border-1 w-full border-blueGray-400" />
          <div className="text-blueGray-700 font-bold text-xl mb-2 capitalize">Error: {error}</div>
          <div className="text-blueGray-700 font-bold text-lg mx-6">{description}</div>
        </div>
      </div>
  );
}