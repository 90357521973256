import { useEffect, useState } from 'react';
import { version } from '../../version';

export default function SystemInfo() {
    const [serverInfoLoading, setServerInfoLoading] = useState(true);
    const [serverInfo, setServerInfo] = useState({});
    const [serverInfoLoadingFailed, setServerInfoLoadingFailed] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/system-info');
                setServerInfo(await response.json());
            } catch (err) {
                setServerInfoLoadingFailed(true);
            }
            setServerInfoLoading(false);
        })();
    }, []);
    return (
        <div className="flex justify-center">
            <div className="relative flex flex-col shadow-lg rounded-lg bg-blueGray-100 min-h-fit w-full md:w-2/3 items-center py-5 px-10">
            <div className="text-blueGray-700 font-bold text-2xl">Project CloudBreak - System Info</div>
            <hr className="mt-4 mb-4 border-1 w-full border-blueGray-400" />
                <div className="w-full flex flex-wrap">
                    <div className="basis-1/2 min-w-fit mb-6">
                        <div className="text-blueGray-700 font-bold text-xl mb-2">Client</div>
                        <ul className="text-blueGray-700 text-lg mb-2">
                            <li><span className="font-bold">Version:</span> {version}</li>
                            <li><span className="font-bold">Node Environment:</span> {process.env.NODE_ENV}</li>
                            <li><span className="font-bold">Backend API:</span> <a className="underline" href={process.env.REACT_APP_BACKEND_URL}>{process.env.REACT_APP_BACKEND_URL}</a></li>
                            <li><span className="font-bold">Default API Audience:</span> {process.env.REACT_APP_AUTH0_API_AUDIENCE}</li>
                            <li><span className="font-bold">Extraction API Audience:</span> {process.env.REACT_APP_AUTH0_API_AUDIENCE}/extraction</li>
                            <li><span className="font-bold">Auth0 Domain:</span> {process.env.REACT_APP_AUTH0_DOMAIN}</li>
                        </ul>
                    </div>
                    <div className="basis-1/2 min-w-fit mb-6">
                        <div className="text-blueGray-700 font-bold text-xl mb-2">Server</div>
                        <ul className="text-blueGray-700 text-lg mb-2">
                            {(() => {
                                if (serverInfoLoading) {
                                    return <li>Loading...</li>
                                }
                                if (serverInfoLoadingFailed) {
                                    return <li>Failed to load server info...</li>
                                }
                                return Object.entries(serverInfo).map(([key, value]) => {
                                    return <li><span className="font-bold capitalize">{key.replace('_', ' ')}:</span> {value}</li>
                                });
                            })()}
                        </ul>
                        
                    </div>
                </div>
            </div>
        </div>
        
    )
}