import React, { useEffect, useState } from "react";
import { useStatContext } from "contexts/StatsContext";

// components

import CardStats from "components/Cards/CardStats.js";

export default function HeaderStats() {
  const { getStats } = useStatContext();
  const [stats, setStats] = useState({});
  useEffect(() => {
    getStats().then(stats => {
      if (stats) {
        setStats(stats);
      }
    });
  }, [getStats]);
  
  

  return (
    <>
      {/* Header */}
      <div className="relative pb-32 pt-12 hidden lg:block">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="NEED CLASSIFICATION - TEMG"
                  statTitle={stats.unclassified_temg !== undefined ? stats.unclassified_temg : "..."}
                  statIconName="fas fa-file-medical-alt"
                  statIconColor="bg-atec-neon-green-400"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="NEED CLASSIFICATION - SSEP"
                  statTitle={stats.unclassified_ssep !== undefined ? stats.unclassified_ssep : "..."}
                  statIconName="fa-solid fa-bed-pulse"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="NEED ADJUDICATION - TEMG"
                  statTitle={stats.needs_adjudication_temg !== undefined ? stats.needs_adjudication_temg : "..."}
                  statIconName="fa-solid fa-gavel"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="NEED ADJUDICATION - SSEP"
                  statTitle={stats.needs_adjudication_ssep !== undefined ? stats.needs_adjudication_ssep : "..."}
                  statIconName="fa-solid fa-gavel"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
