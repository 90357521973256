import { createContext, useContext, useEffect, useMemo, useState } from "react";
import Loading from "components/Loading";
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { useNavigate } from "react-router-dom";

const audience = process.env.REACT_APP_AUTH0_API_AUDIENCE || (process.env.REACT_APP_BACKEND_URL + '/api');

const config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    authorizationParams: {
        redirect_uri: window.location.origin + '/auth/callback',
        audience: audience,
    }
};


export const AuthContext = createContext();

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

const hasAuthParams = (searchParams = window.location.search) => {
  return (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) &&
  STATE_RE.test(searchParams);
} 
  

export const AuthProvider = ({ children }) => {
  const [authClient] = useState(() => new Auth0Client(config));
  const [isLoading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(null);
  const [permissionSubscribers, setPermissionSubscribers] = useState([])

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      console.log("Auth0 Initializing..");
      await authClient.checkSession();
      setLoading(false);
    })();
  }, [])

  // Timer to check permissions every 5 minutes
  useEffect(() => {
    const interval = setInterval(async () => {
      console.log('Pinging backend to see if permissions changed...');
      try {
        const updatedPermissions = await fetchPermissions();
        if (!Array.isArray(permissions) || JSON.stringify(permissions) !== JSON.stringify(updatedPermissions)) {
            setPermissions(updatedPermissions);
            notifyPermissionChange(updatedPermissions);
        }
      } catch (err) {
        console.error(err);
      }
    }, 300000);
    return () => {
      clearInterval(interval);
    };
  }, [permissions]);


  // Subscribe to permission changes
  const subscribeToPermissionUpdate = (callback) => {
    if (typeof callback === 'function') {
      setPermissionSubscribers([...permissionSubscribers, callback])
    } else {
      throw new Error('Callback must be a function');
    }
  }

  const notifyPermissionChange = (newPermissions) => {
    console.log('Permissions changed, notifying subscribers...', newPermissions);
    for (const callback of permissionSubscribers) {
      callback(newPermissions);
    }
  }

  const fetchPermissions = async () => {
    console.log('Fetching Permissions');
    const result = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/auth/get-permissions', {method: "GET", headers: await getAuthHeader()});
    if (result.ok) {
      return result.json();
    }
  }

  const login = () => {
    authClient.isAuthenticated().then(authenticated => {
      if (!authenticated) {
        authClient.loginWithRedirect();  
      } else {
        navigate('/user/myaccount');
      }
    })
  };

  const handleRedirectCallback = () => {
    if (hasAuthParams()) {
      authClient.handleRedirectCallback().then(result => {
        navigate('/');
      }).catch(err => {
        console.error(err);
        navigate({pathname: '/auth/error', search: window.location.search});
      });
    } else {
      navigate('/');
    }
  }

  const logout = () => {
    authClient.logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  const getAuthHeader = async () => {
    const token = await authClient.getTokenSilently();
    return { Authorization: `Bearer ` + token };
  }

  const getCurrentUser = async () => {
    const user = await authClient.getUser();
    user.permissions = await getPermissions();
    return user;
  }

  const getUserDisplayName = async () => {
    const user = await authClient.getUser();
    return user?.name;
  }

  const getUserName = async () => {
    const user = await authClient.getUser();
    return user?.email;
  }

  const isLoggedIn = async () => {
    return await authClient.isAuthenticated();
  }

  const getPermissions = async () => {
    if (permissions) {
      return permissions;
    } else {
      const result = await fetchPermissions();
      if (Array.isArray(result)) {
        setPermissions(result);
        return result;
      }
      console.error('Failed to get permissions');
      return [];
    }
  }

  const getExtractionToken = async (firewalledMode=false) => {
    const tempClient = await createAuth0Client({
      domain: config.domain,
      clientId: config.clientId,
      authorizationParams: {
        audience: audience + '/extraction',
        redirect_uri: config.authorizationParams.redirect_uri
      }
    })

    const requestedScope = firewalledMode ? 'use:extraction-firewalled' : 'use:extraction-normal';
    const token = await tempClient.getTokenWithPopup({
      cacheMode: 'off',
      authorizationParams: {
        prompt: 'consent',
        scope: requestedScope
      }
    })

    const decoded = JSON.parse(atob(token.split('.')[1]));
    const scopes = decoded.scope.split(' ');
    if (!scopes.includes(requestedScope)) {
      throw Error("Unauthorized: You do not have permission to use that mode");
    }
    return {token: token, scope: decoded.scope, expiration: decoded.exp, audience: decoded.aud}
  }

  const value = useMemo(
    () => ({
      login,
      logout,
      isLoggedIn,
      getAuthHeader,
      getExtractionToken,
      getUserDisplayName,
      getUserName,
      handleRedirectCallback,
      getCurrentUser,
      getPermissions,
      subscribeToPermissionUpdate
    }),
    [authClient, permissions]
  );

  return <AuthContext.Provider value={value}>
    {(isLoading) ? <Loading/> : children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
