/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { AdminPermissions, AdjudicationPermissions, ClassificationPermissions } from "util/Permissions";

export default function UserSidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const { logout, getUserName, getPermissions, subscribeToPermissionUpdate } = useAuth();
  const [username, setUsername] = useState("");
  const [permissions, setPermissions] = useState([]);
  let location = useLocation();

  useEffect(() => {
    getUserName().then((username) => setUsername(username));
    getPermissions().then(permissions => {
      setPermissions(permissions);
    })
    subscribeToPermissionUpdate((newPermissions) => setPermissions(newPermissions));
  }, [getPermissions])

  const buildItem = (link, faClass, name) => {
    return (
      <li className="items-center">
        <Link
          className={
            "text-xs uppercase py-3 font-bold block " +
            (location.pathname == link
              ? "text-atec-neon-green-200 hover:text-atec-neon-green-200"
              : "text-white hover:text-atec-neon-green-200")
          }
          to={link}
        >
          <div className="flex items-center">
            <div className="w-5 text-center">
              <i
                className={
                  faClass + " text-sm " +
                  (location.pathname == link
                    ? "opacity-75"
                    : "text-blueGray-300")
                }/>
            </div>
            <div className="ml-2">
              {name}
            </div>
          </div>
        </Link>
      </li>
    );
  }




  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-atec-dark-grey flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-start w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-white opacity-80 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <div className="flex grow md:grow-0 justify-center">
            <Link
              className="md:block md:pb-2 mr-0 inline-block p-4 px-0 text-center"
              to="/"
            >
            <img
              alt="..."
              className="max-w-[200px]"
              src={(process.env.NODE_ENV === 'development') ? require("assets/img/atec-logo-dev.png") : require("assets/img/atec-logo.png")}
            />
            </Link>
          </div>
          <div
            className={
              "bg-atec-dark-grey md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-white opacity-80 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Menu
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-white opacity-80 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <hr className={"my-4 md:min-w-full" + (collapseShow != "hidden" ? " hidden" : "")} />
            {/* Heading */}
            <h6 className="md:min-w-full text-atec-neon-green-100 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              {username}
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {buildItem('/user/myaccount', 'fas fa-user', 'My Account')}
              <li className="items-center">
                <a
                  className="text-xs uppercase py-3 font-bold block cursor-pointer text-white hover:text-atec-neon-green-200"
                  onClick={logout}
                >
                  <div className="flex items-center">
                    <div className="w-5 text-center">
                      <i className="fas fa-sign-out-alt text-sm text-blueGray-300"/>
                    </div>
                    <div className="ml-2">
                      Logout
                    </div>
                  </div>
                </a>
              </li>
              
            </ul>

            {/* Divider */}
            { Object.values(AdminPermissions).some(p => permissions.includes(p)) && // SITE ADMIN LINKS
              <>
                <hr className="my-4 md:min-w-full" />
                <h6 className="md:min-w-full text-atec-neon-green-100 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Site Administration
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  { permissions.includes(AdminPermissions.UserManagement) &&
                    buildItem('/admin/users', 'fas fa-users', 'Users')
                  }
                  { permissions.includes(AdminPermissions.SiteSettings) &&
                    buildItem('/admin/site-settings', 'fas fa-gear', 'Site Settings')
                  }
                  { permissions.includes(AdminPermissions.Ingestion) &&
                    buildItem('/admin/ingestion', 'fas fa-download', 'Ingestion')
                  }
                  { permissions.includes(AdminPermissions.Ingestion) &&
                    buildItem('/admin/pending-ingestion', 'fas fa-file-import', 'Pending Ingestion')
                  }
                  { permissions.includes(AdminPermissions.TEMG) &&
                    buildItem('/admin/temg-records', 'fas fa-file-medical-alt', 'TEMG Records')
                  }
                  { permissions.includes(AdminPermissions.SSEP) &&
                    buildItem('/admin/ssep-records', 'fas fa-bed-pulse', 'SSEP Records')
                  }
                  { permissions.includes(AdminPermissions.Extraction) &&
                    buildItem('/admin/extraction-api', 'fas fa-tornado', 'Extraction API')
                  }
                </ul>
            </>
            }

            { Object.values(AdjudicationPermissions).some(p => permissions.includes(p)) && // Adjudication Menu
              <>
                <hr className="my-4 md:min-w-full" />
                <h6 className="md:min-w-full text-atec-neon-green-100 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Data Adjudication
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  { permissions.includes(AdjudicationPermissions.TEMG) &&
                    buildItem('/user/adjudicate-temg', 'fa-solid fa-gavel', 'Adjudicate TEMG')
                  }
                  { permissions.includes(AdjudicationPermissions.SSEP) &&
                    buildItem('/user/adjudicate-ssep', 'fa-solid fa-gavel', 'Adjudicate SSEP')
                  }
                </ul>
            </>
            }


            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-atec-neon-green-100 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Tools
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              { permissions.includes(ClassificationPermissions.SSEP) &&
                buildItem('/user/classify-ssep', 'fas fa-fingerprint', 'Classify SSEP')
              }
              { permissions.includes(ClassificationPermissions.TEMG) &&
                buildItem('/user/classify-temg', 'fas fa-fingerprint', 'Classify TEMG')
              }
            </ul>
            {/* Divider */}
            { permissions.includes('developer') && // DEVELOPER LINKS
              <>
                <hr className="my-4 md:min-w-full" />
                <h6 className="md:min-w-full text-atec-neon-green-100 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Developer Tools
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  {buildItem('/developer/execute-query', 'fa-solid fa-terminal', 'Execute Query')}
                  {buildItem('/developer/temg-tools', 'fas fa-hammer', 'TEMG Tools')}
                  {buildItem('/developer/ssep-tools', 'fas fa-wrench', 'SSEP Tools')}
                  {buildItem('/developer/ingestion-tools', 'fas fa-download', 'Ingestion Tools')}
                  {buildItem('/developer/other-tools', 'fas fa-toolbox', 'Other Tools')}
                </ul>
            </>
            }
          </div>
        </div>
      </nav>
    </>
  );
}
