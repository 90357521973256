import React, {useState, useEffect} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Decimation
} from 'chart.js';
import { Chart, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import colors from "tailwindcss/colors";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Decimation,
  zoomPlugin,
  annotationPlugin
);

const ClassificationChart = ({
    data = [],
    decimateData = false,
    annotations = {}, 
    title = "",
    clickListener,
    xTitle,
    xUnit,
    yTitle,
    yUnit,
    customLegend,
    lineColor = colors.gray[300],
    hoverColor = colors.gray[100],
    disableTooltip = false,
}) => {

  const options = {
    responsive: true,
    normalized: true,
    color: colors.gray[200],
    parsing: false,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      decimation: {
        enabled: decimateData,
        algorithm: 'lttb',
        samples: 150,
        threshold: 400
      },
      legend: {
        display: false,
        position: 'right',
        title: {
          text: "Legend",
          display: true
        },
      },
      title: {
        color: colors.gray[100]
      },
      zoom: {
        limits: {
          x: {min: 'original', max: 'original'},
        },
        pan: {
          enabled: true,
          mode: 'y',
        },
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: 'ctrl',
          },
          pinch: {
            enabled: true
          },
          mode: 'y',
        }
      },
    },
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          color: colors.gray[100],
          font: {
            weight: 600, 
          }
        },
        grid: {
          display: true,
          color: colors.gray[600]
        },
        ticks: {
          color: colors.gray[300]
        }
      },
      y: {
        title: {
          display: true,
          color: colors.gray[100],
          font: {
            weight: 600,
          },
        },
        grid: {
          display: true,
          color: colors.gray[600]
        },
        ticks: {
          display: false,
        }
      }
    },
    datasets: {
      line: {
        tension: 0.001
      }
    },
    elements: {
      point: {
        radius: 0,
        hoverRadius: 0,
        hitRadius: 10,
        backgroundColor: colors.gray[300]
      },
      line: {
        borderWidth: 2,
        hoverBorderWidth: 3,
        hoverBorderColor: colors.gray[100],
        borderColor: colors.gray[300]
      },
    },
    hover: {
      mode: 'dataset',
    },
    interaction: {
      mode: 'nearest',
    }
  }


  const clickHandler = (e) => {
    const elems = e.chart.getElementsAtEventForMode(e, 'nearest', {intersect: true}, true);
    if (clickListener && elems.length > 0) {
      const item = elems[0];
      const dataX = e.chart.scales.x.getValueForPixel(item.element.x);
      const dataY = e.chart.scales.y.getValueForPixel(item.element.y);
      clickListener(item.datasetIndex, dataX, dataY, item.index);
    }
  }

  ChartJS.register({
    id: "ChartScaleCalculator",
    afterUpdate: function(chart, args, options) {
      // get scale using the difference between middle ticks
      const middleTick = Math.floor(chart.scales.y.ticks.length / 2);
      const diff = Math.abs(chart.scales.y.ticks[middleTick].value - chart.scales.y.ticks[middleTick + 1].value);
      chart.stepSize = Math.round(diff);
    },
    afterDraw: function(chart, args, options) {
      chart.ctx.save();
      chart.ctx.font = '15px serif';
      chart.ctx.fillStyle = 'white';
      chart.ctx.fillText(chart.stepSize + ' ' + yUnit + '/div', 40, 30);
      chart.ctx.restore();
    }
  })

  useEffect(() => {
    return () => {
      ChartJS.unregister({id: "ChartScaleCalculator"})
    }
  }, [])

  if (customLegend) {
    options.plugins.legend.display = false;
    ChartJS.register(customLegend);
  }

  useEffect(() => {
    return () => {
      if (customLegend) {
        console.log('Unregistering custom legend')
        ChartJS.unregister(customLegend);
      }
    }
  }, [])


  options.plugins.title.text = title;
  options.plugins.title.display = (title != "" ? true : false);
  options.plugins.annotation = {annotations};
  options.onClick = clickHandler;
  options.scales.x.title.text = xTitle + ' (' + xUnit + ')';
  options.scales.y.title.text = yTitle + ' (' + yUnit + ')';

  options.elements.point = {
    radius: 0,
    hoverRadius: 0,
    hitRadius: 10,
    backgroundColor: lineColor
  };
  options.elements.line = {
    borderWidth: 2,
    hoverBorderWidth: 3,
    hoverBorderColor: hoverColor,
    borderColor: lineColor
  };


  options.plugins.tooltip = {
    enabled: !disableTooltip,
    callbacks: {
      label: () => {return}, // disable default label
      title: function(context) {
        if (context.length > 0) {
          return context[0].dataset.label || '';
        }
      },
      beforeBody: function(context) {
        if (context.length > 0) {
          let addedOffset = context[0].dataset.offset || 0;
          const voltage = yTitle + ': ' + (context[0].parsed.y - addedOffset).toFixed(2) + ' ' + yUnit;
          return xTitle + ': ' + context[0].label + ' ' + xUnit + '\n' + voltage;
        }
      }
    }
  }

  return (
    <div className="flex h-full">
      <div className="flex flex-col w-full">
              <Line 
              data={data}
              options={options}
              />
      </div>
    </div>
    
    );
}

export default React.memo(ClassificationChart);