import colors from "tailwindcss/colors";
import React from "react";
import ClassifyChart from "components/Charts/ClassificationChart";
import { AuthContext } from "contexts/AuthContext";
import FinishRecordPopup from "components/Popups/FinishRecordPopup";
import LRUCache from "lru-cache";
import { Tooltip } from "flowbite-react";
import Hotkeys from "react-hot-keys";

class TEMGClassificationView extends React.Component {
    legendPlugin;
    setCount = 0;
    waveformSetCache;

    CYAN_COLOR = colors.cyan[400];
    GREEN_COLOR = colors.green[500];
    RED_COLOR = colors.red[500];

    DEFAULT_CONFIDENCE = 0;

    CONFIDENCE_0 = {
        text: 'High',
        classes: ['bg-green-500', 'hover:bg-green-600']
    }
    CONFIDENCE_1 = {
        text: 'Medium',
        classes: ['bg-blue-600', 'hover:bg-blue-700']
    }
    CONFIDENCE_2 = {
        text: 'Low',
        classes: ['bg-red-500', 'hover:bg-red-600']
    }

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            sweepTitle: "N/A",
            interrogatingTitle: "N/A",
            chartAnnotations: {}, // these are annotations made on top of the chart, not in the data
            annotationMode: 0, // 0 => activity 1 => artifact
            dataAnnotations: [],
            waveformCount: 0,
            currentSet: 0,
            showFinishDialog: false,
            loading: true,
            saving: false,
            unhideAll: false,
            multipleAnnotationEnabled: false,
            disableTooltip: false,
        }

        this.waveformSetCache = new LRUCache({
            max: 30,
        });

        this.getCachedSet.bind(this);
        this.setCachedSet.bind(this);
        this.hasCachedSet.bind(this);

        // Bind functions that need access to state
        this.getDataAnnotations = this.getDataAnnotations.bind(this);

        // Bind event listeners
        this.lineClicked = this.lineClicked.bind(this);
        this.clearAnnotationsHandler = this.clearAnnotationsHandler.bind(this);
        this.changeAnnotationMode = this.changeAnnotationMode.bind(this);
        this.getBorderColor = this.getBorderColor.bind(this);

        // Setup Custom Legend
        this.legendPlugin = {
            id: "htmlLegend",
            afterUpdate: this.customLegend.bind(this)
        }
    }

    componentDidMount() {
        this.init();
    }

    // ---- State Updating Functions ---- //

    setSweepTitle(value) {
        this.setState({sweepTitle: value});
    }

    setInterrogatingTitle(value) {
        this.setState({interrogatingTitle: value});
    }

    setCurrentSet(value) {
        this.setState({currentSet: value});
    }

    setWaveformCount(value) {
        this.setState({waveformCount: value});
    }

    setChartAnnotations(annotations) {
        this.setState({chartAnnotations: annotations});
    }

    setChartData(data) {
        this.setState({chartData: data})
    }

    setLoading(isLoading) {
        this.setState({loading: isLoading})
    }

    setSaving(isSaving) {
        this.setState({isSaving: isSaving})
    }

    setAnnotationMode(mode) {
        this.setState({annotationMode: mode})
    }

    setMultipleAnnotationEnabled(enabled) {
        this.setState({multipleAnnotationEnabled: enabled})
    }

    setDataAnnotations(annotations) {
        this.setState({dataAnnotations: annotations});
    }

    updateDataAnnotations(index, annotations) {
        let newAnnotations = [...this.state.dataAnnotations];
        newAnnotations[index] = annotations;
        this.setDataAnnotations(newAnnotations); 
    }

    getCachedSet(set) {
        let key = parseInt(set)
        if (isNaN(key)) {
            console.error('Failed to get cached set, key did not parse to number', set);
            return null;
        }
        return this.waveformSetCache.get(key);
    }

    setCachedSet(set, data) {
        let key = parseInt(set)
        if (isNaN(key)) {
            console.error('Failed to set cached set, key did not parse to number', set);
            return null;
        }
        this.waveformSetCache.set(key, data);
    }

    hasCachedSet(set) {
        return this.waveformSetCache.has(set);
    }

    clearCachedSets() {
        console.log('Clearing cached sets');
        this.waveformSetCache.clear();
    }

    updateView(sweep, interrogating, setIndex, waveforms, userAnnotations) {
        this.setUnhideAll(true);
        this.setInterrogatingTitle(interrogating)
        this.setSweepTitle(sweep);
        this.setCurrentSet(setIndex);
        this.setWaveformCount(waveforms.length);

        let datasets = [];
        const chartAnnotations = {}
        const addAnnotationMark = (name, x, y, color, width, display) => {
            chartAnnotations[name] = {
                type: 'point',
                pointStyle: 'line',
                xValue: x,
                yValue: y,
                borderWidth: width,
                rotation: 90,
                adjustScaleRange: false,
                borderColor: color,
                display: display
            }
        }

        for (let i = 0; i < waveforms.length; i++) {
            const item = waveforms[i];
            datasets.push({
                label: item.name,
                data: item.points,
                segment: {
                    borderColor: this.getBorderColor
                },
                offset: item.offset
            })

            if (item.ref_index != undefined) { 
                const point = item.points[item.ref_index];
                if (point === undefined) {
                    console.error(`ref_index: ${item.ref_index} is not a valid index, size of data points array: ${item.points.length}`);
                } else {
                    addAnnotationMark('ref' + i, point.x, point.y, colors.yellow[400], 3, false);
                }
            } 

            if (item.peak_index != undefined) {
                const point = item.points[item.peak_index];
                if (point === undefined) {
                    console.error(`peak_index: ${item.peak_index} is not a valid index, size of data points array: ${item.points.length}`);
                } else {
                    addAnnotationMark('peak' + i, point.x, point.y, colors.yellow[400], 3, false);
                }
            }

            let showMarkers = false;            
            if (Array.isArray(userAnnotations?.[i])) {
                for (let j = 0; j < userAnnotations[i].length; j++) {
                    const startIndex = userAnnotations[i][j].startIndex;
                    const endIndex = userAnnotations[i][j].endIndex;
                    const type = userAnnotations[i][j].type;
                    if (startIndex != undefined && type != undefined) {
                        const point = item.points[startIndex];
                        addAnnotationMark('start' + i + "_" + j, point.x, point.y, (type == 0) ? this.GREEN_COLOR : this.RED_COLOR, 2, true);
                    }
                    if (endIndex != undefined && type != undefined) {
                        const point = item.points[endIndex];
                        addAnnotationMark('end' + i + "_" + j, point.x, point.y, (type == 0) ? this.GREEN_COLOR : this.RED_COLOR, 2, true);
                    }
                    showMarkers = (showMarkers || (startIndex != undefined && endIndex != undefined && type != undefined)) ? true : false;
                }
            } else {
                const startIndex = userAnnotations?.[i]?.startIndex;
                const endIndex = userAnnotations?.[i]?.endIndex;
                const type = userAnnotations?.[i]?.type;
                if (startIndex != undefined && type != undefined) {
                    const point = item.points[startIndex];
                    addAnnotationMark('start' + i, point.x, point.y, (type == 0) ? this.GREEN_COLOR : this.RED_COLOR, 2, true);
                }
                if (endIndex != undefined && type != undefined) {
                    const point = item.points[endIndex];
                    addAnnotationMark('end' + i, point.x, point.y, (type == 0) ? this.GREEN_COLOR : this.RED_COLOR, 2, true);
                }
                showMarkers = (showMarkers || (startIndex != undefined && endIndex != undefined && type != undefined)) ? true : false;
            }
            if (showMarkers) { // Display ref/peak markings
                if (chartAnnotations['ref' + i]) chartAnnotations['ref' + i].display = true;
                if (chartAnnotations['peak' + i]) chartAnnotations['peak' + i].display = true;
            }
        }
        if (userAnnotations != undefined) {
            this.setDataAnnotations(userAnnotations);
        } else {
            this.setDataAnnotations(Array(waveforms.length));
        }

        this.setChartAnnotations(chartAnnotations);
        this.setChartData({datasets: datasets});
        this.setMultipleAnnotationEnabled(false);
    }

    showFinishDialog(value) {
        console.log("show", value)
        this.setState({showFinishDialog: value});
    }

    setUnhideAll(value, callback) {
        this.setState({unhideAll: value}, callback);
    }

    // -------- Custom Legend ---------- //

    customLegend(chart, args, options) {
        if (this.state.unhideAll) {
            for (let i = 0; i < this.state.chartData.datasets.length; i++) {
                chart.setDatasetVisibility(i, true);
            }
            this.setUnhideAll(false, () => {
                chart.update();
            });
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        for (let i = 0; i < items.length; i++) {
            let container = document.getElementById('legend-container-' + (i+1));
            const item = items[i];
            if (container) {
                if (container.firstChild) {
                    container.firstChild.remove();
                }
                const legendDiv = document.createElement('div');
                legendDiv.style.alignItems = 'center';
                legendDiv.style.display = 'flex';
                legendDiv.style.flexDirection = 'row';
                legendDiv.style.marginLeft = '10px';

                const showHide = document.createElement('div');
                showHide.style.width = '22px';
                showHide.style.marginRight = '10px';
                showHide.style.textAlign = 'center';

                const eyeball = document.createElement('i');
                eyeball.classList = item.hidden ? 'fas fa-eye-slash' : 'fas fa-eye';
                eyeball.style.width = '20px';
                eyeball.style.marginRight = '10px';
                eyeball.style.cursor = 'pointer';

                eyeball.onclick = () => {
                    const visible = chart.isDatasetVisible(item.datasetIndex);
                    chart.setDatasetVisibility(item.datasetIndex, !visible); // dataset line visibility
                    const chartAnnotations = {...this.state.chartAnnotations};
                    const annotationControlsEnabled = this.annotationControlsEnabled(item.datasetIndex)
                    const annotationsToToggle = Object.keys(chartAnnotations).filter(key => (key.startsWith('start' + item.datasetIndex) || key.startsWith('end' + item.datasetIndex)));
                    annotationsToToggle.forEach(key => {
                        chartAnnotations[key].display = (!visible) ? true : false;
                    })
                    if (chartAnnotations['ref' + item.datasetIndex]) { // ref annotation
                        chartAnnotations['ref' + item.datasetIndex].display = (!visible && annotationControlsEnabled) ? true : false;
                    }
                    if (chartAnnotations['peak' + item.datasetIndex]) { // peak annotation
                        chartAnnotations['peak' + item.datasetIndex].display = (!visible && annotationControlsEnabled) ? true : false;
                    }
                    this.setChartAnnotations(chartAnnotations);
                };
            
                // Text
                const textContainer = document.createElement('p');
                textContainer.style.color = item.fontColor;
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
            
                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                showHide.appendChild(eyeball);
                legendDiv.appendChild(showHide);
                legendDiv.appendChild(textContainer);

                container.append(legendDiv)
            } else {
                console.error("Container 'legend-container-" + i + "' not found... could not append legend item");
            }
        }
    }

    // -------- Event Handlers ---------- //

    lineClicked(dataset, x, y, itemIndex) {
        const lineAnnotation = { // New Chart Marker
            type: 'point',
            pointStyle: 'line',
            xValue: x,
            yValue: y,
            rotation: 90,
            radius: 15,
            adjustScaleRange: false,
            borderColor: (this.state.annotationMode == 0) ? this.GREEN_COLOR : this.RED_COLOR,
            borderWidth: 2
        }
        const dataAnnotations = this.state.dataAnnotations[dataset];
        let newAnnotations;
        const chartAnnotations = {...this.state.chartAnnotations};

        const finalizeNewAnnotations = () => { // Go through and make sure start < end and swap if necessary
            if (!newAnnotations) return;
            if (Array.isArray(newAnnotations)) {
                newAnnotations.forEach((annotation, index) => {
                    if (annotation.start === undefined || annotation.end === undefined) { // Annotation Incomplete
                        return;
                    }
                    if (annotation.start > annotation.end) { // swap start and end points
                        [annotation.start, annotation.end] = [annotation.end, annotation.start];
                        [annotation.startIndex, annotation.endIndex] = [annotation.endIndex, annotation.startIndex];
                        [chartAnnotations[`start${dataset}_${index}`], chartAnnotations[`end${dataset}_${index}`]] = [chartAnnotations[`end${dataset}_${index}`], chartAnnotations[`start${dataset}_${index}`]]; // Swap chart annotations
                    }
                    if (chartAnnotations[`ref${dataset}`]) chartAnnotations[`ref${dataset}`].display = true;
                    if (chartAnnotations[`peak${dataset}`]) chartAnnotations[`peak${dataset}`].display = true;
                });
            } else {
                if (newAnnotations.start === undefined || newAnnotations.end === undefined) { // Annotation Incomplete
                    return;
                }
                if (newAnnotations.start > newAnnotations.end) { // swap start and end points
                    [newAnnotations.start, newAnnotations.end] = [newAnnotations.end, newAnnotations.start];
                    [newAnnotations.startIndex, newAnnotations.endIndex] = [newAnnotations.endIndex, newAnnotations.startIndex];
                    [chartAnnotations[`start${dataset}`], chartAnnotations[`end${dataset}`]] = [chartAnnotations[`end${dataset}`], chartAnnotations[`start${dataset}`]]; // Swap chart annotations
                }
                if (chartAnnotations[`ref${dataset}`]) chartAnnotations[`ref${dataset}`].display = true;
                if (chartAnnotations[`peak${dataset}`]) chartAnnotations[`peak${dataset}`].display = true;
            }
        }
        
        // Steps:
        // 1. Make sure annotation(s) are complete, otherwise finish annotation
        // 2. If annotation(s) are complete:
        //     a. Edit mode: find closest marker and move it to new position
        //     b. Add mode: create new annotation
        // 3. Make sure start < end, otherwise swap

        if (!dataAnnotations) { // No annotations made, create first mark
            newAnnotations = {
                start: x,
                startIndex: itemIndex,
                type: this.state.annotationMode
            }
            chartAnnotations[`start${dataset}`] = lineAnnotation;
            this.updateDataAnnotations(dataset, newAnnotations);
            this.setChartAnnotations(chartAnnotations);
            return;
        }

        // Check if annotation(s) are complete
        if (Array.isArray(dataAnnotations)) { // Multiple annotation state
            const annotation = dataAnnotations[dataAnnotations.length - 1];
            if (annotation.start === undefined || annotation.end === undefined) { // Annotation Incomplete, finish
                newAnnotations = [...dataAnnotations];
                newAnnotations[dataAnnotations.length - 1] = {
                    start: (annotation.start === undefined) ? x : annotation.start,
                    startIndex: (annotation.start === undefined) ? itemIndex : annotation.startIndex,
                    end: (annotation.end === undefined) ? x : annotation.end,
                    endIndex: (annotation.end === undefined) ? itemIndex : annotation.endIndex,
                    type: this.state.annotationMode,
                    confidenceLevel: (annotation.confidenceLevel === undefined) ? this.DEFAULT_CONFIDENCE : annotation.confidenceLevel,
                }
                if (annotation.start === undefined) {
                    chartAnnotations[`start${dataset}_${dataAnnotations.length - 1}`] = lineAnnotation;
                    if (annotation.end !== undefined) chartAnnotations[`end${dataset}_${dataAnnotations.length - 1}`].borderColor = lineAnnotation.borderColor;
                }
                if (annotation.end === undefined) {
                    chartAnnotations[`end${dataset}_${dataAnnotations.length - 1}`] = lineAnnotation;
                    if (annotation.start !== undefined) chartAnnotations[`start${dataset}_${dataAnnotations.length - 1}`].borderColor = lineAnnotation.borderColor;
                }
                finalizeNewAnnotations();
                this.updateDataAnnotations(dataset, newAnnotations);
                this.setChartAnnotations(chartAnnotations);
                return; // Nothing left to do
            }
        } else if (dataAnnotations.start === undefined || dataAnnotations.end === undefined){ // Single annotation state & Incomplete
            newAnnotations = {
                start: (dataAnnotations.start === undefined) ? x : dataAnnotations.start,
                startIndex: (dataAnnotations.start === undefined) ? itemIndex : dataAnnotations.startIndex,
                end: (dataAnnotations.end === undefined) ? x : dataAnnotations.end,
                endIndex: (dataAnnotations.end === undefined) ? itemIndex : dataAnnotations.endIndex,
                type: this.state.annotationMode,
                confidenceLevel: (dataAnnotations.confidenceLevel === undefined) ? this.DEFAULT_CONFIDENCE : dataAnnotations.confidenceLevel,
            };
            if (dataAnnotations.start === undefined) {
                chartAnnotations[`start${dataset}`] = lineAnnotation;
                if (dataAnnotations.end !== undefined) chartAnnotations[`end${dataset}`].borderColor = lineAnnotation.borderColor
            }
            if (dataAnnotations.end === undefined) {
                chartAnnotations[`end${dataset}`] = lineAnnotation;
                chartAnnotations[`start${dataset}`].borderColor = lineAnnotation.borderColor;
            }
            finalizeNewAnnotations();
            this.updateDataAnnotations(dataset, newAnnotations);
            this.setChartAnnotations(chartAnnotations);
            return;   
        }

        let invalidMultipleAnnotation = false;
        // Check to make sure user did not click within another annotation
        if (this.state.multipleAnnotationEnabled) { 
            if (Array.isArray(dataAnnotations)) {
                dataAnnotations.forEach((annotation) => {
                    if (annotation.start <= x && x <= annotation.end) {
                        invalidMultipleAnnotation = true;
                        return; // break loop, invalid annotation
                    }
                });
            } else {
                if (dataAnnotations.start <= x && x <= dataAnnotations.end) {
                    invalidMultipleAnnotation = true;
                }
            }
        }

        // Annotation(s) are complete, now we either add a new one or move an existing one
        if (this.state.multipleAnnotationEnabled && !invalidMultipleAnnotation) { // Add Mode & Valid
            if (!Array.isArray(dataAnnotations)) { // Still in single annotation state, convert to multiple annotation state
                newAnnotations = [dataAnnotations];
                chartAnnotations[`start${dataset}_0`] = chartAnnotations[`start${dataset}`];
                chartAnnotations[`end${dataset}_0`] = chartAnnotations[`end${dataset}`];
                delete chartAnnotations[`start${dataset}`];
                delete chartAnnotations[`end${dataset}`];
            } else {
                newAnnotations = [...dataAnnotations];
            }
            newAnnotations.push({
                start: x,
                startIndex: itemIndex,
                type: this.state.annotationMode
            });
            chartAnnotations[`start${dataset}_${newAnnotations.length - 1}`] = lineAnnotation;
            this.setMultipleAnnotationEnabled(false);
        } else { // Edit Mode
            if (Array.isArray(dataAnnotations)) { // Multiple annotation state
                const distances = {};
                dataAnnotations.forEach((annotation, index) => {
                    if (annotation.start !== undefined) distances[`start${dataset}_${index}`] = Math.abs(annotation.start - x);
                    if (annotation.end !== undefined) distances[`end${dataset}_${index}`] = Math.abs(annotation.end - x);
                });
                const closestMarker = Object.keys(distances).reduce((a, b) => distances[a] < distances[b] ? a : b);
                const closestMarkerIndex = closestMarker.split('_')[1];
                if (closestMarker.includes('start')) { // Move start marker
                    newAnnotations = [...dataAnnotations];
                    newAnnotations[closestMarkerIndex] = {
                        start: x,
                        startIndex: itemIndex,
                        end: dataAnnotations[closestMarkerIndex].end,
                        endIndex: dataAnnotations[closestMarkerIndex].endIndex,
                        type: this.state.annotationMode,
                        confidenceLevel: dataAnnotations[closestMarkerIndex].confidenceLevel
                    }
                    chartAnnotations[`start${dataset}_${closestMarkerIndex}`] = lineAnnotation;
                    chartAnnotations[`end${dataset}_${closestMarkerIndex}`].borderColor = lineAnnotation.borderColor;
                } else { // Move end marker
                    newAnnotations = [...dataAnnotations];
                    newAnnotations[closestMarkerIndex] = {
                        start: dataAnnotations[closestMarkerIndex].start,
                        startIndex: dataAnnotations[closestMarkerIndex].startIndex,
                        end: x,
                        endIndex: itemIndex,
                        type: this.state.annotationMode,
                        confidenceLevel: dataAnnotations[closestMarkerIndex].confidenceLevel
                    }
                    chartAnnotations[`end${dataset}_${closestMarkerIndex}`] = lineAnnotation;
                    chartAnnotations[`start${dataset}_${closestMarkerIndex}`].borderColor = lineAnnotation.borderColor;
                }
            } else { // Single annotation state
                if (Math.abs(dataAnnotations.start - x) < Math.abs(dataAnnotations.end - x)) { // Start marker is closer
                    newAnnotations = {
                        start: x,
                        startIndex: itemIndex,
                        end: dataAnnotations.end,
                        endIndex: dataAnnotations.endIndex,
                        type: this.state.annotationMode,
                        confidenceLevel: dataAnnotations.confidenceLevel
                    }
                    chartAnnotations[`start${dataset}`] = lineAnnotation;
                    chartAnnotations[`end${dataset}`].borderColor = lineAnnotation.borderColor;
                } else { // End marker is closer
                    newAnnotations = {
                        start: dataAnnotations.start,
                        startIndex: dataAnnotations.startIndex,
                        end: x,
                        endIndex: itemIndex,
                        type: this.state.annotationMode,
                        confidenceLevel: dataAnnotations.confidenceLevel
                    };
                    chartAnnotations[`end${dataset}`] = lineAnnotation;
                    chartAnnotations[`start${dataset}`].borderColor = lineAnnotation.borderColor;
                }
            }
        }
        finalizeNewAnnotations();
        this.updateDataAnnotations(dataset, newAnnotations);
        this.setChartAnnotations(chartAnnotations);
    }


    clearAnnotationsHandler() {
        this.setDataAnnotations(Array(this.state.waveformCount));
        const filteredAnnotations = Object.keys(this.state.chartAnnotations) // Filter out user annotations
            .filter((key) => (key.includes("ref") || key.includes('peak')))
            .reduce((cur, key) => {return Object.assign(cur, {[key]: this.state.chartAnnotations[key]})}, {})
        
        for (const annotation of Object.values(filteredAnnotations)) {
            annotation.display = false;
        }
        this.setChartAnnotations(filteredAnnotations);
    }

    changeAnnotationMode() {
        this.setAnnotationMode((this.state.annotationMode == 0) ? 1 : 0);
    }

    nextConfidenceLevel(dataset) {
        const dataAnnotations = this.state.dataAnnotations[dataset];
        if (Array.isArray(dataAnnotations)) {
            let nextLevel = dataAnnotations[0].confidenceLevel || 0;
            nextLevel = (nextLevel + 1 == 3) ? 0 : nextLevel + 1;
            const newAnnotations = dataAnnotations.map((annotation) => {
                return {...annotation, confidenceLevel: nextLevel}
            });
            this.updateDataAnnotations(dataset, newAnnotations);
        } else {
            let nextLevel = dataAnnotations.confidenceLevel || 0;
            nextLevel = (nextLevel + 1 == 3) ? 0 : nextLevel + 1;
            this.updateDataAnnotations(dataset, {...dataAnnotations, confidenceLevel: nextLevel});
        }
    }

    changeMarkerStatus(dataset) {
        if (this.annotationControlsEnabled(dataset)) {
            const dataAnnotations = this.state.dataAnnotations[dataset];
            if (Array.isArray(dataAnnotations)) {
                const newSetting = (dataAnnotations[0].markersIncorrect) ? false : true; // Use the first one to make sure no inconsistencies occur
                const newAnnotations = dataAnnotations.map((annotation) => {
                    return {...annotation, markersIncorrect: newSetting}
                });
                this.updateDataAnnotations(dataset, newAnnotations);
            } else {
                this.updateDataAnnotations(
                    dataset, 
                    {...dataAnnotations, markersIncorrect: (dataAnnotations.markersIncorrect == true) ? false : true}
                );
            }
        }
    }

    async nextWaveformSet() {
        console.log('Moving to next waveform set...');
        this.setLoading(true);
        const nextSet = await this.loadNextSet();
        this.updateView(nextSet.sweep, nextSet.interrogating, nextSet.set_index, nextSet.waveforms, nextSet.annotations);
        this.setLoading(false);

        await this.saveAnnotations(this.state.currentSet, this.state.dataAnnotations, this.state.currentSet + 1);

        // Lazy load the next set if needed
        if (this.state.currentSet < this.setCount - 1 && !this.hasCachedSet(this.state.currentSet + 1)) {
            console.log('Lazy loading next set');
            this.loadNextSet();
        }
    }

    async previousWaveformSet() {
        console.log('Moving to previous waveform set...');
        this.setLoading(true);

        const prevSet = await this.loadPreviousSet();
        this.updateView(prevSet.sweep, prevSet.interrogating, prevSet.set_index, prevSet.waveforms, prevSet.annotations);
        this.setLoading(false);

        await this.saveAnnotations(this.state.currentSet, this.state.dataAnnotations, this.state.currentSet - 1);

        // Lazy load the previous set
        if (this.state.currentSet > 0 && !this.hasCachedSet(this.state.currentSet - 1)) {
            console.log('Lazy loading previous set');
            this.loadPreviousSet();
        }
    }

    async completeRecord(flagRecord = false, notes = "") {
        const result = await this.saveAnnotations(this.state.currentSet, this.state.dataAnnotations);
        if (result) {
            this.setLoading(true);
            const err = false;
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/temgset/finish', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', ...await this.context.getAuthHeader() },
                body: JSON.stringify({notes: notes, flag_record: flagRecord})
            }).catch(error => {
                err = true; 
                console.error(error)
            });
            if (response.status !== 200 || err) {
                window.alert('Something went wrong..')
                console.error('Failed to complete temg record');
            }
            this.clearAnnotationsHandler();
            this.setChartData(null);
            this.clearCachedSets();
            this.init();
        }
    }

    finishDialogUpdateCallback(type, value) {
        switch (type) {
            case 'checkbox':
                this.setState({flagRecord: value});
            break;
            case 'textarea':
                this.setState({recordNotes: value});
            break;
        }
    }

    /*
    * Gets called on every render on each data point
    * used to specify which color each point it
    */
    getBorderColor(ctx) {
        const getColor = (x, start, end, type) => {
            if (x > start && x <= end) {
                switch(type) {
                    case 0:
                        return this.GREEN_COLOR;
                    case 1:
                        return this.RED_COLOR;
                }
            }
        }

        const annotations = this.state.dataAnnotations[ctx.datasetIndex];
        if (!annotations) {
            return;
        }
        if (Array.isArray(annotations)) { // Multiple annotations on line
            for (const annotation of annotations) {
                if (annotation.start && annotation.end) {
                    const color = getColor(ctx.p1.parsed.x, annotation.start, annotation.end, annotation.type);
                    if (color) {
                        return color;
                    }
                }
            }
        } else if (annotations.start && annotations.end) {
            return getColor(ctx.p1.parsed.x, annotations.start, annotations.end, annotations.type);
        }
    }

    // -------- Utility Functions ------ //
    async init() {
        this.setLoading(true);
        console.log("TEMG Classification View Initializing...")
        const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + '/api/temgset',
            {method: 'GET', headers: await this.context.getAuthHeader()}
        );
        if (response.status != 200) {
            if (response.status == 404) {
                window.alert('Could not fetch anything new... Try again later..');
                this.props.navigate(-1);
                return;
            }
            window.alert("Something went wrong.. Please try again later..")
            console.error(response);
            return;
        }
        const body = await response.json();
        this.setCount = body.total_sets;
        this.updateView(body.sweep, body.interrogating, body.set_index, body.waveforms, body.annotations);
        this.setCachedSet(body.set_index, body);
        this.setLoading(false);
        if (body.set_index < body.total_sets - 1) {
            this.loadSet(body.set_index + 1);
        }
        if (body.set_index > 0) {
            this.loadSet(body.set_index - 1);
        }
    }

    async loadSet(setIndex) {
        console.log('Loading set: ' + setIndex);

        const cachedSet = this.getCachedSet(setIndex);
        if (cachedSet) {
            console.log('Loaded set: ' + setIndex + ' from cache', cachedSet);
            return cachedSet;
        }

        const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + '/api/temgset/set/' + setIndex,
            {method: 'GET', headers: await this.context.getAuthHeader()}
        );

        if (response.status != 200) {
            console.error('failed to load set: ' + setIndex, response);
            return;
        }
        const body = await response.json();
        this.setCachedSet(setIndex, body);

        console.log('Loaded set: ' + setIndex + ' from server', body)
        return body;
    }

    async loadNextSet() {
        return this.loadSet(this.state.currentSet + 1);
    }

    async loadPreviousSet() {
        return this.loadSet(this.state.currentSet - 1);
    }


    prepareAnnotationsForSave(annotations) {
        let newAnnotations = [...annotations];
        for (let i = 0; i < newAnnotations.length; i++) {
            if (Array.isArray(newAnnotations[i])) {
                for (let j = 0; j < newAnnotations[i].length; j++) {
                    if (newAnnotations[i][j].start === undefined || newAnnotations[i][j].end === undefined) { // Annotation incomplete, discard
                        newAnnotations[i].splice(j, 1) // Remove annotation
                    }
                }
                if (newAnnotations[i].length === 0) {
                    newAnnotations[i] = null;
                    continue;
                }
                newAnnotations[i].sort((a, b) => a.start - b.start); // Sort annotations by start marking
            } else {
                if (newAnnotations[i] && (newAnnotations[i].start === undefined || newAnnotations[i].end === undefined)) { // Annotation incomplete, discard
                    newAnnotations[i] = null;
                }
            }
        }
        return newAnnotations;
    }

    async saveAnnotations(setIndex, annotations, setNextSetTo) {
        this.setSaving(true);
        const preparedAnnotations = this.prepareAnnotationsForSave(annotations);
        const currentCachedSet = this.getCachedSet(setIndex);
        if (currentCachedSet) {
            const item = {
                ...currentCachedSet,
                annotations: preparedAnnotations,
            }
            this.setCachedSet(setIndex, item);
        } else {
            console.error('Could not update cached item with new annotations')
        }

        const requestBody = {
            set_index: setIndex,
            annotations: preparedAnnotations,
        }

        if (setNextSetTo !== undefined) {
            requestBody.change_set_to = setNextSetTo;
        }

        console.log("Sending save request with body", requestBody);
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/temgset/save', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...await this.context.getAuthHeader() },
            body: JSON.stringify(requestBody)
        }).catch(error => {
            window.alert('Something went wrong')
            console.error('Failed to save annotations', error);
        })
        if (response.status != 200) {
            console.error('Failed to save annotations', response);
            window.alert('Something went wrong');
            this.setSaving(false);
            return false;
        }
        this.setSaving(false);
        return true;
    }

    getDataAnnotations(index) {
        return this.state.dataAnnotations[index];
    }

    annotationControlsEnabled(index) {
        const currentAnnotations = this.getDataAnnotations(index);
        if (Array.isArray(currentAnnotations) && currentAnnotations.some(annotation => annotation.start && annotation.end)) {
            return true;
        }
        return (currentAnnotations && currentAnnotations.start && currentAnnotations.end) ? true : false;
    }

    getConfidenceBtnClasses(index) {
        if (this.annotationControlsEnabled(index)) {
            let currentAnnotations = this.getDataAnnotations(index);
            if (Array.isArray(currentAnnotations)) {
                currentAnnotations = currentAnnotations[0]; // Confidence will be the same for all
            }
            if (currentAnnotations.confidenceLevel == undefined) {
                console.error("Confidence Level not set but annotation made.... classes", index);
            }
            switch(currentAnnotations.confidenceLevel || 0) {
                case 0:
                    return this.CONFIDENCE_0.classes;
                case 1:
                    return this.CONFIDENCE_1.classes;
                case 2:
                    return this.CONFIDENCE_2.classes;
            }
        }
        return ["cursor-not-allowed", "bg-gray-600"];
    }

    getConfidenceBtnText(index) {
        if (this.annotationControlsEnabled(index)) {
            let currentAnnotations = this.getDataAnnotations(index);
            if (Array.isArray(currentAnnotations)) {
                currentAnnotations = currentAnnotations[0]; // Confidence will be the same for all
            }
            if (currentAnnotations.confidenceLevel == undefined) {
                console.error("Confidence Level not set but annotation made.... text", index, currentAnnotations);
            }
            switch(currentAnnotations.confidenceLevel || 0) {
                case 0:
                    return this.CONFIDENCE_0.text;
                case 1:
                    return this.CONFIDENCE_1.text;
                case 2:
                    return this.CONFIDENCE_2.text;
            }
        } else {
            return "N/A";
        }
    }

    getMarkerClasses(index) {
        if (this.annotationControlsEnabled(index)) {
            let currentAnnotations = this.getDataAnnotations(index);
            if (Array.isArray(currentAnnotations)) {
                currentAnnotations = currentAnnotations[0]; // Markers will be the same for all
            }
            if (currentAnnotations.markersIncorrect) {
                return ["fa-flip-vertical", "hover:text-gray-200", "text-gray-50"];
            }
            return ["hover:text-gray-200", "text-gray-50"];
        }
        return ["cursor-not-allowed", "text-gray-600"];
    }

    handleHotKey(keyName, e, handle) {
        switch(keyName) {
            case "left":
                if (this.state.currentSet > 0 && !this.state.loading) {
                    return this.previousWaveformSet();
                }
            break;
            case "right":
                if ((this.setCount - this.state.currentSet > 1) && !this.state.loading) {
                    return this.nextWaveformSet();
                }
            break;
            case "t":
                this.setState({disableTooltip: !this.state.disableTooltip})
            break;
            case "n": 
                this.changeAnnotationMode();
            break;
            case "m":
                this.setMultipleAnnotationEnabled(!this.state.multipleAnnotationEnabled);
            break;
            case "delete":
                this.clearAnnotationsHandler();
            break;
        }
    }

    // ---- React Lifecycle Functions ---- //

    render() {
        let progress = (this.setCount !== 0 || !this.state.loading) ? (this.state.currentSet + 1) / this.setCount * 100 : 0;
        return (
            <Hotkeys
                keyName="left,right,t,m,n,delete"
                onKeyDown={this.handleHotKey.bind(this)}
            >
              <div className="flex flex-wrap">
                <div className="relative sm:w-screen flex flex-wrap shadow-lg rounded bg-atec-dark-grey mb-6 w-full text-gray-100 justify-center xl:justify-start">
                    <div className="relative w-8/12 mt-1 px-4 flex grow-0 justify-evenly">
                        <div className="flex-initial font-semibold text-lg">Sweep Index: {this.state.sweepTitle}</div>
                        <div className="flex-initial font-semibold text-lg">Stimulus Current: {this.state.interrogatingTitle}</div>
                    </div>
                    <div className="w-full lg:w-2/3 xl:w-4/12 max-w-xl">
                        <div className="relative mx-4">
                            <div className="flex justify-between mb-1 font-medium items-end">
                                <span>Case Progress: </span>
                                <span>{(this.setCount === 0 && this.state.loading) ? "Loading" : `${Math.floor(progress)}%`}</span>
                            </div>
                            <div class="w-full bg-gray-300 rounded-full h-2.5">
                                <div className={(progress === 100 ? "bg-green-500" : "bg-sky-600") + " h-2.5 rounded-full"} style={{width: progress + "%"}}></div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-8/12">
                        {this.state.loading && 
                            <div role="status" className="absolute top-[8px] left-[8px]">
                                <svg className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-atec-neon-green-100" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>  
                        }
                        <div className="relative h-[70vh] p-3">
                            {this.state.chartData && 
                                <ClassifyChart 
                                    data={this.state.chartData} 
                                    annotations={this.state.chartAnnotations} 
                                    clickListener={this.lineClicked} 
                                    yUnit="uV"
                                    yTitle="Voltage"
                                    xUnit="ms"
                                    xTitle="Time"
                                    lineColor={colors.cyan[400]}
                                    hoverColor={colors.cyan[200]}
                                    customLegend={this.legendPlugin}
                                    disableTooltip={this.state.disableTooltip}
                                />
                            }
                        </div>
                    </div>
                    <div className="w-full lg:w-2/3 xl:w-4/12 max-w-xl">
                        <div className="mx-2 mb-5 xl:mx-0 xl:mr-2 2xl:mx-0 2xl:mr-2">
                            <h2 className="w-full text-center leading-border-text -mb-3 pr-2 pl-2">
                                <span className="bg-atec-dark-grey px-1 text-md font-medium">Annotations</span>
                            </h2>
                            <div className="flex flex-col px-5 pb-1 pt-4 border-b border-t border-r border-l border-atec-light-grey rounded-md lg">
                                <div className="flex flex-wrap justify-between items-center">
                                    <button
                                        className=
                                        {
                                            ((this.state.annotationMode == 0) 
                                            ? "bg-green-500 hover:bg-green-600"
                                            : "bg-red-500 hover:bg-red-600")
                                            + " w-[135px] text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150"
                                        }
                                        type="button"
                                        onClick={this.changeAnnotationMode}
                                    >
                                        Mode:
                                        {(this.state.annotationMode == 0) 
                                        ? " Activity" 
                                        : " Artifact"}
                                    </button>
                                    <div className="flex flex-grow justify-start">
                                        <Tooltip
                                            content={(this.state.multipleAnnotationEnabled ? "Disable Multiple Annotations" : "Enable Multiple Annotations")}
                                            trigger="hover"
                                        >
                                            <i
                                                className={
                                                    (this.state.multipleAnnotationEnabled ? "fa-solid text-blue-400" : "fa-regular hover:text-blue-400") +
                                                    " fa-square-plus fa-xl px-2 mr-2 cursor-pointer shadow hover:shadow-md m-1 ease-linear transition-all duration-150 rounded-lg"
                                                }
                                                onClick={() => this.setMultipleAnnotationEnabled(!this.state.multipleAnnotationEnabled)}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Tooltip
                                        content={"Clear All"}
                                        trigger="hover"
                                    >
                                        <i 
                                            className="fas fa-trash fa-lg hover:text-red-500 px-2 mr-2 cursor-pointer shadow hover:shadow-md m-1 ease-linear transition-all duration-150"
                                            onClick={this.clearAnnotationsHandler}
                                        />
                                    </Tooltip>
                                    
                                </div>
                                <div className="flex flex-nowrap justify-between mt-5">
                                    <p className="w-[80px] mx-1">Confidence</p>
                                    <p>Markers</p>
                                </div>
                                <div id="legend-container" className="flex flex-col mb-5">
                                    {(() => {
                                        let containers = [];
                                        for (let i = 1; i <= this.state.dataAnnotations.length; i++) {
                                            containers.push(
                                                <div key={i} className="flex flex-nowrap items-center justify-between">
                                                    <button
                                                        className=
                                                        {
                                                            this.getConfidenceBtnClasses(i-1).join(" ")
                                                                + " w-[80px] grow-0 shrink-0 text-white font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150"
                                                        }
                                                        type="button"
                                                        onClick={this.nextConfidenceLevel.bind(this, i-1)}
                                                        disabled={!this.annotationControlsEnabled(i-1)}
                                                    >
                                                        {this.getConfidenceBtnText(i-1)}
                                                    </button>

                                                    <div id={"legend-container-" + i} className="basis-3/5"></div>
                                                    <i 
                                                        className={
                                                            this.getMarkerClasses(i-1).join(" ")
                                                            + " fas fa-thumbs-up px-2 cursor-pointer shadow hover:shadow-md m-1 ease-linear transition-all duration-150 float-right"
                                                        }
                                                        onClick={this.changeMarkerStatus.bind(this, i-1)}
                                                    />
                                                </div>
                                            );
                                        }
                                        return containers;
                                    })()}
                                </div>
                                <div className={"flex flex-nowrap justify-center" + ((this.state.isSaving) ? "" : " invisible")}>
                                    <p className="text-atec-neon-green-100">Saving..</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"relative w-full lg:w-1/3 flex-col xl:w-8/12 xl:justify-evenly xl:flex-row xl:max-w-full mb-5 px-4 max-w-xl flex"}>
                        <button
                            className={
                                ((this.state.currentSet < 1) ? "bg-gray-700 cursor-not-allowed" : "bg-sky-700 active:bg-sky-600")
                                + " text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150 xl:w-52"
                            }
                            type="button"
                            onClick={this.previousWaveformSet.bind(this)}
                            disabled={this.state.currentSet < 1 || this.state.loading}
                        >
                            Previous Waveform Set
                        </button>
                        <button
                            className={
                                ((this.state.loading) 
                                    ? "bg-gray-700 cursor-not-allowed" 
                                    : ((this.setCount - this.state.currentSet > 1) ? "bg-sky-700 active:bg-sky-600" : "bg-green-500 active:bg-green-600")
                                ) + " text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150 xl:w-52"
                            }
                            type="button"
                            onClick={(this.setCount - this.state.currentSet > 1) ? this.nextWaveformSet.bind(this) : this.showFinishDialog.bind(this, true)}
                            disabled={this.state.loading}
                        >
                            {(this.setCount - this.state.currentSet > 1) ? "Next Waveform Set": "Finish Record"}
                        </button>
                    </div>      
                </div>
                <FinishRecordPopup
                    show = {this.state.showFinishDialog}
                    confirmText = "Finish Record"
                    confirmCallback = {(flag, notes) => {this.showFinishDialog(false); this.completeRecord(flag, notes)}}
                    cancelCallback = {() => {this.showFinishDialog(false);}}
                    title = "Additional Details"
                />
              </div>
            </Hotkeys>
          );
    }
}

export default TEMGClassificationView;