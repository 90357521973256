import React from "react";
import { useState } from "react";
import { useAuth } from "contexts/AuthContext";

// components
import Alert from "components/Alerts/Alert.js";
import CardWithButton from "components/Cards/CardWithButton";

export default function SSEPTools() {
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    backgroundColor: "",
    textColor: "",
    text: "",
    icon: ""
  })
  const { getAuthHeader } = useAuth();
  const [userIdToDelete, setUserIdToDelete] = useState("");

  const [ssepUserEmail, setSsepUserEmail] = useState("");
  const [ssepRecordID, setSsepRecordId] = useState("");

  const [ssepRecordIDToReset, setSSEPRecordIdToReset] = useState("");

  const [deleteSsepRecordID, setDeleteSsepRecordId] = useState("");


  const deleteMyAnnotations = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/delete-my-ssep-annotations', 
    {
      method: "POST",
      headers: await getAuthHeader(),
    });

    
    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Success",
        icon: "fas fa-exclamation-triangle"
      });
    }
    setShowAlert(true);
  }

  const deleteOthersAnnotations = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/delete-a-users-ssep-annotations', 
    {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
      body: JSON.stringify({user_id: userIdToDelete})
    });

    
    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Success",
        icon: "fas fa-exclamation-triangle"
      });
    }
    setShowAlert(true);
  }

  const assignUserToSSEPRecord = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/assign-user-to-ssep-record', 
    {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
      body: JSON.stringify({user_email: ssepUserEmail, ssep_record_id: ssepRecordID})
    });

    
    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Success",
        icon: "fas fa-exclamation-triangle"
      });
    }
    setShowAlert(true);
  }

  const resetSSEPRecord = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/reset-ssep-record', 
    {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
      body: JSON.stringify({ssep_record_id: ssepRecordIDToReset})
    });

    
    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Success",
        icon: "fas fa-exclamation-triangle"
      });
    }
    setShowAlert(true);
  }

  const deleteSSEPRecord = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/developer/delete-ssep-record', 
    {
      method: "POST",
      headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
      body: JSON.stringify({record_id: deleteSsepRecordID})
    });

    
    if (response.status != 200) {
      setAlert({
        backgroundColor: "bg-red-500",
        textColor: "text-white",
        text: response.statusText,
        icon: "fas fa-exclamation-triangle"
      });
    } else {
      setAlert({
        backgroundColor: "bg-atec-neon-green-400",
        textColor: "text-white",
        text: "Success",
        icon: "fas fa-exclamation-triangle"
      });
    }
    setShowAlert(true);
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <Alert showAlert={showAlert} closeCallback={() => setShowAlert(false)} alertOptions={alert}/>
          <CardWithButton title="SSEP Developer Tools" content={<div className="flex flex-col">
              <div className="flex flex-wrap py-4 justify-between">
                <button
                  className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={deleteMyAnnotations}
                >
                  Delete My Annotations
                </button>
              </div>
              <hr/>
              <div>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Reset SSEP Record
                </h6>
                <div className="flex flex-col mb-5">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Record ID
                      </label>
                      <input
                        type="text"
                        value={ssepRecordIDToReset} 
                        onChange={(e) => {setSSEPRecordIdToReset(e.target.value)}}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={resetSSEPRecord}
                    >
                      Reset Record
                    </button>
                  </div>
                </div>
              </div>
              <hr/>
              <div>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Delete User's Annotations
                </h6>
                <div className="flex flex-col mb-5">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        User ID
                      </label>
                      <input
                        type="text"
                        value={userIdToDelete} 
                        onChange={(e) => {setUserIdToDelete(e.target.value)}}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={deleteOthersAnnotations}
                    >
                      Delete Annotations
                    </button>
                  </div>
                </div>
              </div>
              <hr/>
              <div className="py-4">
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Assign User to SSEP Record
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        User Email
                      </label>
                      <input
                        type="text"
                        value={ssepUserEmail} 
                        onChange={(e) => {setSsepUserEmail(e.target.value)}}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Record ID
                      </label>
                      <input
                        type="text"
                        value={ssepRecordID} 
                        onChange={(e) => {setSsepRecordId(e.target.value)}}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="self-center">
                    <button
                      className="bg-sky-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={assignUserToSSEPRecord}>
                      Assign User
                    </button>
                  </div>
                </div>
              </div>
              <hr/>
              <div>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Delete SSEP Record
                </h6>
                <div className="flex flex-col mb-5">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        SSEP Record ID
                      </label>
                      <input
                        type="text"
                        value={deleteSsepRecordID} 
                        onChange={(e) => {setDeleteSsepRecordId(e.target.value)}}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-2 focus:ring-atec-neon-green-200 w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={deleteSSEPRecord}
                    >
                      Delete SSEP Record <i class="fa-solid fa-skull-crossbones"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }/>
        </div>
      </div>
    </>
  );
}
