import React, { useState, useMemo, useReducer, useEffect } from "react";
import { useUserService } from "contexts/UserContext";
import ConfirmPopup from "../../components/Popups/ConfirmPopup";
import Alert from "../../components/Alerts/Alert";
import CardPaginatedTable from "../../components/Cards/CardPaginatedTable";
import { validateEmail } from "../../util/Utility";
import { AdminPermissions, AdjudicationPermissions, ClassificationPermissions } from "../../util/Permissions";


const prepareUserRow = (user) => {
  return [
    user.name,
    user.email,
    (new Date(Date.parse(user.created_at))).toLocaleDateString(),
    (new Date(Date.parse(user.last_login))).toLocaleString(),
    (user.pending_approval ? ['Pending Approval', 'text-red-500'] : ['Approved', 'text-emerald-500']),
    [{text: "Edit", target: "/admin/users/" + user.id}]
  ]
}

const AvailablePermissions = [
  ...Object.values(AdminPermissions),
  ...Object.values(AdjudicationPermissions),
  ...Object.values(ClassificationPermissions),
]

const mapPermissions = () => {
  let permissions = {}
  AvailablePermissions.map(p => permissions[p] = false);
  return permissions
}

export default function Users() {
  const { getAllUsers, preapproveUser } = useUserService();
  const [view, setView] = useState('approved');
  const [inviteUserEmail, setInviteUserEmail] = useState("");
  const [inviteUserLoading, setInviteUserLoading] = useState(false);
  const [inviteUserEmailError, setInviteUserEmailError] = useState(null);
  const [alertOptions, setAlertOptions] = useState({
    backgroundColor: "",
    textColor: "",
    text: "",
    icon: ""
  })
  const [alertVisible, showAlert] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [inviteUserPermissions, setInviteUserPermissions] = useState({...mapPermissions()})

  const config = {
    title: "Users",
    columns: [
      {
        header: "Name",
        type: "text"
      },
      {
        header: "Email",
        type: "text"
      },
      {
        header: "User Since",
        type: "date"
      },
      {
        header: "Last Login",
        type: "date"
      },
      {
        header: "Approval Status",
        type: "status"
      },
      {
        header: "Action",
        type: "menu"
      }],
    color: "light",
  }

  const approvedFetchFunction = async (page) => {
    const { users, hasMore} = await getAllUsers(true, page);
    return { 
      data: users.map(prepareUserRow), 
      hasMore: hasMore
    };
  }

  const pendingFetchFunction = async (page) => {
    const { users, hasMore } = await getAllUsers(false, page);
    return { 
      data: users.map(prepareUserRow), 
      hasMore: hasMore
    };
  }

  const switchView = () => {
    setView(view === 'approved' ? 'pending-approval' : 'approved');
  }
  
  const closeAndResetInviteDialog = () => {
    setShowAddUser(false);
    setInviteUserEmailError(null);
    setInviteUserEmail("");
    setInviteUserPermissions(mapPermissions());
  }

  const validateInviteUserDialog = () => {
    if (inviteUserLoading) {
      console.log('Invite user still loading..');
      return false;
    }
    if (!validateEmail(inviteUserEmail)) {
      setInviteUserEmailError('Invalid Email');
      return false;
    }
    return true;
  }

  const confirmAddUserCallback = async () => {
    if (!validateInviteUserDialog()) return; 
    setInviteUserLoading(true);
    const permissions = Object.entries(inviteUserPermissions).filter(p => p[1]).map(p => p[0]);
    const { error } = await preapproveUser(inviteUserEmail, permissions);
    setInviteUserLoading(false);
    if (error) {
      setInviteUserEmailError(error);
      console.error(error);
      return;
    }
    closeAndResetInviteDialog();
    setAlertOptions({
      backgroundColor: "bg-atec-neon-green-400",
      textColor: "text-white",
      text: `Successfully added ${inviteUserEmail} to preapproval list, once they try to login they will automatically be approved`,
      icon: "fas fa-check"
    })
    showAlert(true);  
  }

  const buildPermissionCard = (key, enabled, callback) => {
    const id = 'permission-checkbox-' + key;
    return (
      <div className="flex items-center pl-4 border border-gray-200 rounded shadow dark:border-gray-700 cursor-pointer">
          <input 
            checked={enabled} 
            id={id} 
            onChange={e => callback(key, e.target.checked)} 
            type="checkbox"
            name="bordered-checkbox" 
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
          />
          <label for={id} className="w-full py-4 mx-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer select-none">{key}</label>
      </div>
    )
  }

  const handlePermissionChange = (key, status) => {
    setInviteUserPermissions({...inviteUserPermissions, [key]: status})
  }

  


  return (
    <>
      <ConfirmPopup
        show={showAddUser}
        cancelCallback={() => setShowAddUser(false)}
        confirmCallback={() => {confirmAddUserCallback()}}
        confirmText="Confirm Approval"
        title="Preapprove User"
        content={
          <div>
            <div className="w-full px-4">
              <div className="relative w-full xl:w-8/12 mb-3">
                <div className="relative">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Email</label>
                  <input
                      type="email"
                      value={inviteUserEmail}
                      onChange={e => setInviteUserEmail(e.target.value)}
                      placeholder='john@atecspine.com'
                      className={(inviteUserEmailError 
                                    ? "focus:ring-red-600 text-red-700" : " text-slate-600"
                                  ) + " placeholder-slate-300 border-0 px-3 py-3 bg-white rounded text-sm shadow w-full"}
                      disabled={inviteUserLoading}
                  />
                  <i className={"fas fa-spinner animate-spin absolute top-[55%] -ml-7" + (!inviteUserLoading ? " invisible" : "")}/>
                </div>
                {inviteUserEmailError && <span className="text-sm text-red-600">{inviteUserEmailError}</span>}
              </div>
              <h6 className="text-blueGray-600 text-xs font-bold mt-6 mb-2 uppercase">User Permissions</h6>
              <div className="flex flex-wrap gap-4 p-4 border border-solid border-slate-300 rounded-b shadow-sm">
                {AvailablePermissions.map(p => buildPermissionCard(p, inviteUserPermissions[p], handlePermissionChange))}
              </div>
            </div>
          </div>
        }
      />
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <div 
            className="float-right border-blueGray-400 border-2 py-2 px-4 rounded mb-2 shadow-md bg-white hover:bg-blueGray-50 cursor-pointer transition-all duration-150 ease-in-out"
            onClick={() => setShowAddUser(true)}
          >
            <i className="fas fa-plus text-blueGray-500"></i>
          </div>
          <Alert
            showAlert={alertVisible}
            closeCallback={() => showAlert(false)}
            alertOptions={alertOptions}
          />
          <CardPaginatedTable 
            title={config.title}
            color={config.color}
            columns={config.columns}
            fetchFunction={(view === 'approved' ? approvedFetchFunction : pendingFetchFunction)}
            queryKey={"paginated-key-" + view}
            extraHeaderContent={
                <div className="flex flex-grow justify-center">
                    <button
                        className={(view === 'approved' ? 'bg-orange-500 active:bg-orange-400' : 'bg-atec-neon-green-400 active:bg-atec-neon-green-200')
                            + " text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"}
                        type="button"
                        onClick={switchView}
                        >
                        {view === 'approved' ? 'Switch to Pending Approval' : 'Switch to Approved'}
                    </button>
                </div>
                
            }/>
        </div>
      </div>
    </>
  );
}
