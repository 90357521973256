import React, { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import Alert from "components/Alerts/Alert.js";


// components

import CardTable from "components/Cards/CardTable.js";

export default function PendingIngestion() {
  const [ingestionRecords, setRecords] = useState(null);
  const { getAuthHeader } = useAuth();
  useEffect(() => {
    (async () => {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/get-ingestion-items', {method: "GET", headers: await getAuthHeader()});
      if (response.status != 200 && response.status != 404) {
        console.error("Something went wrong");
        return;
      }
      try {
        const body = await response.json();
        console.log(body);
        const formatted = body.items.map((record) => {
          let created;
          if (record.created) {
            created = new Date(record.created).toLocaleString();
          }
          return [record.case_id || "N/A", record.ingestion_id || "N/A", record.type || "N/A", created || "N/A", record.total_items || "N/A"]
        })
        setRecords(formatted);
      } catch (err) {
        window.alert('Failed to parse response');
        console.error("Error", err);
      }
    })();
  }, []);

  const config = {
    title: "Items queued for Ingestion (Runs every night at 10:00pm)",
    columns: [
      {
        header: "CASE ID",
        type: "text"
      },
      {
        header: "INGESTION ID",
        type: "text"
      },
      {
        header: "INGESTION TYPE",
        type: "text"
      },
      {
        header: "CREATED",
        type: "date"
      },
      {
        header: "ITEMS ASSOCIATED",
        type: "text"
      }],
    color: "light",
  }

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({
    backgroundColor: "bg-atec-neon-green-400",
    textColor: "text-white",
    text: "Ingestion Started, Ingestion will run for 30 minutes or until all items have been ingested",
    icon: "fas fa-file-export"
  });

  const forceStartIngestion = async () => {
    const result = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/force-start-ingestion', {method: "GET", headers: await getAuthHeader()});
    if (result.status === 200) {
      setShowAlert(true);
    }
    console.log(result)
  }

  return (
    <>
    
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Alert showAlert={showAlert} closeCallback={() => setShowAlert(false)} alertOptions={alert}/>
          <CardTable 
            title={config.title}
            color={config.color}
            columns={config.columns}
            data={ingestionRecords}
            extraHeaderContent = {
              <div className="flex ">
                <button
                  className="bg-red-600 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={forceStartIngestion}
                >
                  Force Ingestion Start
                </button>
              </div>
            }  
          />
        </div>
      </div>
    </>
  );
}
