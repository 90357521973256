import { useState, useEffect } from "react";

export default function FinishRecordPopup({
  show = true, 
  confirmCallback = null, 
  cancelCallback = null, 
  confirmText = "Confirm", 
  cancelText = "Cancel", 
  title = "", 
  checkboxName = "Flag Record For Further Review",
  textBoxName = "Notes"

}) {
    const [showModal, setShowModal] = useState(false)
    const [checkBox, setCheckbox] = useState(false);
    const [textArea, setTextArea] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        setShowModal(show)
    }, [show])

    const cancel = () => {
        setShowModal(false);
        if (cancelCallback) {
            cancelCallback();
        }
    }

    const confirm = () => {
        if (checkBox && textArea === "") {
            setError("Explanation is required when flagging record for further review.");
            return;
        }
        setShowModal(false);
        if (confirmCallback) {
            confirmCallback(checkBox, textArea);
            setTextArea("");
            setCheckbox(false);
            setError(null);
        }
    }

    const checkBoxHandler = (event) => {
      if (error && event.target.checked === false) {
        setError(null);
      }
      setCheckbox(event.target.checked);
    }
    const textAreaHandler = (event) => {
      if (error && event.target.value !== "") {
        setError(null);
      }
      setTextArea(event.target.value);
    }

    return (
      <>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl min-w-[30%]">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold text-blueGray-700">
                      {title}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => cancel()}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="relative w-full mb-3 lg:w-3xl">
                      <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                          {checkboxName}
                          <input type="checkbox" className="ml-2" 
                              checked={checkBox} 
                              onChange={checkBoxHandler}/>
                      </label>

                      <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                          {textBoxName}
                          <textarea rows="4" 
                              className={(error ? "border-red-500" : "border-gray-300") + " block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
                              value={textArea} 
                              onChange={textAreaHandler}
                          />
                      </label>
                      {error && <p className="text-red-500 text-xs italic">{error}</p>}
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => cancel()}
                    >
                      {cancelText}
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => confirm()}
                    >
                      {confirmText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }