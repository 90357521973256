import Navbar from "components/Navbars/AuthNavbar";
import React, { useEffect, useState } from "react";
import {Route, Routes} from "react-router-dom";
import AuthError from "views/auth/AuthError";
import LoginCallback from "views/auth/AuthCallback";

import Login from "views/auth/Login.js";
import Logout from "../views/auth/Logout";
import { useAuth } from "../contexts/AuthContext";
import SystemInfo from "../views/auth/SystemInfo";

export default function Auth() {
  const { isLoggedIn, getUserName } = useAuth();
  const [authenticated, setAuthenticated] = useState(false);
  const [userName, setUserName] = useState(null);
  useEffect(() => {
    isLoggedIn().then(status => setAuthenticated(status));
    getUserName().then(name => setUserName(name));
  }, [isLoggedIn]);
  return (
    <>
      <Navbar transparent/>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-atec-dark-grey bg-no-repeat bg-full"
          >
            <div className="absolute bottom-10 text-gray-400">Login Status: {authenticated ? "Logged In" : "Logged Out"}</div>
            {authenticated && <div className="absolute bottom-5 text-gray-400">User: {userName}</div>}
          </div>
          
          <Routes>
            <Route path="login" element={<Login/>} />
            <Route path="logout" element={<Logout/>} />
            <Route path='callback' element={<LoginCallback/>} />
            <Route path='error' element={<AuthError/>} />
            <Route path='systeminfo' element={<SystemInfo/>} />
          </Routes>
          {/* <FooterSmall absolute /> */}
        </section>
      </main>
    </>
  );
}
