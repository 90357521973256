export default function Loading() {
    return (
        <div className="bg-atec-dark-grey fixed left-0 right-0 top-0 bottom-0 z-50">
            <div className="flex h-screen items-center justify-center w-full">
                <img
                    alt="..."
                    className="border-none animate-pulse max-w-[200px]"
                    src={(process.env.NODE_ENV === 'development') ? require("assets/img/atec-logo-dev.png") : require("assets/img/atec-logo.png")}
                />
            </div>
        </div>
        
    )
}