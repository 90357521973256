import { createContext, useContext, useMemo, useReducer, useState } from "react";
import { useAuth } from "./AuthContext";
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const { getAuthHeader, getCurrentUser } = useAuth();

    const getAllUsers = async (approved=true, page=0) => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/users/getall?page=${page}&approved=${approved}`, {method: "GET", headers: await getAuthHeader()});
        const result = await response.json();
        return { users: result.users, hasMore: result.hasMore, total: result.total };
    }

    const getUser = async (id) => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + id, {method: "GET", headers: await getAuthHeader()});
        if (response.status != 200) {
            throw new Error(response.status)
        }
        const user = await response.json();
        user.last_login = new Date(user.last_login).toLocaleString();
        user.last_update = new Date(user.last_update).toLocaleString();
        if (user.multifactor.last_modified) {
            user.multifactor.last_modified = new Date(user.multifactor.last_modified).toLocaleString();
        }
        return user;
    }

    const getMyUser = async () => {
        return await getCurrentUser();
    }

    const assignUserPermissions = async (id, permissions) => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/users/permissions?id=${id}`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
                body: JSON.stringify({
                    permissions: permissions
                }),
            });
            if (response.ok) {
                return true;
            }
            console.error('Failed to assign user permissions ' + response);
        } catch (err) {
            console.error('Failed to remove user permissions ' + err);
        }
        return false;
    }

    const removeUserPermissions = async (id, permissions) => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/users/permissions?id=${id}`, {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
                body: JSON.stringify({
                    permissions: permissions
                }),
            });
            if (response.ok) {
                return true;
            }
            console.error('Failed to remove user permissions ' + response);
        } catch (err) {
            console.error('Failed to remove user permissions ' + err);
        }
        return false;
    }

    const changeApprovalStatus = async (id, status) => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/users/approval-status?id=${id}`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
                body: JSON.stringify({
                    status: status
                }),
            });
            if (response.ok) {
                return true;
            }
            console.error('Failed to modify approval status ' + response);
        } catch (err) {
            console.error('Failed to modify approval status ' + err);
        }
        return false;
    }

    const removeGuardianEnrollment = async (id) => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/users/remove-guardian-enrollment?id=${id}`, {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json', ...await getAuthHeader() }
            });
            if (response.ok) {
                return true;
            }
            console.error('Failed to remove MFA Enrollments ' + response);
        } catch (err) {
            console.error('Failed to remove MFA Enrollments ' + err);
        }
        return false;
    }

    const preapproveUser = async (email, permissions) => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/admin/preapprove-user`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', ...await getAuthHeader() },
                body: JSON.stringify({
                    user_email: email,
                    permissions: permissions
                }),
            });
            if (response.ok) {
                return {error: null};
            }
            console.error('Failed to preapprove user', response);
            const {error} = await response.json();
            return {error: error || 'Something went wrong'};
        } catch (err) {
            console.error('Failed parse response for preapprove-user api ' + err);
            return {error: 'Something went wrong'};
        }
    }

    const value = useMemo(
        () => ({
            getAllUsers,
            getUser,
            getMyUser,
            assignUserPermissions,
            removeUserPermissions,
            changeApprovalStatus,
            removeGuardianEnrollment,
            preapproveUser
        }),
        []
    );

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserService = () => {
  return useContext(UserContext);
};