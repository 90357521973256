import React, { useState } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import UserSidebar from "components/Sidebar/UserSidebar";

import MyAccount from "views/user/MyAccount";

import TEMGAdjudicationView from 'views/user/TEMGAdjudicationView';
import SSEPClassificationView from "views/user/SSEPClassificationView";
import TEMGClassificationView from "views/user/TEMGClassificationView";
import { SecureRoute } from "components/SecureRoute";
import { AdjudicationPermissions, ClassificationPermissions } from "util/Permissions";
import { WithParams } from "components/WithParams";
import TEMGAdjudicationListView from "views/user/TEMGAdjudicationListView";
import SSEPAdjudicationListView from "views/user/SSEPAdjudicationListView";

export default function User() {
  const navigate = useNavigate();

  return (
    <>
      <UserSidebar />
      <div className="relative md:ml-64">
        <div className="fixed top-0 bottom-0 right-0 left-0 -z-50 bg-white"></div>
        <AdminNavbar />
        <div className="relative bg-atec-light-grey md:pt-32 pb-32 pt-12 hidden lg:block"></div>
        <div className="relative py-4 px-5 lg:px-10 mx-auto w-full lg:-mt-40">
          <SecureRoute>
            <Routes>
              <Route path="myaccount" element={<MyAccount/>} />
              <Route path="adjudicate-temg" element={<SecureRoute requiredRole={AdjudicationPermissions.TEMG}><TEMGAdjudicationListView/></SecureRoute>} />
              <Route path="adjudicate-temg/:id" element={<SecureRoute requiredRole={AdjudicationPermissions.TEMG}><WithParams><TEMGAdjudicationView navigate={navigate}/></WithParams></SecureRoute>} />
              <Route path="adjudicate-ssep" element={<SecureRoute requiredRole={AdjudicationPermissions.SSEP}><SSEPAdjudicationListView/></SecureRoute>} />
              <Route path="adjudicate-ssep/:id" element={<SecureRoute requiredRole={AdjudicationPermissions.SSEP}><WithParams><SSEPClassificationView navigate={navigate} adjudicateMode={true}/></WithParams></SecureRoute>} />
              <Route path="classify-ssep" element={<SecureRoute requiredRole={ClassificationPermissions.SSEP}><SSEPClassificationView navigate={navigate}/></SecureRoute>} />
              <Route path="classify-temg" element={<SecureRoute requiredRole={ClassificationPermissions.TEMG}><TEMGClassificationView navigate={navigate}/></SecureRoute>} />
            </Routes>
          </SecureRoute>
        </div>
      </div>
    </>
  );
}
