import React, { useState, useMemo } from "react";
import { usePopper } from "react-popper";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function EllipsisDropdown({items, iconClass}) {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update} = usePopper(referenceElement, popperElement, {
    placement: "left-start",
  });

  const toggle = () => {
    if (isOpen) {
      console.log("Closing");
      setIsOpen(false);
    } else {
      console.log("Opening");
      setIsOpen(true);
    }
    update();
  }

  const elements = useMemo(
    () => items.map((item, index) => {
      return (
        <Link
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          to={item.target}
          key={"elipsis-dropdown-" + index}
        >
          {item.text}
        </Link>
      );
    })
  );

  return (
    <>
      <div className="text-blueGray-500 py-1 px-3">
        <button type="button" 
          ref={setReferenceElement}
          onClick={toggle}
        >
          <i className={"fas fa-ellipsis-v" + (iconClass ? " " + iconClass : "")}/>
        </button>
      </div>
      <div ref={setPopperElement} 
        className={
          (isOpen ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
          }
          style={styles.popper} 
          {...attributes.popper}
          >
        {elements}
      </div>
      
    </>
  );
}

EllipsisDropdown.defaultProps = {
  items: []
}

EllipsisDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      target: PropTypes.string
    })
  )
};