import TEMGClassificationView from "./TEMGClassificationView";
import colors from "tailwindcss/colors";
import React from "react";
import ClassifyChart from "components/Charts/ClassificationChart";
import { Tooltip } from "flowbite-react";
import ConfirmPopup from "components/Popups/ConfirmPopup";
import Hotkeys from "react-hot-keys";
class TEMGAdjudicationView extends TEMGClassificationView {
    constructor(props) {
        super(props);
        this.state = { // Add adjudication props
            ...this.state,
            setsWithDiscrepancies: [],
            atDiscrepancyIndex: -1,
            annotationLogs: {},
            setAnnotations: {},
            recordId: props.params.id || "",
            selectedAnnotation: "N/A",
            annotatorNotes: "N/A",
            annotatorFlagged: "N/A",
            systemDiscrepancies: [],
        };
    }

    setAnnotationSelected(value, updateChart = false) {
        this.setState({selectedAnnotation: value});
        if (updateChart) {
            this.loadSet(this.state.currentSet).then(set => {
                this.updateView(set.sweep, set.interrogating, set.set_index, set.waveforms, set.all_annotations[value])
            });
        }
    }

    setAnnotationLogs(value) {
        this.setState({annotationLogs: value});
    }

    updateSetAnnotations(annotations) {
        this.setState({setAnnotations: annotations});
    }

    setSystemDiscrepancies(discrepancies) {
        this.setState({systemDiscrepancies: discrepancies});
    }

    lineClicked(dataset, x, y, itemIndex) {
        super.lineClicked(dataset, x, y, itemIndex);
        this.setAnnotationSelected("OVERRIDE");
    }

    clearAnnotationsHandler() {
        super.clearAnnotationsHandler();
        this.setAnnotationSelected("OVERRIDE");
    }

    changeMarkerStatus(dataset) {
        super.changeMarkerStatus(dataset);
        this.setAnnotationSelected("OVERRIDE");
    }

    nextConfidenceLevel(dataset) {
        super.nextConfidenceLevel(dataset);
        this.setAnnotationSelected("OVERRIDE");
    }

    async nextDiscrepancy() {
        const index = this.state.atDiscrepancyIndex + 1;
        const next = this.state.setsWithDiscrepancies[index];

        if (next !== undefined) {
            const currentDiscrepancyIndex = parseInt(this.state.setsWithDiscrepancies[this.state.atDiscrepancyIndex])
            if (this.state.selectedAnnotation === "OVERRIDE" || this.state.currentSet == currentDiscrepancyIndex) {
                console.log('Saving Annotation Override with annotations', this.state.dataAnnotations);
                this.saveAnnotations(this.state.currentSet, this.state.dataAnnotations, next);
            } else {
                console.log('Sending set index update to server: ' + next);
                this.saveAnnotations(undefined, undefined, next);
            }

            console.log('Moving to next discrepancy');
            this.setState({atDiscrepancyIndex: index})
            this.setLoading(true);
            const set = await this.loadSet(next);
            this.updateSetAnnotations(set.all_annotations);
            this.setSystemDiscrepancies(set.discrepancies || []);
            let annotations = set.all_annotations?.[this.state.selectedAnnotation];
            if (set.annotations !== undefined) { // Set has annotation overrides
                annotations = set.annotations;
                this.setAnnotationSelected("SAVED");
            } else if (this.state.selectedAnnotation === "OVERRIDE" || this.state.selectedAnnotation === "SAVED") {
                const firstEntry = Object.entries(set.all_annotations || {})[0];
                this.setAnnotationSelected(firstEntry?.[0] || "N/A")
                annotations = firstEntry?.[1];
            }
            this.updateView(set.sweep, set.interrogating, set.set_index, set.waveforms, annotations);
            this.setLoading(false);
    
            // Lazy load the next discrepancy if needed
            if (this.state.setsWithDiscrepancies[index+1] !== undefined) {
                const nextDiscrepancy = this.state.setsWithDiscrepancies[index+1];
                if (!this.hasCachedSet(nextDiscrepancy)) {
                    console.log('Lazy loading next discrepancy');
                    this.loadSet(nextDiscrepancy);
                }
            }
        }
    }

    async previousDiscrepancy() {
        const index = this.state.atDiscrepancyIndex - 1;
        const prev = this.state.setsWithDiscrepancies[index];
        console.log("PREVIOUS DISCREPANCY", index, prev);

        if (prev !== undefined) {
            const currentDiscrepancyIndex = parseInt(this.state.setsWithDiscrepancies[this.state.atDiscrepancyIndex])
            if (this.state.selectedAnnotation === "OVERRIDE" || this.state.currentSet == currentDiscrepancyIndex) {
                this.saveAnnotations(this.state.currentSet, this.state.dataAnnotations, prev);
            } else {
                this.saveAnnotations(undefined, undefined, prev);
            }
            this.setState({atDiscrepancyIndex: index})
            this.setLoading(true);
            const set = await this.loadSet(prev);
            this.updateSetAnnotations(set.all_annotations);
            this.setSystemDiscrepancies(set.discrepancies || []);
            let annotations = set.all_annotations?.[this.state.selectedAnnotation];
            if (set.annotations !== undefined) { // Set has annotation overrides
                annotations = set.annotations;
                this.setAnnotationSelected("SAVED");
            } else if (this.state.selectedAnnotation === "OVERRIDE" || this.state.selectedAnnotation === "SAVED") {
                const firstEntry = Object.entries(set.all_annotations || {})[0];
                this.setAnnotationSelected(firstEntry?.[0] || "N/A")
                annotations = firstEntry?.[1];
            }
            this.updateView(set.sweep, set.interrogating, set.set_index, set.waveforms, annotations);
            this.setLoading(false);
    
            // Lazy load the previous discrepancy if needed
            if (index > 0) {
                const previousDiscrepancy = this.state.setsWithDiscrepancies[index-1];
                if (previousDiscrepancy !== undefined && !this.hasCachedSet(previousDiscrepancy)) {
                    console.log('Lazy loading previous discrepancy');
                    this.loadSet(previousDiscrepancy);
                }
            }
        }
    }

    async nextWaveformSet() {
        console.log('Moving to next waveform set...');
        this.setLoading(true);

        if (this.state.selectedAnnotation === "OVERRIDE" || this.state.systemDiscrepancies.length > 0) {
            console.log('Saving Annotation Override with annotations', this.state.dataAnnotations);
            this.saveAnnotations(this.state.currentSet, this.state.dataAnnotations, this.state.currentSet + 1);
        } else {
            console.log('Sending set index update to server: ' + this.state.currentSet + 1);
            this.saveAnnotations(undefined, undefined, this.state.currentSet + 1);
        }

        if (this.state.currentSet + 1 < this.setCount - 1 && !this.hasCachedSet(this.state.currentSet + 2)) {
            console.log('Lazy loading next set');
            this.loadSet(this.state.currentSet + 2);
        }

        const nextSet = await this.loadNextSet();
        this.updateSetAnnotations(nextSet.all_annotations);
        this.setSystemDiscrepancies(nextSet.discrepancies || []);
        let annotations = nextSet.all_annotations?.[this.state.selectedAnnotation];
        if (nextSet.annotations !== undefined) { // Set has annotation overrides
            annotations = nextSet.annotations;
            this.setAnnotationSelected("SAVED");
        } else if (this.state.selectedAnnotation === "OVERRIDE" || this.state.selectedAnnotation === "SAVED") {
            const firstEntry = Object.entries(nextSet.all_annotations || {})[0];
            this.setAnnotationSelected(firstEntry?.[0] || "N/A")
            annotations = firstEntry?.[1];
        }

        this.updateView(nextSet.sweep, nextSet.interrogating, nextSet.set_index, nextSet.waveforms, annotations);
        this.setLoading(false);
    }

    async previousWaveformSet() {
        console.log('Moving to previous waveform set...');
        this.setLoading(true);

        if (this.state.selectedAnnotation === "OVERRIDE" || this.state.systemDiscrepancies.length > 0) {
            console.log('Saving Annotation Override with annotations', this.state.dataAnnotations);
            this.saveAnnotations(this.state.currentSet, this.state.dataAnnotations, this.state.currentSet - 1);
        } else {
            console.log('Sending set index update to server: ' + (this.state.currentSet - 1));
            this.saveAnnotations(undefined, undefined, this.state.currentSet - 1);
        }

        // Lazy load the previous set
        if (this.state.currentSet - 1 > 0 && !this.hasCachedSet(this.state.currentSet - 2)) {
            console.log('Lazy loading previous set');
            this.loadSet(this.state.currentSet-2);
        }

        const prevSet = await this.loadPreviousSet();
        this.updateSetAnnotations(prevSet.all_annotations);
        this.setSystemDiscrepancies(prevSet.discrepancies || []);

        let annotations = prevSet.all_annotations?.[this.state.selectedAnnotation];
        if (prevSet.annotations !== undefined) { // Set has annotation overrides
            annotations = prevSet.annotations;
            this.setAnnotationSelected("SAVED");
        } else if (this.state.selectedAnnotation === "OVERRIDE" || this.state.selectedAnnotation === "SAVED") {
            const firstEntry = Object.entries(prevSet.all_annotations || {})[0];
            this.setAnnotationSelected(firstEntry?.[0] || "N/A")
            annotations = firstEntry?.[1];
        }
        this.updateView(prevSet.sweep, prevSet.interrogating, prevSet.set_index, prevSet.waveforms, annotations);
        this.setLoading(false);
    }

    async init() {
        console.log('ADJUDICATING RECORD: ' + this.state.recordId);
        this.setLoading(true);
        console.log("TEMG Adjudication View Initializing...")
        const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + '/api/temgset?adjudicate=true&record_id=' + this.state.recordId,
            {method: 'GET', headers: await this.context.getAuthHeader()}
        );
        if (response.status != 200) {
            if (response.status == 404) {
                window.alert('Could not fetch... Try again later..');
                this.props.navigate(-1);
                return;
            }
            window.alert("Something went wrong.. Please try again later..")
            console.error(response);
            return;
        }
        let body = await response.json();
        console.log('INIT: ', body);
        this.setCount = body.total_sets;
        this.setAnnotationLogs(body.annotation_logs);
        if (body.all_annotations) {
            body.all_annotations = this.mapAnnotationsToLocalVersion(body.all_annotations);
        }
        this.updateSetAnnotations(body.all_annotations);
        this.setSystemDiscrepancies(body.discrepancies || []);
        this.setState({setsWithDiscrepancies: body.discrepancies_in_record || []});

        if (body.discrepancies_in_record.length > 0) { // Look through discrepancy set list and determine if retrieved sweep is one of them (sets up the discrepancy navigation)
            for (let i = 0; i < body.discrepancies_in_record.length; i++) {
                const discrepancySet = parseInt(body.discrepancies_in_record[i]);
                if (parseInt(body.set_index) === discrepancySet) {
                    console.log('User left off at discrepancy index ' + i + ' setting index');
                    this.setState({atDiscrepancyIndex: i}); // User left off on a discrepancy set
                    break;
                }
            }
        }

        const overrideAnnotation = body.annotations; // Adjudicator's selected annotation that is saved in db
        const firstEntry = Object.entries(body.all_annotations || {})[0];
        this.setAnnotationSelected((overrideAnnotation !== undefined) ? "SAVED" : (firstEntry?.[0] || "N/A"));
        this.updateView(body.sweep, body.interrogating, body.set_index, body.waveforms, overrideAnnotation || firstEntry?.[1]);

        this.setCachedSet(body.set_index, body);
        this.setLoading(false);
        if (body.set_index < body.total_sets - 1) {
            this.loadSet(body.set_index + 1);
        }
        if (body.set_index > 0) {
            this.loadSet(body.set_index - 1);
        }
    }

    async loadSet(setIndex) {
        console.log('Loading set: ' + setIndex);

        const cachedSet = this.getCachedSet(setIndex);
        if (cachedSet) {
            console.log('Loaded set: ' + setIndex + ' from cache', cachedSet);
            return cachedSet;
        }

        const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + '/api/temgset/set/' + setIndex + '?adjudicate=true',
            {method: 'GET', headers: await this.context.getAuthHeader()}
        );

        if (response.status != 200) {
            console.error('failed to load set: ' + setIndex, response);
            return;
        }
        const body = await response.json();
        if (body.all_annotations) {
            body.all_annotations = this.mapAnnotationsToLocalVersion(body.all_annotations);
        }
        this.setCachedSet(setIndex, body);

        console.log('Loaded set: ' + setIndex + ' from server', body)
        return body;
    }

    async saveAnnotations(setIndex, annotations, setNextSetTo) {
        this.setSaving(true);
        let requestBody = {};
        if (setIndex !== undefined && annotations !== undefined) {
            const preparedAnnotations = this.prepareAnnotationsForSave(annotations);
            const currentCachedSet = this.getCachedSet(setIndex);
            if (currentCachedSet) {
                const item = {
                    ...currentCachedSet,
                    annotations: preparedAnnotations,
                }
                this.setCachedSet(setIndex, item);
            } else {
                console.error('Could not update cached item with new annotations')
            }
            requestBody.set_index = setIndex;
            requestBody.annotations = preparedAnnotations;
        }

        if (setNextSetTo !== undefined) {
            requestBody.change_set_to = setNextSetTo;
        }

        console.log("Sending save request with body", requestBody);
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/temgset/save?adjudicate=true', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...await this.context.getAuthHeader() },
            body: JSON.stringify(requestBody)
        }).catch(error => {
            window.alert('Something went wrong')
            console.error('Failed to save annotations', error);
        })
        if (response.status != 200) {
            console.error('Failed to save annotations', response);
            window.alert('Something went wrong');
            this.setSaving(false);
            return false;
        }
        this.setSaving(false);
        return true;
    }

    async completeRecord() {
        let result = true;
        if (this.state.selectedAnnotation === "OVERRIDE" || this.state.systemDiscrepancies.length > 0) {
            console.log('Saving Annotation Override with annotations', this.state.dataAnnotations);
            result = await this.saveAnnotations(this.state.currentSet, this.state.dataAnnotations);
        }

        if (result) {
            this.setLoading(true);
            const err = false;
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/temgset/finish?adjudicate=true', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', ...await this.context.getAuthHeader() },
            }).catch(error => {
                err = true; 
                console.error(error)
            });
            if (response.status !== 200 || err) {
                window.alert('Something went wrong..')
                console.error('Failed to complete temg adjudication record');
            }
            this.props.navigate(-1);
        }
    }

    mapAnnotationsToLocalVersion(unformatted) {
        const formattedAllAnnotations = {}
        const map = (annotation) => ({
            confidenceLevel: annotation.confidence_level,
            end: annotation.end_value,
            endIndex: annotation.end_index,
            start: annotation.begin_value,
            startIndex: annotation.begin_index,
            type: annotation.annotation_type,
            markersIncorrect: annotation.markers_incorrect,
        });
        for (const [key, value] of Object.entries(unformatted || {})) {
            formattedAllAnnotations[key] = value.map(unformatted => {
                if (!unformatted) return null;
                if (Array.isArray(unformatted)) {
                    return unformatted.map(map);
                }
                return map(unformatted)
            })
        }
        return formattedAllAnnotations;
    }

    
    handleHotKey(keyName, e, handle) {
        switch(keyName) {
            case "left":
                if (!this.state.loading && (this.state.atDiscrepancyIndex > 0)) {
                    return this.previousDiscrepancy();
                }
            break;
            case "right":
                if (!this.state.loading && (this.state.atDiscrepancyIndex < this.state.setsWithDiscrepancies.length - 1)) {
                    return this.nextDiscrepancy();
                }
            break;
        }
        super.handleHotKey(keyName, e, handle);
    }

    render() {
        let progress = (this.setCount !== 0 || !this.state.loading) ? ((this.state.atDiscrepancyIndex + 1) / (this.state.setsWithDiscrepancies.length) * 100) : 0;
        if (this.state.setsWithDiscrepancies.length === 0 && this.setCount !== 0) progress = 100;
        const controls = (
            <>
            <button
                className={
                    ((this.state.currentSet < 1) ? "bg-gray-700 cursor-not-allowed" : "bg-sky-700 active:bg-sky-600")
                    + " text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150 xl:w-52"
                }
                type="button"
                onClick={this.previousWaveformSet.bind(this)}
                disabled={this.state.currentSet < 1 || this.state.loading}
            >
                Previous Waveform Set
            </button>
            <button
                className={
                    ((this.state.loading) 
                        ? "bg-gray-700 cursor-not-allowed" 
                        : ((this.setCount - this.state.currentSet > 1) ? "bg-sky-700 active:bg-sky-600" : "bg-gray-700 cursor-not-allowed")
                    ) + " text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150 xl:w-52"
                }
                type="button"
                onClick={this.nextWaveformSet.bind(this)}
                disabled={this.state.loading || (this.setCount - this.state.currentSet <= 1)}
            >
                Next Waveform Set
            </button>
            <button
                className={
                    ((this.state.atDiscrepancyIndex <= 0) ? "bg-gray-700 cursor-not-allowed" : "bg-sky-700 active:bg-sky-600")
                    + " text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150 xl:w-52"
                }
                type="button"
                onClick={this.previousDiscrepancy.bind(this)}
                disabled={this.state.loading || (this.state.atDiscrepancyIndex <= 0)}
            >
                Previous Discrepancy
            </button>
            <button
                className={
                    ((this.state.loading)
                        ? "bg-gray-700 cursor-not-allowed"
                        : ((this.state.setsWithDiscrepancies.length - this.state.atDiscrepancyIndex > 1) ? "bg-sky-700 active:bg-sky-600" : "bg-green-500 active:bg-green-600")
                    ) + " text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150 xl:w-52"
                }
                type="button"
                onClick={(this.state.setsWithDiscrepancies.length - this.state.atDiscrepancyIndex > 1) ? this.nextDiscrepancy.bind(this) : this.showFinishDialog.bind(this, true)}
                disabled={this.state.loading}
            >
                {(this.state.setsWithDiscrepancies.length - this.state.atDiscrepancyIndex > 1) ? "Next Discrepancy" : "Finish Adjudication"}
            </button>
            </>
        )


        return (
            <Hotkeys
                keyName="left,right,t,m,n,delete"
                onKeyDown={this.handleHotKey.bind(this)}
            >
              <div className="flex flex-wrap">
                <div className="relative sm:w-screen flex flex-wrap shadow-lg rounded bg-atec-dark-grey mb-6 w-full text-gray-100 justify-center xl:justify-start items-start">
                    <div className="relative w-8/12 mt-1 px-4 flex grow-0 justify-evenly">
                        <div className="flex-initial font-semibold text-lg">Sweep Index: {this.state.sweepTitle}</div>
                        <div className="flex-initial font-semibold text-lg">Stimulus Current: {this.state.interrogatingTitle}</div>
                    </div>
                    <div className="w-full lg:w-2/3 xl:w-4/12 max-w-xl">
                        <div className="relative mx-4">
                            <div className="flex justify-between mb-1 font-medium items-end">
                                <span>Adjudication Progress: </span>
                                <span>{(this.setCount === 0 && this.state.loading) ? "Loading" : `${Math.floor(progress)}%`}</span>
                            </div>
                            <div class="w-full bg-gray-300 rounded-full h-2.5">
                                <div className={(progress === 100 ? "bg-green-500" : "bg-sky-600") + " h-2.5 rounded-full"} style={{width: progress + "%"}}></div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-8/12">
                        {this.state.loading && 
                            <div role="status" className="absolute top-[8px] left-[8px]">
                                <svg className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-atec-neon-green-100" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>  
                        }
                        <div className="relative h-[70vh] p-3">
                            {this.state.chartData && 
                                <ClassifyChart 
                                    data={this.state.chartData} 
                                    annotations={this.state.chartAnnotations} 
                                    clickListener={this.lineClicked} 
                                    yUnit="uV"
                                    yTitle="Voltage"
                                    xUnit="ms"
                                    xTitle="Time"
                                    lineColor={colors.cyan[400]}
                                    hoverColor={colors.cyan[200]}
                                    customLegend={this.legendPlugin}
                                    disableTooltip={this.state.disableTooltip}
                                />
                            }
                        </div>
                        <div className={"relative flex-row w-full justify-evenly mb-5 px-4 hidden xl:flex"}>
                            {controls}
                        </div>  
                    </div>
                    <div className="w-full lg:w-2/3 xl:w-4/12 max-w-xl">
                        <div className="mx-2 mb-4 xl:mx-0 xl:mr-2 2xl:mx-0 2xl:mr-2">
                            <h2 className="w-full text-center leading-border-text -mb-3 pr-2 pl-2">
                                <span className="bg-atec-dark-grey px-1 text-md font-medium">Annotations</span>
                            </h2>
                            <div className="flex flex-col px-5 pb-1 pt-4 border-b border-t border-r border-l border-atec-light-grey rounded-md lg">
                                <div className="flex flex-wrap justify-between items-center">
                                    <button
                                        className=
                                        {
                                            ((this.state.annotationMode == 0) 
                                            ? "bg-green-500 hover:bg-green-600"
                                            : "bg-red-500 hover:bg-red-600")
                                            + " w-[135px] text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150"
                                        }
                                        type="button"
                                        onClick={this.changeAnnotationMode}
                                    >
                                        Mode:
                                        {(this.state.annotationMode == 0) 
                                        ? " Activity" 
                                        : " Artifact"}
                                    </button>
                                    <div className="flex flex-grow justify-start">
                                        <Tooltip
                                            content={(this.state.multipleAnnotationEnabled ? "Disable Multiple Annotations" : "Enable Multiple Annotations")}
                                            trigger="hover"
                                        >
                                            <i
                                                className={
                                                    (this.state.multipleAnnotationEnabled ? "fa-solid text-blue-400" : "fa-regular hover:text-blue-400") +
                                                    " fa-square-plus fa-xl px-2 mr-2 cursor-pointer shadow hover:shadow-md m-1 ease-linear transition-all duration-150 rounded-lg"
                                                }
                                                onClick={() => this.setMultipleAnnotationEnabled(!this.state.multipleAnnotationEnabled)}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Tooltip
                                        content={"Clear All"}
                                        trigger="hover"
                                    >
                                        <i 
                                            className="fas fa-trash fa-lg hover:text-red-500 px-2 mr-2 cursor-pointer shadow hover:shadow-md m-1 ease-linear transition-all duration-150"
                                            onClick={this.clearAnnotationsHandler}
                                        />
                                    </Tooltip>
                                </div>
                                <div className="flex flex-nowrap justify-between mt-5">
                                    <p className="w-[80px] mx-1">Confidence</p>
                                    <p>Markers</p>
                                </div>
                                <div id="legend-container" className="flex flex-col">
                                    {(() => {
                                        let containers = [];
                                        for (let i = 1; i <= this.state.dataAnnotations.length; i++) {
                                            containers.push(
                                                <div key={i} className="flex flex-nowrap items-center justify-between">
                                                    <button
                                                        className=
                                                        {
                                                            this.getConfidenceBtnClasses(i-1).join(" ")
                                                                + " w-[80px] grow-0 shrink-0 text-white font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150"
                                                        }
                                                        type="button"
                                                        onClick={this.nextConfidenceLevel.bind(this, i-1)}
                                                        disabled={!this.annotationControlsEnabled(i-1)}
                                                    >
                                                        {this.getConfidenceBtnText(i-1)}
                                                    </button>

                                                    <div id={"legend-container-" + i} className="basis-3/5"></div>
                                                    <i 
                                                        className={
                                                            this.getMarkerClasses(i-1).join(" ")
                                                            + " fas fa-thumbs-up px-2 cursor-pointer shadow hover:shadow-md m-1 ease-linear transition-all duration-150 float-right"
                                                        }
                                                        onClick={this.changeMarkerStatus.bind(this, i-1)}
                                                    />
                                                </div>
                                            );
                                        }
                                        return containers;
                                    })()}
                                </div>
                                <div className={"flex flex-nowrap justify-center mb-2" + ((this.state.isSaving) ? "" : " invisible")}>
                                    <p className="text-atec-neon-green-100">Saving..</p>
                                </div>
                                <div className="flex flex-col mb-2">
                                    <div className="flex flex-nowrap justify-between items-center">
                                        <div>Selected Annotation</div>
                                        <select 
                                            value={this.state.selectedAnnotation} 
                                            onChange={(e) => this.setAnnotationSelected(e.target.value, true)} 
                                            className={"w-1/2 min-w-[160px] p-2.5 pr-6 border rounded-md shadow-sm outline-none appearance-none text-ellipsis focus:ring-white focus:border-inherit "
                                                + (this.state.selectedAnnotation === "N/A" ? "cursor-not-allowed bg-gray-600" : (this.state.selectedAnnotation === "OVERRIDE" || this.state.selectedAnnotation === "SAVED") ? "bg-orange-400" : "bg-sky-600")}
                                            >
                                            <option value="N/A" hidden>N/A</option>
                                            <option value="OVERRIDE" hidden>Override</option>
                                            <option value="SAVED" hidden>On Screen</option>

                                            {(() => {
                                                let options = [];
                                                for (const userId of Object.keys(this.state.setAnnotations || {})) {
                                                    options.push(
                                                        <option className="bg-atec-dark-grey" key={userId} value={userId}>{this.state.annotationLogs[userId]?.user_email || userId}</option>
                                                    )
                                                }
                                                return options
                                            })()}
                                        </select>
                                    </div>
                                    <div className={"flex flex-col" + (this.state.systemDiscrepancies.length > 0 ? "" : " hidden")}>
                                        <hr className="mt-4 mb-2 w-full" />
                                        <div className="font-bold">Discrepancies</div>
                                        <ul className="list-disc ml-7">
                                            {this.state.systemDiscrepancies.map(discrepancy => {
                                                return (<li>{discrepancy}</li>)
                                            })}
                                        </ul>
                                    </div>
                                    <div className={"flex flex-col" + ((this.state.selectedAnnotation !== "N/A" && this.state.selectedAnnotation !== "OVERRIDE" && this.state.selectedAnnotation !== "SAVED") ? "" : " hidden")}>
                                        <hr className="mt-4 mb-2 w-full" />
                                        <div className="mb-1"><strong>Classifier: </strong> {this.state.annotationLogs[this.state.selectedAnnotation]?.user_email || "N/A"}</div>
                                        <div className="mb-1"><strong>Flagged Case: </strong> {this.state.annotationLogs[this.state.selectedAnnotation]?.caused_flag || "false"}</div>
                                        <div><strong>Notes: </strong> {this.state.annotationLogs[this.state.selectedAnnotation]?.notes || "N/A"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"relative w-full lg:w-1/3 flex-col xl:hidden mb-5 px-4 max-w-xl flex"}>
                        {controls}
                    </div>      
                </div>
                <ConfirmPopup
                    show = {this.state.showFinishDialog}
                    confirmText = "Finish Adjudication"
                    cancelText = "Cancel"
                    content = {<p>Are you sure you want to finish adjudicating this record?</p>}
                    confirmCallback = {() => {this.showFinishDialog(false); this.completeRecord();}}
                    cancelCallback = {() => {this.showFinishDialog(false);}}
                    title = "Please Confirm"
                />
              </div>
            </Hotkeys>
          );
    }

}

export default TEMGAdjudicationView;