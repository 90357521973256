import React from "react";
import PropTypes from "prop-types";
import EllipsisDropdown from "components/Dropdowns/EllipsisDropdown";
import { Link } from "react-router-dom";

// components

export default function CardTable({title, columns, color, data, extraHeaderContent}) {
    let thead = [];
    let index = 0;
    for (const column of columns) {
        thead.push(
            <th key={"head-" + index}
                className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                (color === "light"
                    ? "bg-blueGray-100 text-blueGray-500 border-blueGray-200"
                    : "bg-atec-dark-grey text-gray-100 border-gray-400")
                }
                >
                  {column.header}
            </th>
        )
        index += 1;
    }

    const buildItem = (type, content) => {
        switch(type) {
            case "text":
                return (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {content}
                    </td>
                );
            case "date":
                return (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {content}
                    </td>
                );
            case "status":
                return (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i className={"fas fa-circle mr-2 " + content[1]}></i> {content[0]}
                    </td>
                );
            case "list":
                let list = content.map((item) => {
                    return (<li>{item}</li>)
                });
                return (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {(list.length > 0) ? (<ul className="list-disc">{list}</ul>): "N/A"}
                    </td>   
                );
            case "progress":
                return (
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="flex items-center">
                            <span className="mr-2">{content}%</span>
                            <div className="relative w-full">
                                <div className={"overflow-hidden h-2 text-xs flex rounded " + (content === "100" ? "bg-emerald-200" : "bg-red-200")}>
                                    <div
                                    style={{ width: content + "%"}}
                                    className=
                                    {"shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center " 
                                        + (content === "100" ? "bg-emerald-500" : "bg-red-500")
                                    }
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </td>
                );
            case "button-link":
              return (
                <td className="border-t-0 border-l-0 border-r-0 text-md whitespace-nowrap align-middle items-center">
                  <div className="flex items-center">
                    <Link to={content[1]}>
                      <button className="bg-atec-neon-green-400 text-white active:bg-atec-neon-green-200 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                        {content[0]}
                      </button>
                    </Link>
                  </div>
                </td>
              )
            case "menu":
              return (
                <td className="border-t-0 border-l-0 border-r-0 text-md whitespace-nowrap align-middle items-center">
                  <div className="flex items-center ml-6">
                    <EllipsisDropdown items={content} iconClass={(color === 'dark') ? "text-white" : null}/>
                  </div>
                </td>
              )
        }
    }

    let rows = [];

    if (!data) {
      rows.push(
        <tr key={"table-row-loading"}>
          <td className={"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 col-span-full"}>
            Loading..
          </td>
        </tr>
      )
    } else {
      index = 0;
      for (const item of data) {
          let cols = []
          for (let i = 0; i < columns.length; i++) {
              cols.push(buildItem(columns[i].type, item[i], "column-" + i))
          }
          rows.push(<tr key={"table-row-" + index}>{cols}</tr>)
          index += 1;
      }
  
      if (rows.length === 0) {
        rows.push(
          <tr key={"table-row-loading"}>
            <td className={"border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 col-span-full"}>
              None
            </td>
          </tr>
        )
      } 
    }


  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white text-blueGray-700" : "bg-atec-dark-grey text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                {title}
              </h3>
            </div>
            {extraHeaderContent}
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
                <tr>{thead}</tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}


CardTable.defaultProps = {
    title: "",
    columns: [],
    color: "light",
    data: [],
}

CardTable.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array,
    color: PropTypes.oneOf(["light", "dark"]),
    data: PropTypes.array
  };