import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
var reactPlugin = new ReactPlugin();
console.log('Initializing Application Insights');
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: ['*.auth0.com'],
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [reactPlugin],
        disableTelemetry: (process.env.NODE_ENV !== 'production')
    }
});
appInsights.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);

export const applicationInsights = appInsights;