import Loading from "components/Loading";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export const SecureRoute = ({permissionRequired=false, children}) => {
    const { isLoggedIn, getPermissions, subscribeToPermissionUpdate } = useAuth();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        (async () => {
            if (!await isLoggedIn()) {
                console.log('User not logged in, redirecting to login..')
                navigate("/auth/login");
                return;
            }
            const permissions = await getPermissions().catch(err => {
                window.alert('Something went wrong, please try again later..')
                console.error('Failed to fetch permissions', err);
                navigate('/');
                return;
            });
            if (permissionRequired) {
                if (permissions && permissions.includes(permissionRequired)) {
                    setShow(true);
                    return
                }
                console.log(`User does not possess permission "${permissionRequired}", access forbidden to route`);
                navigate('/auth/error?error=forbidden');
                return;
            }
            setShow(true);
        })();
        subscribeToPermissionUpdate((newPermissions) => {
            if (permissionRequired && !newPermissions.includes(permissionRequired)) {
                navigate('/user/myaccount');
            }
        })
    }, []);


    return ((!show) ? <Loading/> : children);
};