import React, { useEffect, useState } from "react";
import CardProfile from "components/Cards/CardProfile";
import CardWithButton from "components/Cards/CardWithButton.js";
import { useUserService } from "contexts/UserContext";
import { getRoleString } from "../../util/Permissions";

export default function MyAccount() {
    const [user, setUser] = useState(null);
    const [roleString, setRoleString] = useState("");
    const { getMyUser } = useUserService();

    //TODO: Update this once information is available
    const stats = {
        itemsClassified: "N/A",
        avgTimeSpent: "N/A"
    }
    useEffect(() => {
        getMyUser().then(u => {
            u.last_login = u.last_login ? new Date(u.last_login).toLocaleString() : "Unknown";
            setUser(u);
            setRoleString(getRoleString(u.permissions));
        }).catch(err => {
            console.error(err);
            window.alert('Failed to load your user account, please refresh the page');
        })
    }, []);

  return (
    <>
      <div className="flex flex-wrap">
        {user &&
        <>
            <div className="w-full lg:w-8/12 px-4" data-testid='myaccount-user-div'>
                <CardWithButton content={
                    <div className="flex flex-wrap py-4">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Name</label>
                                <input
                                    type="text"
                                    value={user.name}
                                    disabled={true}
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full cursor-not-allowed"
                                />
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Email</label>
                                <input
                                    type="text"
                                    value={user.email}
                                    disabled={true}
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow w-full cursor-not-allowed"
                                />
                            </div>
                        </div>
                    </div>  
                } title="My Account"/>
            </div>
            <div className="w-full lg:w-4/12 px-4">
                <CardProfile 
                    name={user.name}
                    lastLogin={user.last_login}
                    role={roleString}
                    stats={stats} 
                />
            </div>
        </>
        }
        
      </div>
    </>
  );
}
