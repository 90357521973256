import { 
  BrowserRouter as Router,
  Route, 
  Routes,
  Navigate
} from 'react-router-dom';

import { AuthProvider } from 'contexts/AuthContext';
import './App.css';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin";
import Auth from "layouts/Auth";
import User from 'layouts/User';
import Developer from 'layouts/Developer';
import { UserProvider } from 'contexts/UserContext';
import AppInsights from 'util/ApplicationInsights';

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
  return (
    <div className='min-w-[375px]'>
      <Router>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <UserProvider>
              <Routes>
                <Route path="/" element={<Navigate to="/auth/login" />} />
                <Route path="/admin/*" element={<Admin/>} />
                <Route path="/auth/*" element={<Auth/>} />
                <Route path="/user/*" element={<User/>} />
                <Route path="/developer/*" element={<Developer/>} />
              </Routes>
            </UserProvider>
          </AuthProvider>
        </QueryClientProvider>
      </Router>
    </div>
      
  );
}

export default AppInsights(App);
