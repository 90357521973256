import React, { useEffect, useState } from "react";
import { IngestionClient, IngestionStatus } from "../../util/IngestionClient";
import { useAuth } from 'contexts/AuthContext';

export default function Ingestion() {
    const { getAuthHeader } = useAuth();
    const [client] = useState(IngestionClient.getInstance(getAuthHeader));
    const [fileDetails, setFileDetails] = useState(client.getFileDetails());
    const [isWorking, setWorking] = useState(client.isWorking());


    useEffect(() => {
      console.log('Setting auth function');
      client.setAuthHeaderFunction(getAuthHeader);
    }, [getAuthHeader])

    useEffect(() => {
      if (isWorking) {
        const interval = setInterval(() => {
          setFileDetails(client.getFileDetails());
          if (!isWorking) {
            clearInterval(interval);
          }
        }, 500);
        return () => {
          clearInterval(interval);
        };
      }
    }, [isWorking]);

    const ingestionReady = client.isReady();

    const clearAllFiles = () => {
      if (!isWorking) {
        client.setFiles([]);
        setFileDetails(client.getFileDetails());
      }
    }

    const removeFileAtPosition = (index) => {
      if (!isWorking) {
        client.removeFileAtPosition(index);
        setFileDetails(client.getFileDetails());
      }
    }

    const handleFileChange = (e) => {
      let files = [];
      const currentFiles = client.getFileDetails();
      for (const file of e.target.files) {
        let duplicate = false;
        for (const current of currentFiles) {
          if (file.name === current.name) {
            duplicate = true;
            break;
          }
        }
        if (!duplicate) files.push(file);
      }
      client.addFiles(files);
      setFileDetails(client.getFileDetails());
    }

    const triggerIngestion = async () => {
      try {
        setWorking(true);
        await client.startIngestion();
        setWorking(false);
        setFileDetails(client.getFileDetails());
      } catch (err) {
        console.error(err);
        window.alert('Something went wrong..');
      }
    }

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">Data Ingestion</h6>
            </div>
          </div>
            <div className="flex flex-wrap px-4 lg:px-10 py-5">
              <div className="md:w-1/2 w-full">
                <div className="flex items-center justify-center w-full" >
                    <label id="dropzone-label" htmlFor="dropzone-file" className={"flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg " + (isWorking ? " cursor-not-allowed bg-gray-200" : " cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600")} >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">JSON</p>
                        </div>
                        <input onChange={handleFileChange} id="dropzone-file" type="file" className="hidden" accept=".json" disabled={isWorking} multiple/>
                    </label>
                </div> 
              </div>
              <div className="flex flex-col px-4 lg:px-10 mb-5 md:w-1/2 w-full">
                {fileDetails.length > 0 && 
                <div className="flex flex-nowrap justify-between items-center">
                  <div className="font-bold">Selected Files</div>
                  <i 
                    className={"fas fa-trash fa-lg px-2 mr-2 cursor-pointer m-1 ease-linear transition-all duration-150 " + (isWorking ? "cursor-not-allowed" : "cursor-pointer hover:text-red-500")}
                    onClick={clearAllFiles}
                  />
                </div>
                }
                <ul className="list-disc ml-5">
                  {fileDetails.map((detail, index) => {
                    if (!isWorking && detail.status == IngestionStatus.Waiting) {
                      return (
                        <li 
                        key={detail.name}
                        className="hover:line-through cursor-pointer hover:text-red-500"
                        onClick={() => {removeFileAtPosition(index)}}
                        >
                          {detail.name + " - " + detail.type}
                        </li>)
                    }
                    switch(detail.status) {
                      case IngestionStatus.Uploading:
                        const percentComplete = (detail.percent_complete || 0).toString();
                        return (
                          <li key={detail.name}>
                            {detail.name + " - " + detail.type} <i className="fas fa-spinner animate-spin"/>
                            <ul className="list-none ml-3">
                              <li>
                                <div className="flex justify-between mb-1">
                                  <span className="font-medium">Status: Uploading</span>
                                  <span className="text-sm font-medium">{percentComplete + "%"}</span>
                                </div>
                                <div className="w-full bg-gray-300 rounded-full h-2.5">
                                  <div className="bg-sky-600 h-2.5 rounded-full" style={{width: percentComplete + "%"}}></div>
                                </div>
                              </li>
                            </ul>
                          </li>)
                      case IngestionStatus.Error:
                        return (
                          <li className="text-red-600" key={detail.name}><span className="font-bold">{detail.name + " - " + detail.type}</span>
                            <ul className="list-none ml-3">
                              {detail.errors.map(error => {
                                return <li key={error} className="font-semibold">{error}</li>
                              })}
                            </ul>
                          </li>);
                      case IngestionStatus.Complete:
                        return (
                          <li className="text-emerald-600" key={detail.name}>
                            {detail.name + " - " + detail.type} <i className="fas fa-check"/>
                          </li>)
                      default:
                        return (
                          <li key={detail.name}>
                            {detail.name + " - " + detail.type} <i className="fas fa-spinner animate-spin"/>
                            <ul className="list-none ml-3">
                              <li><span className="font-medium">Status: {detail.status}</span></li>
                            </ul>
                          </li>)
                      }
                    })}
                </ul>
              </div>
            </div>
            <div className="flex justify-center py-5">
              <button
                className={
                  (!ingestionReady ? "bg-gray-500 cursor-not-allowed" : "bg-emerald-500 active:bg-emerald-400") +
                  " text-white font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none m-1 ease-linear transition-all duration-150 w-60"
                }
                type="button"
                onClick={triggerIngestion}
                disabled={!ingestionReady}
              >
                Ingest Files
              </button>
            </div>
            
            
          </div>
        </div>
      </div>
    </>
  );
}
