import React from "react";
import { useAuth } from "contexts/AuthContext";

// components

import CardPaginatedTable from "components/Cards/CardPaginatedTable.js";

export default function SSEPAdjudicationListView() {
  const { getAuthHeader } = useAuth();

  const fetchFunction = async (page) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/adjudicate-ssep/list?offset=' + (page * 10) + '&max=10', {method: "GET", headers: await getAuthHeader()});
    if (response.status != 200 && response.status != 404) {
      console.error("Something went wrong")
      return {data: [], hasMore: false};
    }
    if (response.status === 404) {
      return {data: [], hasMore: false};
    }
    try {
      const { records, total } = await response.json();
      const formatted = records.map((record) => {
        return [record.case_id || "N/A", record.ssep_record_id || "N/A", record.document_id || "N/A", record.annotation_count || "N/A", (record.user_emails ? record.user_emails.split(",") : []), ["Adjudicate", record.document_id]]
      })
      return {data: formatted, hasMore: ((page + 1) * 10) < total};
    } catch (err) {
      console.error("Error", err);
    }
  }

  const config = {
    title: "SSEP Records Needing Adjudication",
    columns: [
        {
            header: "CASE ID",
            type: "text"
        },
        {
            header: "SSEP RECORD ID",
            type: "text"
        },
        {
            header: "EXTERNAL LOCATOR",
            type: "text"
        },
        {
            header: "ANNOTATION COUNT",
            type: "text"
        },
        {
            header: "ANNOTATED BY",
            type: "list"
        },
        {
            header: "OPTIONS",
            type: "button-link"
        }
    ],
    color: "light",
  }

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardPaginatedTable 
            title={config.title}
            color={config.color}
            columns={config.columns}
            fetchFunction={fetchFunction}
            queryKey={"paginated-ssep-adjudicated-records"}/>
        </div>
      </div>
    </>
  );
}
