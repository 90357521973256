import { validate, ValidationError } from 'json-schema';

export enum IngestionFileType {
    Unknown = "Unknown File",
    Case = "Case File",
    Temg = "Temg File",
    Ssep = "Ssep File",
}

export enum IngestionStatus {
    Waiting = "Waiting",
    Validating = "Validating",
    Starting = "Starting",
    Uploading = "Uploading",
    Finishing = "Finishing",
    Error = "Error",
    Complete = "Complete",
}

interface IngestionItem {
    file: File
    type: IngestionFileType
    status: IngestionStatus
    totalItemsToUpload?: number,
    currentItem?: number,
    valid?: boolean,
    errors?: Array<string>
}

export interface IngestionItemDetails {
    name: string
    type: IngestionFileType
    status: IngestionStatus
    percent_complete: number
    errors?: Array<string>
}


class Ingestion {
    #files: Array<IngestionItem>
    #working: boolean
    #complete: boolean
    #getAuthHeader: Function | undefined

    #caseSchema: Object | undefined
    #temgSchema: Object | undefined
    #ssepSchema: Object | undefined

    constructor() {
        console.log('Creating Ingestion Client');
        this.#files = [];
        this.#working = false;
        this.#complete = false;
        window.onbeforeunload = () => {
            if (this.#working) {
                return 'Ingestion Client is working, leaving/refreshing page will abort ingestion';
            }
        }
    }

    // Set files for ingestion
    setFiles(files: Array<File>) {
        if (this.#working) return;
        let caseFiles: Array<IngestionItem> = [];
        let otherFiles: Array<IngestionItem> = [];
        for (const file of files) {
            const item: IngestionItem = {
                file: file,
                type: this.getFileType(file.name),
                status: IngestionStatus.Waiting
            }
            if (item.type === IngestionFileType.Case) {
                caseFiles.push(item);
            } else {
                otherFiles.push(item);
            }
        }
        this.#files = [...caseFiles, ...otherFiles];
        this.#complete = false;
    }

    addFiles(files: Array<File>) {
        if (this.#working) return;
        for (const file of files) {
            const item: IngestionItem = {
                file: file,
                type: this.getFileType(file.name),
                status: IngestionStatus.Waiting
            }
            if (item.type === IngestionFileType.Case) {
                this.#files.unshift(item); // add to beginning
            } else {
                this.#files.push(item); // add to end
            }
        }
        this.#complete = false
    }



    removeFileAtPosition(position: number) {
        if (this.#working) return;
        if (this.#files.length > position) {
            this.#files.splice(position, 1);
        }
    }

    getFileDetails() : Array<IngestionItemDetails> {
        console.log('Getting file details');
        return this.#files.map(f => {
            const result: IngestionItemDetails = {
                name: f.file.name,
                type: f.type,
                errors: f.errors,
                status: f.status,
                percent_complete: 0
            };
            if (f.currentItem && f.totalItemsToUpload) {
                result.percent_complete = Math.round((f.currentItem / f.totalItemsToUpload) * 100);
            }
            return result;
        });
    }

    // Check if ingestion client is ready to perform ingestion
    isReady(): boolean {
        if (!this.#working && this.#files.length > 0 && !this.#complete && this.#getAuthHeader !== undefined) {
            return true;
        }
        return false;
    }

    isWorking(): boolean {
        return this.#working;
    }

    // Set auth header function, this function retrieves the auth header with token
    setAuthHeaderFunction(func: Function) {
        this.#getAuthHeader = func;
    }

    async startIngestion() {
        if (!this.isReady() || this.#getAuthHeader === undefined) {
            throw new Error("Ingestion Client is not ready");
        }
        console.log('Starting Ingestion');
        this.#working = true;
        for (const f of this.#files) {
            try {
                f.status = IngestionStatus.Validating;
                console.log('Validating..')
                const schema = await this.getSchema(f.type);
                const check = await this.isFileValid(f.file, schema);
                f.valid = check.valid;
                if (!check.valid) {
                    f.errors = [];
                    f.status = IngestionStatus.Error;
                    for (const error of check.errors || []) {
                        f.errors.push(error.property + ": " + error.message);
                    }
                    continue;
                }
                await this.ingestFile(f);
            } catch (err) {
                f.status = IngestionStatus.Error;
                f.errors = [err.message];
                console.error('Failed to ingest file: ', f.file.name);
            }
        }
        this.#working = false;
        this.#complete = true;
        return;
    }

    async ingestFile(item: IngestionItem) {
        if (this.#getAuthHeader === undefined) {
            throw new Error('getAuthHeader() is not defined');
        }
        item.status = IngestionStatus.Starting;
        switch (item.type) {
            case IngestionFileType.Case: {
                const caseObject = JSON.parse(await item.file.text());
                if (!caseObject.case_id) {
                    item.status = IngestionStatus.Error;
                    item.errors = ["File does not have case_id"];
                    return;
                }
                item.status = IngestionStatus.Uploading;
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/ingest-case', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', ...await this.#getAuthHeader() },
                    body: JSON.stringify(caseObject)
                });
                if (response.status !== 200) {
                    const body = await response.json();
                    console.error('Could not complete ingestion: ', body.error || "Unknown Error", body.missing_items);
                    item.status = IngestionStatus.Error;
                    item.errors = [body["error"] || "Unknown Error"];
                    return;
                }
                break;
            }
            case IngestionFileType.Temg: {
                const temgFile = JSON.parse(await item.file.text());
                if (!temgFile.case_id) {
                    item.status = IngestionStatus.Error;
                    item.errors = ["File does not have case_id"];
                    return;
                }
                if (!temgFile.sets?.length) {
                    item.status = IngestionStatus.Error;
                    item.errors = ["Temg file must have at least one set"];
                    return;
                }
                const metadata = {};
                for (const [key, value] of Object.entries(temgFile)) {
                    if (key === 'case_id' || key === 'sets') {
                        continue;
                    }
                    metadata[key] = value;
                }

                const result = await this.getIngestionId(item.type, temgFile.case_id, temgFile.sets.length, metadata);
                const ingestionId = result.ingestionId;
                if (!ingestionId) {
                    item.status = IngestionStatus.Error;
                    item.errors = [result.error || "Failed to get ingestion id"];
                    return;
                }
                item.status = IngestionStatus.Uploading;
                item.totalItemsToUpload = temgFile.sets.length;
                item.currentItem = 1;
                for (let i = 0; i < temgFile.sets.length; i += 10) {
                    const chunk = temgFile.sets.slice(i, i + 10);
                    console.log('Sending ' + chunk.length + ' items');
                    const batch = await Promise.all(chunk.map(async (set, setIndex) => {
                        if (!this.#getAuthHeader) {
                            throw new Error('getAuthHeader() is not defined');
                        }
                        return fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/ingest-temg?ingestion_id=' + ingestionId, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', ...await this.#getAuthHeader() },
                            body: JSON.stringify({data_position: i + setIndex, data_object: set})
                        });
                    }));
                    for (const result of batch) {
                        if (result.status !== 200) {
                            console.error('Failed to push one of the temg sets to server');
                            const body = await result.json()
                            item.status = IngestionStatus.Error;
                            item.errors = [body["error"] || "Unknown Error"];
                            return;
                        }
                    }
                    item.currentItem += batch.length;
                }
                item.status = IngestionStatus.Finishing;
                const completeRecord = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/finish-ingestion?ingestion_id=' + ingestionId, {
                    method: 'GET',
                    headers: await this.#getAuthHeader()
                });
                if (completeRecord.status !== 200) {
                    const body = await completeRecord.json();
                    console.error('Could not complete ingestion: ', body.error || "Unknown Error", body.missing_items);
                    item.status = IngestionStatus.Error;
                    item.errors = [body["error"] || "Unknown Error"];
                    return;
                }
                break;
            }
            case IngestionFileType.Ssep: {
                const ssepDoc = JSON.parse(await item.file.text());
                if (!ssepDoc.case_id) {
                    item.status = IngestionStatus.Error;
                    item.errors = ["File does not have case_id"];
                    return;
                }
                if (!ssepDoc.montages?.length) {
                    item.status = IngestionStatus.Error;
                    item.errors = ["SSEP file must have at least one montage"];
                    return;
                }

                const metadata = {};
                for (const [key, value] of Object.entries(ssepDoc)) {
                    if (key === 'case_id' || key === 'montages') {
                        continue;
                    }
                    metadata[key] = value;
                }
                const result = await this.getIngestionId(item.type, ssepDoc.case_id, ssepDoc.montages.length, metadata);
                const ingestionId = result.ingestionId;
                if (!ingestionId) {
                    item.status = IngestionStatus.Error;
                    item.errors = [result.error || "Failed to get ingestion id"];
                    return;
                }
                item.status = IngestionStatus.Uploading;
                item.totalItemsToUpload = ssepDoc.montages.length;
                item.currentItem = 1;
                for (let i = 0; i < ssepDoc.montages.length; i++) {
                    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/ingest-ssep?ingestion_id=' + ingestionId, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', ...await this.#getAuthHeader() },
                        body: JSON.stringify({data_position: i, data_object: ssepDoc.montages[i]})
                    });
                    if (response.status !== 200) {
                        console.error('Failed to push one of the temg sets to server', ssepDoc.montages[i]);
                        const body = await response.json();
                        item.status = IngestionStatus.Error;
                        item.errors = [body["error"] || "Unknown Error"];
                        return;
                    }
                    item.currentItem++;
                }
                item.status = IngestionStatus.Finishing;
                const completeRecord = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/finish-ingestion?ingestion_id=' + ingestionId, {
                    method: 'GET',
                    headers: await this.#getAuthHeader()
                });
                if (completeRecord.status !== 200) {
                    const body = await completeRecord.json();
                    console.error('Could not complete ingestion: ', body.error || "Unknown Error", body.missing_items);
                    item.status = IngestionStatus.Error;
                    item.errors = [body["error"] || "Unknown Error"];
                    return;
                }
                break;
            }
        }
        item.status = IngestionStatus.Complete;
    }

    async getIngestionId(type: IngestionFileType, caseId: string, total_records: number, metadata: {[key: string]: any}): Promise<{ingestionId?: string, error?: string}> {
        if (this.#getAuthHeader === undefined || (type !== IngestionFileType.Ssep && type !== IngestionFileType.Temg)) {
            console.error('getAuthHeader() is undefined or type is not SSEP or TEMG in getIngestionId()')
            return {error: "Something went wrong"};
        }
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/get-ingestion-id', {
            method: "POST",
            headers: { 'Content-Type': 'application/json', ...await this.#getAuthHeader() },
            body: JSON.stringify({
                case_id: caseId, 
                type: type === IngestionFileType.Ssep ? 'ssep' : 'temg', 
                expected_records: total_records, 
                metadata: metadata
            })
        });
        const body = await response.json();
        if (response.status !== 200) {
            return {error: body.error || "Unknown Error"}
        }
        return {ingestionId: body.ingestion_id};
    }
    

    // UTIL //
    getFileType(fileName: String) : IngestionFileType {
        if (fileName.startsWith('casefile-')) {
            return IngestionFileType.Case;
        } else if (fileName.startsWith('temgfile-')) {
            return IngestionFileType.Temg;
        } else if (fileName.startsWith('ssepfile-')) {
            return IngestionFileType.Ssep;
        }
        return IngestionFileType.Unknown;
    }

    async getSchema(type: IngestionFileType) : Promise<Object> {
        if (this.#getAuthHeader === undefined) {
            throw new Error('getAuthHeader() is not defined');
        }
        switch(type) {
            case IngestionFileType.Case: {
                if (this.#caseSchema !== undefined) {
                    return this.#caseSchema;
                }
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/get-case-schema', {
                    method: "GET",
                    headers: await this.#getAuthHeader()
                });
                if (response.status !== 200) {
                    throw new Error('Status was not 200');
                }
                const schema = await response.json();
                this.#caseSchema = schema;
                return schema;
            }
            case IngestionFileType.Temg: {
                if (this.#temgSchema !== undefined) {
                    return this.#temgSchema;
                }
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/get-temg-schema', {
                    method: "GET",
                    headers: await this.#getAuthHeader()
                });
                if (response.status !== 200) {
                    throw new Error('Status was not 200');
                }
                const schema = await response.json();
                this.#temgSchema = schema;
                return schema;
            }
            case IngestionFileType.Ssep: {
                if (this.#ssepSchema !== undefined) {
                    return this.#ssepSchema;
                }
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/ingestion/get-ssep-schema', {
                    method: "GET",
                    headers: await this.#getAuthHeader()
                });
                if (response.status !== 200) {
                    throw new Error('Status was not 200');
                }
                const schema = await response.json();
                this.#ssepSchema = schema;
                return schema;
            }
            default:
                throw new Error('Invalid File Type');
        }
    }

    async isFileValid(file: File, schema: Object): Promise<{valid: boolean, errors: Array<ValidationError>}> {
        console.log(schema);
        const parsed = JSON.parse(await file.text());
        if (typeof parsed !== 'object' || Array.isArray(parsed) || parsed === null) {
            return {valid: false, errors: [{property: "Type", message: "Invalid type, expecting json object"}]};
        }
        const result = validate(parsed, schema);
        return {valid: result.valid, errors: result.errors}
    }
}


export const IngestionClient = (function () {
    let instance: Ingestion;

    function createInstance() {
        let object = new Ingestion();
        return object;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();