import React, { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";

// components

import CardPaginatedTable from "components/Cards/CardPaginatedTable.js";

export default function SSEPRecords() {
  const { getAuthHeader } = useAuth();

  const fetchFunction = async (page) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/sseprecords?offset=' + (page * 10) + '&max=10', {method: "GET", headers: await getAuthHeader()});
    if (response.status != 200 && response.status != 404) {
      console.error("Something went wrong")
      return {data: [], hasMore: false};
    }
    if (response.status === 404) {
      return {data: [], hasMore: false};
    }
    try {
      const { records, total } = await response.json();
      const formatted = records.map((record) => {
        let percent = (parseInt(record.annotation_count || "0") / 2) * 100
        percent = (percent > 100) ? 100 : percent;
        return [record.case_id || "N/A", record.case_mapping_id || "N/A", record.ssep_record_id || "N/A", record.document_id || "N/A", record.annotation_count || "N/A", (record.user_emails ? record.user_emails.split(",") : []), (record.is_flagged == 1) ? ['True', "text-red-500"] : ["False", "text-emerald-500"], percent.toString()]
      })
      return {data: formatted, hasMore: ((page + 1) * 10) < total};
    } catch (err) {
      console.error("Error", err);
    }
  }

  const config = {
    title: "SSEP Records",
    columns: [
        {
          header: "CASE ID (SafeOp)",
          type: "text"
        },
        {
            header: "CASE ID (SQL)",
            type: "text"
        },
        {
            header: "SSEP RECORD ID",
            type: "text"
        },
        {
            header: "EXTERNAL LOCATOR (NoSQL)",
            type: "text"
        },
        {
            header: "ANNOTATION COUNT",
            type: "text"
        },
        {
            header: "ANNOTATED BY",
            type: "list"
        },
        {
            header: "NEEDS REVIEW",
            type: "status"
        },
        {
            header: "COMPLETION",
            type: "progress"
        }],
    color: "light",
  }

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardPaginatedTable 
            title={config.title}
            color={config.color}
            columns={config.columns}
            fetchFunction={fetchFunction}
            queryKey={"paginated-ssep-records"}/>
        </div>
      </div>
    </>
  );
}
